export const APP_SUBMISSION_CONFIG = {
    "COMPETITION_COMPLAINTS" : {
        application : {
            type: 'COMPETITION_COMPLAINTS',
            applicationDesc: 'Competition Complaints'
        },
        isLoginRequired: true,
        totalSteps: 5,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep : '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep : '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep : '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/transaction-details'
            }
        ]
    },
    "CONSUMER_COMPLAINTS" : {
        application : {
            type: 'CONSUMER_COMPLAINTS',
            applicationDesc: 'Consumer Complaints'
        },
        totalSteps: 5,
        isLoginRequired:true,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep: '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep: '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep: '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/transaction-details'
            }
        ]
    },
    "CONSUMER_REQUEST_FOR_ADVICE" : {
        application : {
            type: 'CONSUMER_REQUEST_FOR_ADVICE',
            applicationDesc: 'Request for Advice'
        },
        totalSteps: 5,
        isLoginRequired:false,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep: '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep: '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep: '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/transaction-details'
            }
        ]
    },
    "REQUEST_FOR_ADVOCACY" : {
        application: {
            type: 'REQUEST_FOR_ADVOCACY',
            applicationDesc: 'ADVOCACY'
        },
        filePath: '/forms/Avocacy_Suggestion_Form.docx',
        totalSteps: 5,
        isLoginRequired:false,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep: '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep: '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep: '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/transaction-details'
            }
        ]
    },

    "REQUEST_FOR_MERGER_ACQUISITIONS" : {
        application: {
            type: 'REQUEST_FOR_MERGER_ACQUISITIONS',
            applicationDesc: 'Application for Merger & Acquisitions'
        },
        totalSteps: 5,
        filePath: '/forms/Merger_Application_Form.docx',
        isLoginRequired:true,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep: '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep: '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep: '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/transaction-details'
            }
        ]
    },

    "REQUEST_FOR_INFORMATION" : {
        application: {
            type: 'REQUEST_FOR_INFORMATION',
            applicationDesc: 'Request for Information'
        },
        totalSteps: 5,
        filePath: '/forms/Request_for_Information.docx',
        isLoginRequired:false,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep: '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep: '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep: '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/transaction-details'
            }
        ]
    },

    "REQUEST_FOR_OPINION" : {
        application: {
            type: 'REQUEST_FOR_OPINION',
            applicationDesc: 'Request for Opinion'
        },
        totalSteps: 5,
        filePath: '/forms/Request_for_Opinion.docx',
        isLoginRequired:false,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep: '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep: '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep: '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/transaction-details'
            }
        ]
    },

    "REQUEST_FOR_AUTHORIZATION" : {
        application: {
            type: 'REQUEST_FOR_AUTHORIZATION',
            applicationDesc: 'Request for Authorization'
        },
        filePath: '/forms/Application _for_Authorization_Under_Section_29_of_the_FCA.docx',
        totalSteps: 5,
        isLoginRequired:false,
        steps : [
            {
                name: "Guidelines",
                next: '/application/form',
                previous: '/home',
                currentStep : '/application/guidelines'
            },
            {
                name: "Complaint Form",
                next: '/application/supporting-documents',
                previous: '/application/guidelines',
                currentStep: '/application/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/preview',
                previous: '/application/form',
                currentStep: '/application/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/transaction-details',
                previous: '/application/supporting-documents',
                currentStep: '/application/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/transaction-details'
            }
        ]
    },
}