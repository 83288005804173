import React from 'react';

function ApplicationHeading(props) {
    return (
        <div style={{textAlign: 'center', backgroundColor:'#336699', padding: '18px', color:'white', fontWeight:'600',fontSize: '32px', letterSpacing:'4px', lineHeight:'20px'}}>
            {props.title}
        </div>
    );
}

export default ApplicationHeading;