import { GET_MERCHANTS, SET_MERCHANTS } from '../actions/merchants';


const intitialState = {
    merchants : []
}

export default function merchants(state = intitialState, action){
    switch(action.type){
        case SET_MERCHANTS:
            return {
                ...state,
                merchants : action.merchants
            };
        default:
            return state;
    }
}