import React, { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, Link, useParams } from 'react-router-dom';
import { alertService } from '../../_services';
import queryString from 'query-string';

export const Navbar = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    // let queryParams = useParams();
    let queryParams = queryString.parse(props.location?.search)

    const [commission, setCommission] = useState(queryParams.route ?? localStorage.getItem('route'));

    setTimeout(() => {
        setCommission(queryParams.route ?? localStorage.getItem('route'));
    }, 200);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: 'center', backgroundColor: '#d4e3fc', padding: "10px 10px 10px 10px", backgroundColor: '#bee9f8' }}>
                
                {
                    commission === 'ftc' && 
                    <img alt="FTC" height={140} style={{flexGrow: 1}} src='./images/ftc-logo.png'></img>
                }

                {
                    commission === 'cac' && 
                    <img style={{flexGrow: 1}} alt="CAC" src="./images/cac-removebg-preview.png"  height={140}></img>

                }

                <div style={{ fontSize: '46px', fontWeight: '800', flexGrow: 10, textAlign: 'center' , }}>


                    <div className="mb-3">Case Administration Document System (CADS)</div>
                    <div className="top-nav-links d-flex flex-row justify-content-around">
                        <div>
                            <Link to="/home">
                                Home
                            </Link>
                        </div>

                        <div>
                            <Link to="/transactions">
                                My Transactions
                            </Link>
                        </div>
                        {/* <div>
                            <Link to="/guidelines">
                            Guidelines
                            </Link>
                        </div> */}




                        {
                            isLoggedIn &&
                            <div>
                                <Link to="/profile">
                                    Profile
                                </Link>
                            </div>
                        }



                        {
                            isLoggedIn &&
                            <div>
                                <Link to="/login" onClick={() => {
                                    dispatch({
                                        type: 'LOGOUT',
                                    });
                                    history.push('login');
                                    alertService.success('Logged out successfully', { autoClose: true, keepAfterRouteChange: true })

                                }}>
                                    Logout
                                </Link>
                            </div>
                        }




                        {
                            !isLoggedIn &&
                            <div>
                                <Link to="/login">
                                    Login
                                </Link>
                            </div>
                        }

                        {
                            !isLoggedIn &&
                            <div>
                                <Link to="/register">
                                    Register
                                </Link>
                            </div>
                        }


                    </div>

                </div>
                
            </div>

            {/* <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-evenly", backgroundColor: "rgb(148,194,200)", color: "white", fontSize: "26px" }}>
                <Link to="/home">
                    <div>Home</div>
                </Link>
                <Link>
                <div>Help</div>
                </Link>
                <Link>
                <div>FAQ's</div>
                </Link>
                <Link to="/transactions">
                <div>Transactions</div>
                </Link>
                <Link>
                {
                    props.isLoggedIn &&  <div onClick={() => props.logout()}>Logout</div>
                }

                {
                    !props.isLoggedIn && <div onClick={() => history.push('/login')}>Login</div>
                }
                </Link>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    isLoggedIn: state.auth.isLoggedIn
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch({
        type: 'LOGOUT'
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
