import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FTCForm from '../../containers/FTCForm';
import CACForm from '../../containers/CACForm';
import RequestForAdviceForm from '../../containers/RequestForAdviceForm';
import RequestForInfo from '../../containers/RequestForInfo';
import AdvocacySuggestionForm from '../../containers/AdvocacySuggestionForm';
import RequestForAuthorizationForm from '../../containers/RequestForAuthorizationForm';
import RequestForOpinionForm from '../../containers/RequestForOpinionForm';
import SimpleNavigator from '../SimpleNavigator';
import { useDispatch, useSelector } from 'react-redux';
import {APP_SUBMISSION_CONFIG} from '../../config/application-submission-config';
import { alertService } from '../../_services/alert.service';
import GenericApplicationSubmssion from '../../containers/GenericApplicationSubmssion';

function Form(props) {

    const history = useHistory();
    let form;
    let isFormValid = false;

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    
    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};
    
    let routingDetails = appDetails.steps.filter((step) => {    
        if(step.currentStep === history.location.pathname){
            return true;
        }
        return false;
    })[0];

    useEffect(() => {
        alertService.info('Please fill form with relevant details', { autoClose:true, keepAfterRouteChange: false });
    }, []);

    let properties = {
        setFormValid: (valid) => {
            isFormValid = valid;
        },
        next: routingDetails.next,
        previous: routingDetails.previous
    }
    if (props.application.type === 'COMPETITION_COMPLAINTS') {
        form = <FTCForm {...properties}></FTCForm>;
    } else if(props.application.type === 'CONSUMER_COMPLAINTS') {
        form = <CACForm {...properties}></CACForm>;
    } else if(props.application.type === 'CONSUMER_REQUEST_FOR_ADVICE'){
        form = <RequestForAdviceForm {...properties}></RequestForAdviceForm>
    } 
    else if(props.application.type === 'REQUEST_FOR_INFORMATION') {
        form = <RequestForInfo {...properties}></RequestForInfo>;
    } else if(props.application.type === 'REQUEST_FOR_ADVOCACY') {
        form = <AdvocacySuggestionForm {...properties}></AdvocacySuggestionForm>;
    } else if(props.application.type === 'REQUEST_FOR_AUTHORIZATION') {
        form = <RequestForAuthorizationForm {...properties}></RequestForAuthorizationForm>;
    } else if(props.application.type === 'REQUEST_FOR_OPINION'){
        form = <RequestForOpinionForm {...properties}></RequestForOpinionForm>
    }
    else if(['REQUEST_FOR_ADVOCACY', 'REQUEST_FOR_MERGER_ACQUISITIONS', 'REQUEST_FOR_AUTHORIZATION', 'REQUEST_FOR_OPINION'].indexOf(props.application.type) !== -1) {
        form = <GenericApplicationSubmssion></GenericApplicationSubmssion>
    }
    else {
        form = <FTCForm></FTCForm>;
    }

    return (
        <div>
            <div>
                <div className="shadow-sm p-3 m-3 bg-light rounded simple-border">
                    {form}
                </div>

                {/* <div></div> */}
            </div>
        </div>
    );
}

export default Form;