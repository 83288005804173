import { SET_CURRENT_DOCUMENTS } from '../actions/documents';

const initialState = {
  currentDocuments: []
};

export default function documents(state = initialState, action) {
  
  switch (action.type) {
    case SET_CURRENT_DOCUMENTS:
      return {
        ...state,
        currentDocuments : [
          ...action.documents
        ]
      };
    default:
      return state;
  }
}