import React from 'react';

import { useFormik } from 'formik';

import * as Yup from 'yup';
import { alertService } from '../_services';

import {Link, useHistory} from 'react-router-dom'
import { ROUTES } from '../config';
import {postData} from '../services/service-call';
import { GoogleLogin } from 'react-google-login';

import store from '../redux/store'; 

function Registration(props) {

    const history = useHistory();

    const responseGoogle = async (response) => {
        console.log(response);


        const {tokenId} = response;
        const result = await postData({url: `${ROUTES.verifyToken}?token=${tokenId}&actionType=login&email=${response.profileObj?.email}`});
        if(result.statusCode !== 200){
            alertService.error(result.data?.msg, { autoClose:true, keepAfterRouteChange: true })
        }else if(result.statusCode === 200) {
            alertService.success(result.data?.msg, { autoClose:true, keepAfterRouteChange: true });
            localStorage.setItem('token', result.data.token);
                store.dispatch({
                    type: 'LOGIN',
                    token: result.data?.token
                });
                history.push('/home');
        }
    }

    const formik = useFormik({
        initialValues: {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            password: '',
            user_name: '',
            reenterpassword: ''
        },
        validationSchema: Yup.object({
            // first_name: Yup.string()
            //     .max(15, 'Must be 15 characters or less')
            //     .required('This field is required'),
            //     middle_name: Yup.string()
            //     .max(20, 'Must be 20 characters or less')
            //     .required('This field is required'),
            // last_name: Yup.string()
            //     .max(20, 'Must be 20 characters or less')
            //     .required('This field is required'),
            email: Yup.string().email('Invalid email address').required('This field is required'),
            // user_name: Yup.string().max(20, 'Must be 20 characters or less')
            //     .required('This field is required'),
            password: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('This field is required'),
            reenterpassword: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('This field is required'),
        }),
        onSubmit: async values => {
            if(values.password !== values.reenterpassword){
                alertService.error('Password and confirm passsword should be of same value', { autoClose:true, keepAfterRouteChange: true })
                return
            }
            const result = await postData({url: ROUTES.registerUser, body: values});
            if(result.statusCode === 200){
                alertService.success('Registered Successfully', { autoClose:true, keepAfterRouteChange: true })
                history.push('/login');
            }else {
                alertService.error(result.data.msg, { autoClose:true, keepAfterRouteChange: true })
            }
        },
    });

    return (
        <div className="regitration-container">
            <div className="register-form-container">

                {/* <div className="form-top-heading">Complaints Portal</div> */}

                <div className="form-top-heading">Register</div>


                <form onSubmit={formik.handleSubmit}>
                    {/* <div className="row">
                        <div className="col">
                            <label htmlFor="first_name">First Name</label>
                            <input className="form-control"
                                id="first_name"
                                name="first_name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.first_name}
                            />
                            <span>{formik.touched.first_name && formik.errors.first_name ? (
                                <div>{formik.errors.first_name}</div>
                            ) : null}</span>
                        </div>
                        <div className="col">
                            <label htmlFor="middle_name">Middle Name</label>
                            <input
                                id="middle_name"
                                name="middle_name"
                                className="form-control"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.middle_name}
                            />
                            <span>{formik.touched.middle_name && formik.errors.middle_name ? (
                                <div>{formik.errors.middle_name}</div>
                            ) : null}</span>
                        </div>

                        <div className="col">
                            <label htmlFor="last_name">Last Name</label>
                            <input
                                id="last_name"
                                name="last_name"
                                className="form-control"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.last_name}
                            />
                            <span>{formik.touched.last_name && formik.errors.last_name ? (
                                <div>{formik.errors.last_name}</div>
                            ) : null}</span>
                        </div>
                    </div> */}


                    <div className="row">
                        <div className="col">
                            <label htmlFor="email">Email Address</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            <span>{formik.touched.email && formik.errors.email ? (
                                <div>{formik.errors.email}</div>
                            ) : null}</span>
                        </div>
                        {/* <div className="col">
                            <label htmlFor="user_name">User Name</label>
                            <input
                                id="user_name"
                                name="user_name"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.user_name}
                            />
                            <span>{formik.touched.user_name && formik.errors.user_name ? (
                                <div>{formik.errors.user_name}</div>
                            ) : null}</span>
                        </div> */}
                    </div>



                    <div className="row">
                        <div className="col">
                            <label htmlFor="email">Password</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            <span>{formik.touched.password && formik.errors.password ? (
                                <div>{formik.errors.password}</div>
                            ) : null}</span>
                        </div>

                        <div className="col">
                            <label htmlFor="email">Reenter Password</label>
                            <input
                                id="reenterpassword"
                                name="reenterpassword"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.reenterpassword}
                            />
                            <span>{formik.touched.reenterpassword && formik.errors.reenterpassword ? (
                                <div>{formik.errors.reenterpassword}</div>
                            ) : null}</span>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col mt-3 d-flex justify-content-center">
                            <button className="btn btn-primary" type="submit">Register</button>
                        </div>
                    </div>

{/*                    
                    <div className="row mt-3 ">
                                <div className="col d-flex justify-content-center">
                                <h4>OR</h4>
                                </div>
                    </div> */}


                    {/* <div className="row mt-3">
                    <div className="col d-flex justify-content-center">
                    <GoogleLogin
                                // clientId="1015323313638-8357jafp58qd58h6r1s3ch6onju186et.apps.googleusercontent.com"
                                clientId="287642303536-oaeblbu4dejtas0gfo2nl0kg9h4k4h9b.apps.googleusercontent.com"
                                buttonText="Register with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                    </div>

                    </div> */}

                    <div className="row mt-3">
                        <div className="col d-flex justify-content-center">
                            <div>Already have an account? &nbsp;</div>
                            <Link className="" to="login">&nbsp;Login</Link>
                        </div>
                    </div>

                </form>

                <br></br>

               

                <br></br>


            </div>


        </div>
    );
}

export default Registration