import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { useHistory, Link, useParams } from 'react-router-dom';
import '../../assets/styles/form.css';

import { alertService } from '../_services';

import store from '../redux/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GoogleLogin } from 'react-google-login';

import { ROUTES } from '../config';
import { postData } from '../services/service-call';

export const ResetPassword = (props) => {
    
    const history = useHistory();
    const params = useParams();


    useEffect(() => {
        if(params.uid){

        }
    }, []);


    
    const formik = useFormik({
        initialValues: {
            password: '',
            reenterpassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Required'),
                reenterpassword: Yup.string()
                .required('This field is required'),
        }),
        onSubmit: async values => {

            if(values.password !== values.reenterpassword){
                alertService.error('Password and confirm passsword should be of same value', { autoClose:true, keepAfterRouteChange: true })
                return;
            }
            const result = await postData({url: ROUTES.submitNewPassword, body: {
                uid: params.uid,
                password: values.password
            }});
            if(result.statusCode === 200){
                alertService.success(result.data.msg, { autoClose:true, keepAfterRouteChange: true })
                history.push('/login');
            }else {
                alertService.error(result.data.msg, { autoClose:true, keepAfterRouteChange: true })
            }

        },
    });

    return (
        <div className="regitration-container">
            <div className="login-form-container">

                {/* <div className="form-top-heading">Complaints Portal</div> */}

                <div className="form-top-heading">Reset Password</div>

                <form onSubmit={formik.handleSubmit}>

                    <div className="row">

                        <div className="col">
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            <span>{formik.touched.password && formik.errors.password ? (
                                <div>{formik.errors.password}</div>
                            ) : null}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label htmlFor="reenterpassword">Password</label>
                            <input
                                id="reenterpassword"
                                name="reenterpassword"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.reenterpassword}
                            />
                            <span>{formik.touched.reenterpassword && formik.errors.reenterpassword ? (
                                <div>{formik.errors.reenterpassword}</div>
                            ) : null}</span>
                        </div>

                    </div>

                    <div className="row mt-3">
                        <div className="col mt-2 d-flex justify-content-center">
                            <button className="btn btn-primary" type="submit">Reset Password</button>
                        </div>
                    </div>





                </form>
            </div>


        </div>
    );
}

export default ResetPassword
