import axios from "axios";
import { ROUTES } from "../../config";
import { trackPromise } from 'react-promise-tracker';

import { getData } from "../../services/service-call";

 export function requestGetTransactions(){
    let commission = localStorage.getItem('route');
    commission = commission?.toUpperCase();
    return getData({
        url : `${ROUTES.getTransactions}?commission_type=${commission}`
    });
 }

 export function requestGetTransaction(trans_id){
    return getData({
        url : ROUTES.getTransaction + trans_id
    });
 }
