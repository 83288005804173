import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from "react-hook-form";

import '../../assets/styles/form.css';
import HorizontalLine from '../components/HorLine';
import { getCurrentTransaction } from '../redux/actions/current-transaction';
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../config/index";
import SimpleNavigator from '../components/SimpleNavigator';
import { postData } from "../services/service-call";
import RespondentSearch from '../components/MerchantSearch';
import _ from 'lodash';
import AlertDialogSlide from '../components/Modal/Modal';

import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom';
import SelectBranch from '../components/SelectBranch';

export const CACForm = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const buttonSubmitRef = useRef();
    const [data, setFormData] = useState(null);

    const componentRef = useRef();

    const transactionDetails = useSelector(state => {
        return state.currentTransaction;
    });

    const selectBranchContainer = (branches, respondent) => {
        return <SelectBranch branches={branches} onSelectBranch={(branch) => {
            setBranchSearch({
                ...branchSearch,
                branch,
                isSearching: false
            })

            const currentData = getValues();

            setValue('respondent_details', {
                ...currentData.respondent_details,
                ..._.pick(branch, [ "address_line_1", "city", "parish", "email", "telephone_number"]),
                company_name: respondent.name,
                merchant_id: respondent.id,
                phone_number: branch.contact_telephone_number,
                email_address: branch.contact_email,
                street_address: branch.address_line_1,
                branch_name: branch.name,
                name: respondent.name,
                company_website: respondent.website


            });

            saveYourStore();

          setTimeout(() => {
            setValue('respondent_details', {
                // ...currentData.respondent_details,
                ..._.pick(branch, ["city"]),
                

            });
          }, 3000);

        }}></SelectBranch>
    }
    const [respondentSearch , setRespondentSearch] = useState({
        isSearching: false,
        respondent: {},
        content: <RespondentSearch onSelectRespondent={(respondent) => {

            setRespondentSearch({
                ...respondentSearch,
                respondent,
                isSearching: false
            });

            setBranchSearch({
                ...branchSearch,
                isSearching: true,
                content: selectBranchContainer(respondent?.respondent_branch_masters, respondent)
            })

            
            
        }}></RespondentSearch>
    })

    const [branchSearch, setBranchSearch] = useState({
        isSearching: false,
        branch: {},
        
    })

    const saveYourStore = () => {
        const data = getValues();
        data.temp_trans_id = props.temp_trans_id;
        setFormData(data);
    }

    

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const submitAllDetails = () => {
        history.push('/ftc/transaction-details');
    }

    useEffect(() => {
        if (transactionDetails.temp_trans_id) {
            dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
        }
    }, [transactionDetails.temp_trans_id]);

    useEffect(() => {

        if (transactionDetails.transaction) {
            setValue('informant_details', !!transactionDetails.transaction.informant_details?.[0] ? transactionDetails.transaction.informant_details?.[0] : {});
            setValue('respondent_details', !!transactionDetails.transaction.respondent_details?.[0] ? transactionDetails.transaction.respondent_details?.[0] : {});
            setValue('complaint_details', !!transactionDetails.transaction.consumer_complaint ? transactionDetails.transaction.consumer_complaint : {});
        }

    }, [transactionDetails.transaction]);

    const [dropdowns, setAllDropdowns] = useState({});
    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

    // const watchInformantParish = watch("respondent_details.parish");



    const onSubmit = async data => {

        data.temp_trans_id = props.temp_trans_id;
        localStorage.setItem('cac_complaints', JSON.stringify(data));
        const result = await postData({ url: ROUTES.saveConsumerComplaints, body: data });
        localStorage.setItem('cac_complaints', JSON.stringify(result.data));
        console.log(result);
        history.push(props.next);
    }

    const saveAndContinueLater = async () => {
        const currentData = getValues();
        localStorage.setItem('cac_complaints', JSON.stringify(currentData));
        currentData.temp_trans_id = props.temp_trans_id;
        const result = await postData({ url: ROUTES.saveConsumerComplaints, body: currentData });
        console.log(result);
        history.push('/home');
    }

    const nextAction = () => {
        buttonSubmitRef.current.click();
    }

    const previousAction = () => {
        history.push(props.previous);
    }

    // to populate default values while testing
    useEffect(async () => {



        var { parishes, industries, products } = await ALL_DROPDOWNS;
        setAllDropdowns({
            parishes, industries, products
        });

        if (process.env.REACT_APP_ENV === 'development') {

        }

    }, []);


    return (
        <div className="container-fluid " ref={componentRef}>


            <AlertDialogSlide fullWidth={true} open={respondentSearch.isSearching} full
                title={''} handleClose={() => {
                    setRespondentSearch((state) => {
                        return {
                            ...state,
                            isSearching: false
                        }
                    })
                }} primaryActionName={'Close'} content={respondentSearch.content}></AlertDialogSlide>


<AlertDialogSlide fullWidth={true} open={branchSearch.isSearching} full
                title={''} handleClose={() => {
                    setBranchSearch({
                        ...branchSearch,
                        isSearching: false
                    })
                }} primaryActionName={'Close'} content={branchSearch.content}></AlertDialogSlide>

            <div className="head-text">{props.isPreview ? 'Preview Filing' : 'Complaint Form'}</div>

            <div className="d-flex flex-row-reverse mb-1">
                <button onClick={handlePrint} hidden={!props.isDisabled || true} className="btn btn-primary" type="button">Print</button>

            </div>
            <div className="form-container">
                <fieldset disabled={props.isDisabled}>
                    <form onSubmit={handleSubmit(onSubmit)} onChange={() => saveYourStore()}  >
                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header">Complainant's Information</div>
                            <HorizontalLine></HorizontalLine>
                            <div className="row">


                                <div className="col">
                                    <label>First Name</label>
                                    <input type="text"  {...register("informant_details.first_name", { required: true })} className="form-control" placeholder="First name" />
                                    {errors.informant_details?.first_name && <span>This field is required</span>}
                                </div>
                                <div className="col">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" {...register("informant_details.last_name", { required: true })} placeholder="Last name" />
                                    {errors.informant_details?.last_name && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Age</label>

                                    <select className="form-control" {...register("informant_details.age", { required: true })}  >
                                        <option value="">---Select---</option>
                                        <option value="Under 18">Under 18</option>
                                        <option value="18 - 19">18-19</option>
                                        <option value="20 - 29">20-29</option>
                                        <option value="30 - 39">30-39</option>
                                        <option value="40 - 49">40-49</option>
                                        <option value="50 - 59">50-59</option>
                                        <option value="60 - 64">60-64</option>
                                        <option value="65 - 69">65-69</option>
                                        <option value="70 - 79">70-79</option>
                                        <option value="80 and Over">80 and Over</option>
                                    </select>
                                    {errors.informant_details?.age && <span>This field is required</span>}
                                </div>


                            </div>

                            <div className="row">


                                <div className="col">
                                    <label>Street Address</label>
                                    <input type="text" className="form-control" {...register("informant_details.street_address", { required: true })} placeholder="Street Address" />
                                    {errors.informant_details?.street_address && <span>This field is required</span>}
                                </div>

                              

                                <div className="col">
                                    <label>Postal Code</label>
                                    <input type="text" className="form-control" {...register("informant_details.post_office", { required: true })} placeholder="Postal Code" />
                                    {errors.informant_details?.post_office && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Parish</label>

                                    <select className="form-control" {...register("informant_details.parish", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.informant_details?.parish && <span>This field is required</span>}
                                </div>


                            </div>

                            <div className="row">

                            <div className="col">
                                    <label>Town or City</label>
                                    
                                    <select className="form-control" {...register("informant_details.town_city", { required: true })}>
                                        <option selected value="">---Select---</option>
                                        {
                                            // done
                                            data?.informant_details.parish === 'Clarendon' &&
                                            <>
                                                <option value="Aenon Town">Aenon Town</option>
                                                <option value="Alley">Alley</option>
                                                <option value="Alston">Alston</option>
                                                <option value="Beckford Kraal">Beckford Kraal</option>
                                                <option value="Chapleton">Chapleton</option>
                                                <option value="Colonels Ridge">Colonels Ridge</option>
                                                <option value="Crofts Hill">Crofts Hill</option>
                                                <option value="Crooked River">Crooked River</option>
                                                <option value="Denbigh">Denbigh</option>
                                                <option value="Four Paths">Four Paths</option>
                                                <option value="Frankfield">Frankfield</option>
                                                <option value="Grantham">Grantham</option>
                                                <option value="Hayes">Hayes</option>
                                                <option value="James Hill">James Hill</option>
                                                <option value="Kellits">Kellits</option>
                                                <option value="Lionel Town">Lionel Town</option>
                                                <option value="May Pen">May Pen</option>
                                                <option value="Milk River">Milk River</option>
                                                <option value="Mocho">Mocho</option>
                                                <option value="Osbourne Store">Osbourne Store</option>
                                                <option value="Race Course">Race Course</option>
                                                <option value="Richmond Park">Richmond Park</option>
                                                <option value="Rock River">Rock River</option>
                                                <option value="Smithville">Smithville</option>
                                                <option value="Spaldings">Spaldings</option>
                                                <option value="Thompson Town">Thompson Town</option>
                                                <option value="Toll Gate">Toll Gate</option>
                                                <option value="Trout Hall">Trout Hall</option>
                                                <option value="Wood Hall">Wood Hall</option>
                                                
                                            </>
                                        }
                                        {
                                            // done
                                            data?.informant_details.parish === 'Kingston' &&
                                            <>
                                                <option value="Kingston">Kingston</option>
                                            </>
                                        }
                                        {
                                            // done
                                            data?.informant_details.parish === 'Portland' &&
                                            <>
                                                <option value="Balcarres">Balcarres</option>
                                                <option value="Bangor Ridge">Bangor Ridge</option>
                                                <option value="Buff Bay">Buff Bay</option>
                                                <option value="Comfort Castle">Comfort Castle</option>
                                                <option value="Fairy Hill">Fairy Hill</option>
                                                <option value="Fellowship">Fellowship</option>
                                                <option value="Fruitful Vale">Fruitful Vale</option>
                                                <option value="Green Hill">Green Hill</option>
                                                <option value="Hectors River">Hectors River</option>
                                                <option value="Hope Bay">Hope Bay</option>
                                                <option value="Long Bay">Long Bay</option>
                                                <option value="Manchioneal">Manchioneal</option>
                                                <option value="Moore Town">Moore Town</option>
                                                <option value="Orange Bay">Orange Bay</option>
                                                <option value="Port Antonio">Port Antonio</option>
                                                <option value="Priestman's River">Priestman's River</option>
                                                <option value="Prior Park">Prior Park</option>
                                                <option value="Saint Margaret's Bay">Saint Margaret's Bay</option>
                                                <option value="Skibo">Skibo</option>
                                                <option value="Spring Hill">Spring Hill</option>
                                                <option value="Swift River">Swift River</option>
                                                <option value="Windsor Castle">Windsor Castle</option>
                                            </>
                                        }
                                        {/* {
                                            data?.informant_details.parish === 'Saint Ann' &&
                                            <>
                                                <option value="Saint Ann's Bay">Saint Ann's Bay</option>
                                            </>
                                        } */}
                                        {
                                            data?.informant_details.parish === 'St. Catherine' &&
                                            // done
                                            <>
                                                {/* <option value="Linstead">Linstead</option>
                                                <option value="Old Harbour">Old Harbour</option>
                                                <option value="Portmore">Portmore</option>
                                                <option value="Spanish Town">Spanish Town</option> */}
                                                <option value="Above Rocks">Above Rocks</option>
                                                <option value="Bartons">Bartons</option>
                                                <option value="Bellas Gate">Bellas Gate</option>
                                                <option value="Bog Walk">Bog Walk</option>
                                                <option value="Bridgeport">Bridgeport</option>
                                                <option value="Browns Hall">Browns Hall</option>
                                                <option value="Bushy Park">Bushy Park</option>
                                                <option value="Ewarton">Ewarton</option>
                                                <option value="Ginger Ridge">Ginger Ridge</option>
                                                <option value="Glengoffe">Glengoffe</option>
                                                <option value="Greater Portmore">Greater Portmore</option>
                                                <option value="Gregory Park">Gregory Park</option>
                                                <option value="Guanaboa Vale">Guanaboa Vale</option>
                                                <option value="Harewood">Harewood</option>
                                                <option value="Harkers Hall">Harkers Hall</option>
                                                <option value="Linstead">Linstead</option>
                                                <option value="Lluidas Vale">Lluidas Vale</option>
                                                <option value="Old Harbour">Old Harbour</option>
                                                <option value="Old Harbour Bay">Old Harbour Bay</option>
                                                <option value="Pear Tree Grove">Pear Tree Grove</option>
                                                <option value="Point Hill">Point Hill</option>
                                                <option value="Redwood">Redwood</option>
                                                <option value="Riversdale">Riversdale</option>
                                                <option value="Sligoville">Sligoville</option>
                                                <option value="Spanish Town">Spanish Town</option>
                                                <option value="Troja">Troja</option>
                                                <option value="Water Mount">Water Mount</option>
                                                <option value="Waterford">Waterford</option>
                                            </>
                                        }
                                        {/* {
                                            data?.informant_details.parish === 'Saint Thomas' &&
                                            <>
                                                <option value="Morant Bay">Morant Bay</option>
                                            </>
                                        } */}
                                       
                                        {
                                            // done
                                            data?.informant_details.parish === 'Hanover' &&
                                            <>
                                                <option value="Askenish">Askenish</option>
                                                <option value="Cascade">Cascade</option>
                                                <option value="Cessnock">Cessnock</option>
                                                <option value="Chester Castle">Chester Castle</option>
                                                <option value="Dias">Dias</option>
                                                <option value="Great Valley">Great Valley</option>
                                                <option value="Green Island">Green Island</option>
                                                <option value="Hopewell">Hopewell</option>
                                                <option value="Jericho">Jericho</option>
                                                <option value="Ken Jones">Ken Jones</option>
                                                <option value="Lucea">Lucea</option>
                                                <option value="March Town">March Town</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Mount Peto">Mount Peto</option>
                                                <option value="Ramble">Ramble</option>
                                                <option value="Sandy Bay">Sandy Bay</option>

                                            </>
                                        }
                                        {
                                            // done
                                            data?.informant_details.parish === 'Manchester' &&
                                            <>
                                                <option value="Auchtembeddie">Auchtembeddie</option>
                                                <option value="Banana Ground">Banana Ground</option>
                                                <option value="Christiana">Christiana</option>
                                                <option value="Coleyville">Coleyville</option>
                                                <option value="Comfort Hall">Comfort Hall</option>
                                                <option value="Craighead">Craighead</option>
                                                <option value="Cross Keys">Cross Keys</option>
                                                <option value="Devon">Devon</option>
                                                <option value="Ellen Street">Ellen Street</option>
                                                <option value="Harmons">Harmons</option>
                                                <option value="Harry Watch">Harry Watch</option>
                                                <option value="Hat Field">Hat Field</option>
                                                <option value="Kirkvine">Kirkvine</option>
                                                <option value="Knockpatrick">Knockpatrick</option>
                                                <option value="Lincoln">Lincoln</option>
                                                <option value="Maidstone">Maidstone</option>
                                                <option value="Mandeville">Mandeville</option>
                                                <option value="Mile Gully">Mile Gully</option>
                                                <option value="Newport">Newport</option>
                                                <option value="Old England">Old England</option>
                                                <option value="Porus">Porus</option>
                                                <option value="Pratville">Pratville</option>
                                                <option value="Shooters Hill">Shooters Hill</option>
                                                <option value="Spur Tree">Spur Tree</option>
                                                <option value="Walderston">Walderston</option>
                                                <option value="Watson Hill">Watson Hill</option>
                                                <option value="Williamsfield">Williamsfield</option>
                                            </>
                                        }
                                        {
                                            //done
                                            data?.informant_details.parish === 'St. Andrew' &&
                                            <>
                                                <option value="Border">Border</option>
                                                <option value="Bull Bay">Bull Bay</option>
                                                <option value="Dallas">Dallas</option>
                                                <option value="Gordon Town">Gordon Town</option>
                                                <option value="Kingston">Kingston</option>
                                                <option value="Lawrence Tavern">Lawrence Tavern</option>
                                                <option value="Mavis Bank">Mavis Bank</option>
                                                <option value="Mount James">Mount James</option>
                                                <option value="Red Hills">Red Hills</option>
                                                <option value="Saint Peters">Saint Peters</option>
                                                <option value="Woodford">Woodford</option>
                                            </>
                                        }
                                        {
                                            // done
                                            data?.informant_details.parish === 'St. Ann' &&
                                            <>
                                                <option value="Alexandria">Alexandria</option>
                                                <option value="Bamboo">Bamboo</option>
                                                <option value="Bensonton">Bensonton</option>
                                                <option value="Blackstonedge">Blackstonedge</option>
                                                <option value="Borobridge">Borobridge</option>
                                                <option value="Browns Town">Browns Town</option>
                                                <option value="Calderwood">Calderwood</option>
                                                <option value="Cave Valley">Cave Valley</option>
                                                <option value="Claremont">Claremont</option>
                                                <option value="Discovery Bay">Discovery Bay</option>
                                                <option value="Epworth">Epworth</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Inverness">Inverness</option>
                                                <option value="Keith">Keith</option>
                                                <option value="Laughlands">Laughlands</option>
                                                <option value="Lime Hall">Lime Hall</option>
                                                <option value="Lodge">Lodge</option>
                                                <option value="Moneague">Moneague</option>
                                                <option value="Ocho Rios">Ocho Rios</option>
                                                <option value="Runaway Bay">Runaway Bay</option>
                                                <option value="Saint Ann's Bay">Saint Ann's Bay</option>
                                                <option value="Salisbury">Salisbury</option>
                                                <option value="Steer Town">Steer Town</option>
                                                <option value="Walkerswood">Walkerswood</option>
                                                <option value="Watt Town">Watt Town</option>
                                                <option value="Wild Cane">Wild Cane</option>
                                                
                                            </>
                                        }
                                        {
                                            // done
                                            data?.informant_details.parish === 'St. Elizabeth' &&
                                            <>
                                                <option value="Aberdeen">Aberdeen</option>
                                                <option value="Balaclava">Balaclava</option>
                                                <option value="Black River">Black River</option>
                                                <option value="Braes River">Braes River</option>
                                                <option value="Brighton">Brighton</option>
                                                <option value="Elderslie">Elderslie</option>
                                                <option value="Fyffes Pen">Fyffes Pen</option>
                                                <option value="Giddy Hall">Giddy Hall</option>
                                                <option value="Junction">Junction</option>
                                                <option value="Lacovia">Lacovia</option>
                                                <option value="Maggotty">Maggotty</option>
                                                <option value="Malvern">Malvern</option>
                                                <option value="Middle Quarters">Middle Quarters</option>
                                                <option value="Mountainside">Mountainside</option>
                                                <option value="Munroe College">Munroe College</option>
                                                <option value="Myersville">Myersville</option>
                                                <option value="Nain">Nain</option>
                                                <option value="New Market">New Market</option>
                                                <option value="Pepper">Pepper</option>
                                                <option value="Rose Hall">Rose Hall</option>
                                                <option value="Santa Cruz">Santa Cruz</option>
                                                <option value="Siloah">Siloah</option>
                                                <option value="Southfield">Southfield</option>
                                                <option value="Springfield">Springfield</option>
                                                <option value="Top Hill">Top Hill</option>
                                                <option value="Treasure Beach">Treasure Beach</option>
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. James' &&
                                            <>
                                                {/* <option value="St. James">St. James</option> */}
                                                <option value="Adelphi">Adelphi</option>
                                                <option value="Anchovy">Anchovy</option>
                                                <option value="Cambridge">Cambridge</option>
                                                <option value="Catadupa">Catadupa</option>
                                                <option value="Granville">Granville</option>
                                                <option value="Half Moon">Half Moon</option>
                                                <option value="Hopeton">Hopeton</option>
                                                <option value="Johns Hall">Johns Hall</option>
                                                <option value="Little River">Little River</option>
                                                <option value="Lottery">Lottery</option>
                                                <option value="Maroon Town">Maroon Town</option>
                                                <option value="Mont Horeb">Mont Horeb</option>
                                                <option value="Montego Bay">Montego Bay</option>
                                                <option value="Montpellier">Montpellier</option>
                                                <option value="Point">Point</option>
                                                <option value="Reading">Reading</option>
                                                <option value="Salt Spring">Salt Spring</option>
                                                <option value="Sign">Sign</option>
                                                <option value="Somerton">Somerton</option>
                                                <option value="Stonehedge">Stonehedge</option>
                                                <option value="Welcome Hall">Welcome Hall</option>
                                                <option value="White Sands">White Sands</option>
                                                
                                                
                                            </>
                                        }
                                        {
                                            //mapping-mssing
                                            data?.informant_details.parish === 'St. Mary' &&
                                            <>
                                                {/* <option value="St. Mary">St. Mary</option> */}
                                                <option value="Albany">Albany</option>
                                                <option value="Annotto Bay">Annotto Bay</option>
                                                <option value="Belfield">Belfield</option>
                                                <option value="Bonny Gate">Bonny Gate</option>
                                                <option value="Boscobel">Boscobel</option>
                                                <option value="Brainerd">Brainerd</option>
                                                <option value="Carron Hall">Carron Hall</option>
                                                <option value="Castleton">Castleton</option>
                                                <option value="Clonmel">Clonmel</option>
                                                <option value="Enfield">Enfield</option>
                                                <option value="Free Hill">Free Hill</option>
                                                <option value="Gayle">Gayle</option>
                                                <option value="Guys Hill">Guys Hill</option>
                                                <option value="Hampstead">Hampstead</option>
                                                <option value="Highgate">Highgate</option>
                                                <option value="Islington">Islington</option>
                                                <option value="Labyrinth">Labyrinth</option>
                                                <option value="Long Road">Long Road</option>
                                                <option value="Lucky Hill">Lucky Hill</option>
                                                <option value="Oracabessa">Oracabessa</option>
                                                <option value="Pembroke Hall">Pembroke Hall</option>
                                                <option value="Port Maria">Port Maria</option>
                                                <option value="Retreat">Retreat</option>
                                                <option value="Richmond">Richmond</option>
                                                <option value="Tower Isle">Tower Isle</option>
                                                <option value="Union Hill">Union Hill</option>
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. Thomas' &&
                                            // done
                                            <>
                                                
                                                {/* <option value="Morant Bay">Morant Bay</option> */}
                                                <option value="Bath">Bath</option>
                                                <option value="Cedar Valley">Cedar Valley</option>
                                                <option value="Dalvey">Dalvey</option>
                                                <option value="Golden Grove">Golden Grove</option>
                                                <option value="Hagley Gap">Hagley Gap</option>
                                                <option value="Llandewey">Llandewey</option>
                                                <option value="Lyssons">Lyssons</option>
                                                <option value="Morant Bay">Morant Bay</option>
                                                <option value="Port Morant">Port Morant</option>
                                                <option value="Seaforth">Seaforth</option>
                                                <option value="Spring Garden">Spring Garden</option>
                                                <option value="Sunning Hill">Sunning Hill</option>
                                                <option value="Trinityville">Trinityville</option>
                                                <option value="White House">White House</option>
                                                <option value="Wilmington">Wilmington</option>
                                                <option value="Yallahs">Yallahs</option>
                                                
                                            </>
                                        }
                                        {
                                            //mapping-missing
                                            data?.informant_details.parish === 'Trelawny' &&
                                            // done
                                            <>
                                                {/* <option value="Trelawny">Trelawny</option> */}
                                                <option value="Albert Town">Albert Town</option>
                                                <option value="Bunkers Hill">Bunkers Hill</option>
                                                <option value="Clarks Town">Clarks Town</option>
                                                <option value="Deeside">Deeside</option>
                                                <option value="Duanvale">Duanvale</option>
                                                <option value="Duncans">Duncans</option>
                                                <option value="Falmouth">Falmouth</option>
                                                <option value="Hampden">asfasfasf</option>
                                                <option value="Jackson Town">asfasfasf</option>
                                                <option value="Lorrimers">Lorrimers</option>
                                                <option value="Rio Bueno">Rio Bueno</option>
                                                <option value="Sawyers">Sawyers</option>
                                                <option value="Sherwood Content">Sherwood Content</option>
                                                <option value="Stewart Town">Stewart Town</option>
                                                <option value="Troy">Troy</option>
                                                <option value="Ulster Spring">Ulster Spring</option>
                                                <option value="Wait-a-Bit">Wait-a-Bit</option>
                                                <option value="Wakefield">Wakefield</option>
                                                <option value="Warsop">Warsop</option>
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'Westmoreland' &&
                                            <>
                                                {/* <option value="Savanna-la-Mar">Savanna-la-Mar</option> */}
                                                <option value="Beeston Spring">Beeston Spring</option>
                                                <option value="Bethel Town">Bethel Town</option>
                                                <option value="Bluefields">Bluefields</option>
                                                <option value="Carmel">Carmel</option>
                                                <option value="Cave">Cave</option>
                                                <option value="Cornwall Mountain">Cornwall Mountain</option>
                                                <option value="Darliston">Darliston</option>
                                                <option value="Friendship">Friendship</option>
                                                <option value="Frome">Frome</option>
                                                <option value="Glenislay">Glenislay</option>
                                                <option value="Grange Hill">Grange Hill</option>
                                                <option value="Lambs River">Lambs River</option>
                                                <option value="Little London">Little London</option>
                                                <option value="Negril">Negril</option>
                                                <option value="Petersfield">Petersfield</option>
                                                <option value="Saint Leonards">Saint Leonards</option>
                                                <option value="Savanna-La-Mar">Savanna-La-Mar</option>
                                                <option value="Shefield">Shefield</option>
                                                <option value="White House">White House</option>
                                            </>
                                        }

                                    </select>

                                    {errors.informant_details?.town_city && <span>This field is required</span>}
                                </div>
                               
                                <div className="col">
                                    <label>Country</label>

                                    <select className="form-control" {...register("informant_details.country", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Jamaica" selected>Jamaica</option>
                                        {/* {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        } */}
                                    </select>
                                    {errors.informant_details?.parish && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Email Address</label>
                                    <input type="email" className="form-control" {...register("informant_details.email_address", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} placeholder="Email Address" />
                                    {errors.informant_details?.email_address && <span>This field is required</span>}
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Home Phone</label>
                                    <input type="number" className="form-control" {...register("informant_details.home_phone", { required: true })} placeholder="Home Phone" />
                                    {errors.informant_details?.home_phone && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Work Phone</label>
                                    <input type="number" className="form-control" {...register("informant_details.work_phone", { required: true })} placeholder="Work Phone" />
                                    {errors.informant_details?.work_phone && <span>This field is required</span>}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                        </div>


                        <div className="shadow p-3 mb-3 bg-white rounded">

                            {/* <div className="sub-form-header pt-4">Respondent Details</div> */}


                            

                            <div className="horizontally-aligned-container">
                                <div className="sub-form-header">Merchant Details

                                   
                                </div>

                              <button type="button" className=" ml-1 btn btn-sm btn-primary">
                              <div className="horizontally-aligned-container">
                                <span style={{fontSize:'16px'}} >Lookup</span>
                                <span onClick={() => {
                                    setRespondentSearch((state) => {
                                        return {
                                            ...state,
                                            isSearching: true
                                        }
                                    })
                                }} title="Search" style={{paddingTop:'0.5rem', marginLeft:'0.5rem'}} class="material-icons-outlined" >
                                        search
                                    </span>
                            </div>
                              </button>
                            </div>


                            <HorizontalLine></HorizontalLine>

                            <div className="row">

                                <div className="col">
                                    <label>Name of Company or Individual
                                        {/* Against Which You Are Complaining: */}
                                    </label>
                                    <input type="text" className="form-control" {...register("respondent_details.name", { required: true })} placeholder="Name" />
                                    {errors.respondent_details?.name && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Branch:</label>
                                    <input type="text" className="form-control" {...register("respondent_details.branch_name", { required: true })} placeholder="Branch" />
                                    {errors.respondent_details?.branch_name && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Street Address:</label>
                                    <input type="text" className="form-control" {...register("respondent_details.street_address", { required: true })} placeholder="Street Address" />
                                    {errors.respondent_details?.street_address && <span>This field is required</span>}
                                </div>


                            </div>


                            <div className="row">

                                <div className="col">
                                    <label>Parish</label>

                                    <select className="form-control" {...register("respondent_details.parish", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.respondent_details?.parish && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Town or City</label>
                                    {/* <input type="text" className="form-control" {...register("respondent_details.town_city", { required: true })} placeholder="Town/City" /> */}

                                    <select className="form-control" {...register("respondent_details.town_city", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            data?.respondent_details.parish === 'Clarendon' &&
                                            <>
                                                {/* <option value="Hayes">Hayes</option>
                                                <option value="May Pen">May Pen</option>
                                                <option value="Ma Pen">Ma Pen</option> */}
                                                  <option value="Aenon Town">Aenon Town</option>
                                                <option value="Alley">Alley</option>
                                                <option value="Alston">Alston</option>
                                                <option value="Beckford Kraal">Beckford Kraal</option>
                                                <option value="Chapleton">Chapleton</option>
                                                <option value="Colonels Ridge">Colonels Ridge</option>
                                                <option value="Crofts Hill">Crofts Hill</option>
                                                <option value="Crooked River">Crooked River</option>
                                                <option value="Denbigh">Denbigh</option>
                                                <option value="Four Paths">Four Paths</option>
                                                <option value="Frankfield">Frankfield</option>
                                                <option value="Grantham">Grantham</option>
                                                <option value="Hayes">Hayes</option>
                                                <option value="James Hill">James Hill</option>
                                                <option value="Kellits">Kellits</option>
                                                <option value="Lionel Town">Lionel Town</option>
                                                <option value="May Pen">May Pen</option>
                                                <option value="Milk River">Milk River</option>
                                                <option value="Mocho">Mocho</option>
                                                <option value="Osbourne Store">Osbourne Store</option>
                                                <option value="Race Course">Race Course</option>
                                                <option value="Richmond Park">Richmond Park</option>
                                                <option value="Rock River">Rock River</option>
                                                <option value="Smithville">Smithville</option>
                                                <option value="Spaldings">Spaldings</option>
                                                <option value="Thompson Town">Thompson Town</option>
                                                <option value="Toll Gate">Toll Gate</option>
                                                <option value="Trout Hall">Trout Hall</option>
                                                <option value="Wood Hall">Wood Hall</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Kingston' &&
                                            <>
                                                <option value="Kingston">Kingston</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Portland' &&
                                            <>
                                                {/* <option value="Port Antonio">Port Antonio</option> */}

                                                <option value="Balcarres">Balcarres</option>
                                                <option value="Bangor Ridge">Bangor Ridge</option>
                                                <option value="Buff Bay">Buff Bay</option>
                                                <option value="Comfort Castle">Comfort Castle</option>
                                                <option value="Fairy Hill">Fairy Hill</option>
                                                <option value="Fellowship">Fellowship</option>
                                                <option value="Fruitful Vale">Fruitful Vale</option>
                                                <option value="Green Hill">Green Hill</option>
                                                <option value="Hectors River">Hectors River</option>
                                                <option value="Hope Bay">Hope Bay</option>
                                                <option value="Long Bay">Long Bay</option>
                                                <option value="Manchioneal">Manchioneal</option>
                                                <option value="Moore Town">Moore Town</option>
                                                <option value="Orange Bay">Orange Bay</option>
                                                <option value="Port Antonio">Port Antonio</option>
                                                <option value="Priestman's River">Priestman's River</option>
                                                <option value="Prior Park">Prior Park</option>
                                                <option value="Saint Margaret's Bay">Saint Margaret's Bay</option>
                                                <option value="Skibo">Skibo</option>
                                                <option value="Spring Hill">Spring Hill</option>
                                                <option value="Swift River">Swift River</option>
                                                <option value="Windsor Castle">Windsor Castle</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Catherine' &&
                                            <>
                                                {/* <option value="Linstead">Linstead</option>
                                                <option value="Old Harbour">Old Harbour</option>
                                                <option value="Portmore">Portmore</option>
                                                <option value="Spanish Town">Spanish Town</option> */}

                                                <option value="Above Rocks">Above Rocks</option>
                                                <option value="Bartons">Bartons</option>
                                                <option value="Bellas Gate">Bellas Gate</option>
                                                <option value="Bog Walk">Bog Walk</option>
                                                <option value="Bridgeport">Bridgeport</option>
                                                <option value="Browns Hall">Browns Hall</option>
                                                <option value="Bushy Park">Bushy Park</option>
                                                <option value="Ewarton">Ewarton</option>
                                                <option value="Ginger Ridge">Ginger Ridge</option>
                                                <option value="Glengoffe">Glengoffe</option>
                                                <option value="Greater Portmore">Greater Portmore</option>
                                                <option value="Gregory Park">Gregory Park</option>
                                                <option value="Guanaboa Vale">Guanaboa Vale</option>
                                                <option value="Harewood">Harewood</option>
                                                <option value="Harkers Hall">Harkers Hall</option>
                                                <option value="Linstead">Linstead</option>
                                                <option value="Lluidas Vale">Lluidas Vale</option>
                                                <option value="Old Harbour">Old Harbour</option>
                                                <option value="Old Harbour Bay">Old Harbour Bay</option>
                                                <option value="Pear Tree Grove">Pear Tree Grove</option>
                                                <option value="Point Hill">Point Hill</option>
                                                <option value="Redwood">Redwood</option>
                                                <option value="Riversdale">Riversdale</option>
                                                <option value="Sligoville">Sligoville</option>
                                                <option value="Spanish Town">Spanish Town</option>
                                                <option value="Troja">Troja</option>
                                                <option value="Water Mount">Water Mount</option>
                                                <option value="Waterford">Waterford</option>


                                            </>
                                        }
                                       
                                        {
                                            data?.respondent_details.parish === 'Westmoreland' &&
                                            <>
                                                {/* <option value="Savanna-la-Mar">Savanna-la-Mar</option> */}
                                                <option value="Beeston Spring">Beeston Spring</option>
                                                <option value="Bethel Town">Bethel Town</option>
                                                <option value="Bluefields">Bluefields</option>
                                                <option value="Carmel">Carmel</option>
                                                <option value="Cave">Cave</option>
                                                <option value="Cornwall Mountain">Cornwall Mountain</option>
                                                <option value="Darliston">Darliston</option>
                                                <option value="Friendship">Friendship</option>
                                                <option value="Frome">Frome</option>
                                                <option value="Glenislay">Glenislay</option>
                                                <option value="Grange Hill">Grange Hill</option>
                                                <option value="Lambs River">Lambs River</option>
                                                <option value="Little London">Little London</option>
                                                <option value="Negril">Negril</option>
                                                <option value="Petersfield">Petersfield</option>
                                                <option value="Saint Leonards">Saint Leonards</option>
                                                <option value="Savanna-La-Mar">Savanna-La-Mar</option>
                                                <option value="Shefield">Shefield</option>
                                                <option value="White House">White House</option>
                                            </>
                                        }
                                        {
                                            // maping-missing
                                            data?.respondent_details.parish === 'Hanover' &&
                                            <>
                                                {/* <option value="Hanover">Hanover</option> */}
                                                <option value="Askenish">Askenish</option>
                                                <option value="Cascade">Cascade</option>
                                                <option value="Cessnock">Cessnock</option>
                                                <option value="Chester Castle">Chester Castle</option>
                                                <option value="Dias">Dias</option>
                                                <option value="Great Valley">Great Valley</option>
                                                <option value="Green Island">Green Island</option>
                                                <option value="Hopewell">Hopewell</option>
                                                <option value="Jericho">Jericho</option>
                                                <option value="Ken Jones">Ken Jones</option>
                                                <option value="Lucea">Lucea</option>
                                                <option value="March Town">March Town</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Mount Peto">Mount Peto</option>
                                                <option value="Ramble">Ramble</option>
                                                <option value="Sandy Bay">Sandy Bay</option>


                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Manchester' &&
                                            <>
                                                {/* <option value="Mandeville">Mandeville</option> */}

                                                <option value="Auchtembeddie">Auchtembeddie</option>
                                                <option value="Banana Ground">Banana Ground</option>
                                                <option value="Christiana">Christiana</option>
                                                <option value="Coleyville">Coleyville</option>
                                                <option value="Comfort Hall">Comfort Hall</option>
                                                <option value="Craighead">Craighead</option>
                                                <option value="Cross Keys">Cross Keys</option>
                                                <option value="Devon">Devon</option>
                                                <option value="Ellen Street">Ellen Street</option>
                                                <option value="Harmons">Harmons</option>
                                                <option value="Harry Watch">Harry Watch</option>
                                                <option value="Hat Field">Hat Field</option>
                                                <option value="Kirkvine">Kirkvine</option>
                                                <option value="Knockpatrick">Knockpatrick</option>
                                                <option value="Lincoln">Lincoln</option>
                                                <option value="Maidstone">Maidstone</option>
                                                <option value="Mandeville">Mandeville</option>
                                                <option value="Mile Gully">Mile Gully</option>
                                                <option value="Newport">Newport</option>
                                                <option value="Old England">Old England</option>
                                                <option value="Porus">Porus</option>
                                                <option value="Pratville">Pratville</option>
                                                <option value="Shooters Hill">Shooters Hill</option>
                                                <option value="Spur Tree">Spur Tree</option>
                                                <option value="Walderston">Walderston</option>
                                                <option value="Watson Hill">Watson Hill</option>
                                                <option value="Williamsfield">Williamsfield</option>
                                            </>
                                        }
                                        {
                                            //mapping-missing
                                            data?.respondent_details.parish === 'St. Andrew' &&
                                            <>
                                                {/* <option value="St. Andrew">St. Andrew</option> */}
                                                <option value="Border">Border</option>
                                                <option value="Bull Bay">Bull Bay</option>
                                                <option value="Dallas">Dallas</option>
                                                <option value="Gordon Town">Gordon Town</option>
                                                <option value="Kingston">Kingston</option>
                                                <option value="Lawrence Tavern">Lawrence Tavern</option>
                                                <option value="Mavis Bank">Mavis Bank</option>
                                                <option value="Mount James">Mount James</option>
                                                <option value="Red Hills">Red Hills</option>
                                                <option value="Saint Peters">Saint Peters</option>
                                                <option value="Woodford">Woodford</option>

                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Ann' &&
                                            <>
                                                {/* <option value="St. Ann">St. Ann</option> */}
                                                <option value="Alexandria">Alexandria</option>
                                                <option value="Bamboo">Bamboo</option>
                                                <option value="Bensonton">Bensonton</option>
                                                <option value="Blackstonedge">Blackstonedge</option>
                                                <option value="Borobridge">Borobridge</option>
                                                <option value="Browns Town">Browns Town</option>
                                                <option value="Calderwood">Calderwood</option>
                                                <option value="Cave Valley">Cave Valley</option>
                                                <option value="Claremont">Claremont</option>
                                                <option value="Discovery Bay">Discovery Bay</option>
                                                <option value="Epworth">Epworth</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Inverness">Inverness</option>
                                                <option value="Keith">Keith</option>
                                                <option value="Laughlands">Laughlands</option>
                                                <option value="Lime Hall">Lime Hall</option>
                                                <option value="Lodge">Lodge</option>
                                                <option value="Moneague">Moneague</option>
                                                <option value="Ocho Rios">Ocho Rios</option>
                                                <option value="Runaway Bay">Runaway Bay</option>
                                                <option value="Saint Ann's Bay">Saint Ann's Bay</option>
                                                <option value="Salisbury">Salisbury</option>
                                                <option value="Steer Town">Steer Town</option>
                                                <option value="Walkerswood">Walkerswood</option>
                                                <option value="Watt Town">Watt Town</option>
                                                <option value="Wild Cane">Wild Cane</option>
                                            </>
                                        }
                                        {
                                            //mapping-missing
                                            data?.respondent_details.parish === 'St. Elizabeth' &&
                                            <>
                                                {/* <option value="St. Elizabeth">St. Elizabeth</option> */}
                                                <option value="Aberdeen">Aberdeen</option>
                                                <option value="Balaclava">Balaclava</option>
                                                <option value="Black River">Black River</option>
                                                <option value="Braes River">Braes River</option>
                                                <option value="Brighton">Brighton</option>
                                                <option value="Elderslie">Elderslie</option>
                                                <option value="Fyffes Pen">Fyffes Pen</option>
                                                <option value="Giddy Hall">Giddy Hall</option>
                                                <option value="Junction">Junction</option>
                                                <option value="Lacovia">Lacovia</option>
                                                <option value="Maggotty">Maggotty</option>
                                                <option value="Malvern">Malvern</option>
                                                <option value="Middle Quarters">Middle Quarters</option>
                                                <option value="Mountainside">Mountainside</option>
                                                <option value="Munroe College">Munroe College</option>
                                                <option value="Myersville">Myersville</option>
                                                <option value="Nain">Nain</option>
                                                <option value="New Market">New Market</option>
                                                <option value="Pepper">Pepper</option>
                                                <option value="Rose Hall">Rose Hall</option>
                                                <option value="Santa Cruz">Santa Cruz</option>
                                                <option value="Siloah">Siloah</option>
                                                <option value="Southfield">Southfield</option>
                                                <option value="Springfield">Springfield</option>
                                                <option value="Top Hill">Top Hill</option>
                                                <option value="Treasure Beach">Treasure Beach</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. James' &&
                                            <>
                                                {/* <option value="St. James">St. James</option> */}
                                                <option value="Adelphi">Adelphi</option>
                                                <option value="Anchovy">Anchovy</option>
                                                <option value="Cambridge">Cambridge</option>
                                                <option value="Catadupa">Catadupa</option>
                                                <option value="Granville">Granville</option>
                                                <option value="Half Moon">Half Moon</option>
                                                <option value="Hopeton">Hopeton</option>
                                                <option value="Johns Hall">Johns Hall</option>
                                                <option value="Little River">Little River</option>
                                                <option value="Lottery">Lottery</option>
                                                <option value="Maroon Town">Maroon Town</option>
                                                <option value="Mont Horeb">Mont Horeb</option>
                                                <option value="Montego Bay">Montego Bay</option>
                                                <option value="Montpellier">Montpellier</option>
                                                <option value="Point">Point</option>
                                                <option value="Reading">Reading</option>
                                                <option value="Salt Spring">Salt Spring</option>
                                                <option value="Sign">Sign</option>
                                                <option value="Somerton">Somerton</option>
                                                <option value="Stonehedge">Stonehedge</option>
                                                <option value="Welcome Hall">Welcome Hall</option>
                                                <option value="White Sands">White Sands</option>
                                            </>
                                        }
                                        {
                                            //mapping-mssing
                                            data?.respondent_details.parish === 'St. Mary' &&
                                            <>
                                                {/* <option value="St. Mary">St. Mary</option> */}
                                                <option value="Albany">Albany</option>
                                                <option value="Annotto Bay">Annotto Bay</option>
                                                <option value="Belfield">Belfield</option>
                                                <option value="Bonny Gate">Bonny Gate</option>
                                                <option value="Boscobel">Boscobel</option>
                                                <option value="Brainerd">Brainerd</option>
                                                <option value="Carron Hall">Carron Hall</option>
                                                <option value="Castleton">Castleton</option>
                                                <option value="Clonmel">Clonmel</option>
                                                <option value="Enfield">Enfield</option>
                                                <option value="Free Hill">Free Hill</option>
                                                <option value="Gayle">Gayle</option>
                                                <option value="Guys Hill">Guys Hill</option>
                                                <option value="Hampstead">Hampstead</option>
                                                <option value="Highgate">Highgate</option>
                                                <option value="Islington">Islington</option>
                                                <option value="Labyrinth">Labyrinth</option>
                                                <option value="Long Road">Long Road</option>
                                                <option value="Lucky Hill">Lucky Hill</option>
                                                <option value="Oracabessa">Oracabessa</option>
                                                <option value="Pembroke Hall">Pembroke Hall</option>
                                                <option value="Port Maria">Port Maria</option>
                                                <option value="Retreat">Retreat</option>
                                                <option value="Richmond">Richmond</option>
                                                <option value="Tower Isle">Tower Isle</option>
                                                <option value="Union Hill">Union Hill</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Thomas' &&
                                            <>
                                                {/* <option value="Morant Bay">Morant Bay</option> */}
                                                <option value="Bath">Bath</option>
                                                <option value="Cedar Valley">Cedar Valley</option>
                                                <option value="Dalvey">Dalvey</option>
                                                <option value="Golden Grove">Golden Grove</option>
                                                <option value="Hagley Gap">Hagley Gap</option>
                                                <option value="Llandewey">Llandewey</option>
                                                <option value="Lyssons">Lyssons</option>
                                                <option value="Morant Bay">Morant Bay</option>
                                                <option value="Port Morant">Port Morant</option>
                                                <option value="Seaforth">Seaforth</option>
                                                <option value="Spring Garden">Spring Garden</option>
                                                <option value="Sunning Hill">Sunning Hill</option>
                                                <option value="Trinityville">Trinityville</option>
                                                <option value="White House">White House</option>
                                                <option value="Wilmington">Wilmington</option>
                                                <option value="Yallahs">Yallahs</option>
                                                
                                            </>
                                        }
                                        {
                                            //mapping-missing
                                            data?.respondent_details.parish === 'Trelawny' &&
                                            <>
                                                {/* <option value="Trelawny">Trelawny</option> */}
                                                <option value="Albert Town">Albert Town</option>
                                                <option value="Bunkers Hill">Bunkers Hill</option>
                                                <option value="Clarks Town">Clarks Town</option>
                                                <option value="Deeside">Deeside</option>
                                                <option value="Duanvale">Duanvale</option>
                                                <option value="Duncans">Duncans</option>
                                                <option value="Falmouth">Falmouth</option>
                                                <option value="Hampden">asfasfasf</option>
                                                <option value="Jackson Town">asfasfasf</option>
                                                <option value="Lorrimers">Lorrimers</option>
                                                <option value="Rio Bueno">Rio Bueno</option>
                                                <option value="Sawyers">Sawyers</option>
                                                <option value="Sherwood Content">Sherwood Content</option>
                                                <option value="Stewart Town">Stewart Town</option>
                                                <option value="Troy">Troy</option>
                                                <option value="Ulster Spring">Ulster Spring</option>
                                                <option value="Wait-a-Bit">Wait-a-Bit</option>
                                                <option value="Wakefield">Wakefield</option>
                                                <option value="Warsop">Warsop</option>
                                            </>
                                        }
                                        

                                    </select>
                                    {errors.respondent_details?.town_city && <span>This field is required</span>}
                                </div>

                                {/* <div className="col">
                                    <label>Post Office</label>
                                    <input type="text" className="form-control" {...register("respondent_details.post_office", { required: true })} placeholder="Post Office" />
                                    {errors.respondent_details?.post_office && <span>This field is required</span>}
                                </div> */}
                                <div className="col">
                                    <label>Country</label>

                                    <select className="form-control" {...register("respondent_details.country", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="JAMAICA" selected>Jamaica</option>
                                        {/* {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        } */}
                                    </select>
                                    {errors.respondent_details?.country && <span>This field is required</span>}
                                </div>

                            </div>


                            <div className="row">




                                <div className="col">
                                    <label>Company Website</label>
                                    <input type="text" className="form-control" {...register("respondent_details.company_website", { required: true })} placeholder="Website" />
                                    {errors.respondent_details?.company_website && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Company Email Address</label>
                                    <input type="email" className="form-control" {...register("respondent_details.email_address", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} placeholder="Email" />
                                    {errors.respondent_details?.email_address && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Phone Number</label>
                                    <input type="text" className="form-control" {...register("respondent_details.phone_number", { required: true })} placeholder="Phone Number" />
                                    {errors.respondent_details?.phone_number && <span>This field is required</span>}
                                </div>

                            </div>



                        </div>



                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header pt-4">Complaint Details</div>
                            <HorizontalLine></HorizontalLine>

                            <div className="row">
                                <div className="col">
                                    <label>Category:</label>
                                    <select className="form-control" {...register("complaint_details.industry", { required: true })}>
                                        <option value="">---Select---</option>
                                        {/* <option value="Appliances, Electronic and Other Household Items">Appliances, Electronic and Other Household Items</option>
                                        <option value="Automotive">Automotive</option>
                                        <option value="Banking">Banking</option>
                                        <option value="Cable services">Cable services</option>
                                        <option value="Chemical, Pharmaceutical, Petroleum Products or services">Chemical, Pharmaceutical, Petroleum Products or services</option>
                                        <option value="Computer / Internet services">Computer / Internet services</option>
                                        <option value="Debt collection">Debt collection</option>
                                        <option value="Electronic funds transfer">Electronic funds transfer</option>
                                        <option value="Furniture">Furniture</option>
                                        <option value="Food &amp; Beverage">Food &amp; Beverage</option>
                                        <option value="Government agencies/ministries">Government agencies/ministries</option>
                                        <option value="Health">Health</option>
                                        <option value="Lending">Lending</option>
                                        <option value="Professional services">Professional services</option>
                                        <option value="Utility services">Utility services</option>
                                        <option value="Work / Education">Work / Education</option>
                                        <option value="Other">Other</option> */}
                                        <option value="CAT1">Furniture</option>
                                        <option value="CAT2">Hardware Items</option>
                                        <option value="CAT3">Electrical Equipment, Appliances etc.</option>
                                        <option value="CAT4">Utilities</option>
                                        <option value="CAT5">Cable Services</option>
                                        <option value="CAT6">Computer and Computer Parts</option>
                                        <option value="CAT7">Jewellery, Clothing Kitchenware</option>
                                        <option value="CAT8">Chemical, Pharmaceutical and Petroleum Products</option>
                                        <option value="CAT9">Food and Beverages</option>
                                        <option value="CAT10">Motor Vehicle & Parts</option>
                                        <option value="CAT11">Payment Difficulties ( in respect of tangible items only )</option>
                                        <option value="CAT12">Other ( Other complaints against individuals where there are apparent breaches of contracts in any areas not noted elsewhere )</option>
                                        <option value="CAT13">Other Services ( include complaints relating to services apart from those mentioned in 1- 11 )</option>
                                    </select>
                                    {errors.complaint_details?.industry && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>How Did You Make Initial contact With the Company:</label>
                                    <select className="form-control" {...register("complaint_details.intitial_contact_with_company", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Fax">Fax</option>
                                        <option value="In Person">In Person</option>
                                        <option value="Internet (Website)">Internet (Website)</option>
                                        <option value="Email">Internet / E-mail</option>
                                        <option value="Mail">Mail</option>
                                        <option value="Phone">Phone</option>
                                        <option value="Print">Print</option>
                                        <option value="TV-Radio">TV / Radio</option>
                                        <option value="Unknown">Unknown</option>
                                    </select>
                                    {errors.complaint_details?.intitial_contact_with_company && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Have you lodged a complaint with the company: </label>
                                    <select className="form-control" {...register("complaint_details.is_complaint_lodged_against_company", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {errors.complaint_details?.is_complaint_lodged_against_company && <span>This field is required</span>}
                                </div>



                            </div>

                            <div className="row">

                                <div className="col">
                                    <label>If yes, please indicate the date first lodged:</label>
                                    <input type="date"  {...register("complaint_details.complaint_lodged_against_company_date", { required: true })} className="form-control" />
                                    {errors.complaint_details?.complaint_lodged_against_company_date && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Date of Purchase, Service contracted
                                        {/* or Entered Contractual Agreement: */}
                                    </label>
                                    <input type="date"  {...register("complaint_details.date_of_purchase", { required: true })} className="form-control" />
                                    {errors.complaint_details?.date_of_purchase && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Indicate type of Purchase Agreement: </label>
                                    <select className="form-control" {...register("complaint_details.type_of_purchase_agreement", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="N/A">Not Applicable</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Hire Purchase">Hire Purchase</option>
                                        <option value="Lay-Away">Lay Away</option>
                                        <option value="Lease">Lease</option>
                                    </select>
                                    {errors.complaint_details?.type_of_purchase_agreement && <span>This field is required</span>}
                                </div>
                            </div>

                            <div className="row">

                                <div className="col">
                                    <label>How Much Did the Company Ask You To Pay</label>
                                    <input type="number"  {...register("complaint_details.actual_payment_asked", { required: true })} className="form-control" placeholder="Payment Asked" />
                                    {errors.complaint_details?.actual_payment_asked && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>How Much Did You Actually Pay The Company</label>
                                    <input type="number"  {...register("complaint_details.actual_payment_made", { required: true })} className="form-control" placeholder="Payment Made" />
                                    {errors.complaint_details?.actual_payment_made && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>How Did You Pay the Company:</label>
                                    <select className="form-control" {...register("complaint_details.payment_mode_to_company", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="N/A">Not Applicable</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Credit Card">Credit Card</option>
                                        <option value="Debit Card">Debit Card</option>
                                    </select>
                                    {errors.complaint_details?.payment_mode_to_company && <span>This field is required</span>}
                                </div>

                            </div>

                            <div className="row">



                                <div className="col">
                                    <label>Warranty Period Given (Month)</label>
                                    <input type="text"  {...register("complaint_details.warranty_period", { required: true })} className="form-control" placeholder="Warranty in Months" />
                                    {errors.complaint_details?.warranty_period && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Account or Receipt number</label>
                                    <input type="text"  {...register("complaint_details.account_receipt_number", { required: true })} className="form-control" placeholder="Account or Receipt Number" />
                                    {errors.complaint_details?.account_receipt_number && <span>This field is required</span>}
                                </div>

                                <div className="col">

                                </div>


                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>First Name (Representative or Salesperson)</label>
                                    <input type="text"  {...register("complaint_details.first_name_contact", { required: true })} className="form-control" placeholder="First name" />
                                    {errors.complaint_details?.first_name_contact && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Last Name (Representative or Salesperson)</label>
                                    <input type="text"  {...register("complaint_details.last_name_contact", { required: true })} className="form-control" placeholder="Last Name" />
                                    {errors.complaint_details?.last_name_contact && <span>This field is required</span>}
                                </div>

                                <div className="col">

                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Explain Your Problem: Please limit your entry to 2,000 characters (about 30 lines):</label>
                                    <textarea type="text"  {...register("complaint_details.issue_description", { required: true })} className="form-control" placeholder="Describe your problem" />
                                    {errors.complaint_details?.issue_description && <span>This field is required</span>}
                                </div>
                            </div>

                        </div>

                        <div className="d-flex flex-row-reverse">
                            <div className="ml-2">
                                <button hidden={true} ref={buttonSubmitRef} className="btn btn-primary" type="submit">Submit</button>
                            </div>

                            <div>
                                {/* <button hidden={props.isDisabled} onClick={() => saveAndContinueLater()} className="btn btn-primary" type="button">Save & Continue Later</button> */}

                            </div>

                        </div>
                    </form>

                </fieldset>
                <div hidden={props.isDisabled}>
                    <SimpleNavigator next="/ftc/guidelines" middle middleValue={"Save & Continue Later"}
                        middleAction={saveAndContinueLater} nextAction={nextAction} previousAction={previousAction} previous="/ftc/guidelines" />

                    {/* onClick={() => props.handlePrint()} */}
                    {/* <button onClick={handlePrint} hidden={!props.isDisabled} className="btn btn-primary" type="button">Print</button> */}

                    {/* <button onClick={() => submitAllDetails()} hidden={!props.isDisabled} className="btn btn-primary ml-1" type="button">Submit</button> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        temp_trans_id: state.currentTransaction.temp_trans_id
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CACForm)
