import {SET_CURRENT_TRANSACTION, SAVE_CURRENT_TRANSACTION, CLEAR_CURRENT_TRANSACTION} from '../actions/current-transaction';

const initialState = {
    temp_trans_id : localStorage.getItem('TEMP_TRANS_ID'),
    appType: localStorage.getItem('CURRENT_TRANSACTION_DETAILS'),
    trans_id: localStorage.getItem('TRANS_ID'),
    transaction : {}
}

export default function transaction(state = initialState, action){
    switch(action.type){
        case SET_CURRENT_TRANSACTION:
            if(action.appType){
                localStorage.setItem('CURRENT_TRANSACTION_DETAILS', action.appType);
            }
            if(action.trans_id){
                localStorage.setItem('TRANS_ID', action.trans_id);
            }
            if(action.temp_trans_id){
                localStorage.setItem('TEMP_TRANS_ID', action.temp_trans_id);
            }
            return Object.assign({}, state, {
                ...action
            });
        case SAVE_CURRENT_TRANSACTION: {
            return Object.assign({}, state, 
                {
                    submitted_transaction: {
                        ...action.payload
                    }
                })
        }
        case CLEAR_CURRENT_TRANSACTION: {
            return Object.assign({}, state, 
                {
                    submitted_transaction: null,

                })
        }
        default:
            return state;
    }
}