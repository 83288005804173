import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import '../../assets/styles/form.css';
import HorizontalLine from '../components/HorLine';
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../config/index";
import SimpleNavigator from '../components/SimpleNavigator';
import { postData } from "../services/service-call";
import { getCurrentTransaction } from '../redux/actions/current-transaction';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom';
import AlertDialogSlide from '../components/Modal/Modal';
import { alertService } from '../_services';
import RespondentSearch from '../components/RespondentSearch';
import _ from 'lodash';

export const FTCForm = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const buttonSubmitRef = useRef();
    const [respondentSearch, setRespondentSearch] = useState({
        isSearching: false,
        respondent: {},
        content: <RespondentSearch onSelectRespondent={(respondent) => {
            setRespondentSearch({
                ...respondentSearch,
                respondent,
                isSearching: false
            });
            const currentData = getValues();
            setValue('respondent_details', {
                ...currentData.respondent_details,
                ..._.pick(respondent, ["street_address", "parish", "phone_number", "email_address"]),
                company_name: respondent.name,
                merchant_id: respondent.id
            });
        }}></RespondentSearch>
    })
    const [data, setFormData] = useState(null);

    const transactionDetails = useSelector(state => {
        return state.currentTransaction;
    });

    useEffect(() => {

        if (transactionDetails.transaction) {
            const informant_details = !!transactionDetails.transaction.informant_details?.[0] ? transactionDetails.transaction.informant_details?.[0] : {};
            const respondent_details = !!transactionDetails.transaction.respondent_details?.[0] ? transactionDetails.transaction.respondent_details?.[0] : {};
            const complaint_details = !!transactionDetails.transaction.competition_complaint ? transactionDetails.transaction.competition_complaint : {};
            setValue('informant_details', informant_details);
            setValue('respondent_details', respondent_details);
            setValue('complaint_details', complaint_details);
            setFormData({
                informant_details,
                respondent_details,
                complaint_details,
                temp_trans_id: props.temp_trans_id
            });
        }

    }, [transactionDetails.transaction]);

    useEffect(() => {
        if (transactionDetails.temp_trans_id) {
            dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
        }
    }, [transactionDetails.temp_trans_id]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const submitAllDetails = () => {
        history.push('/ftc/transaction-details');
    }

    const [dropdowns, setAllDropdowns] = useState({});
    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();
    const onSubmit = async data => {
        data.temp_trans_id = props.temp_trans_id;
        localStorage.setItem('ftc_complaints', JSON.stringify(data));
        const result = await postData({ url: '/applications/competition-complaint/save', body: data });
        console.log(result);
        localStorage.setItem('ftc_complaints', JSON.stringify(result.data));
        history.push(props.next);
    }

    const nextAction = () => {
        buttonSubmitRef.current.click();
    }

    const previousAction = () => {
        history.push(props.previous);
    }

    const saveAndContinueLater = async () => {
        const currentData = getValues();
        localStorage.setItem('ftc_complaints', JSON.stringify(currentData));
        currentData.temp_trans_id = props.temp_trans_id;
        const result = await postData({ url: '/applications/competition-complaint/save', body: currentData });
        console.log(result);
        alertService.success('Data saved Successfully', { autoClose: true, keepAfterRouteChange: true })
        history.push('/home');
    }

    const saveYourStore = () => {
        const data = getValues();
        data.temp_trans_id = props.temp_trans_id;
        localStorage.setItem('ftc_complaints', JSON.stringify(data));
        setFormData(data);
    }

    // to populate default values while testing
    useEffect(async () => {
        // allParishes = await PARISH_LIST;
        // setAllParish(allParishes);

        var { parishes, industries, products } = await ALL_DROPDOWNS;
        setAllDropdowns({
            parishes, industries, products
        });

        if (process.env.REACT_APP_ENV === 'development') { }

    }, []);


    return (



        <div className="container-fluid " ref={componentRef}>

            <AlertDialogSlide fullWidth={true} open={respondentSearch.isSearching} full
                title={''} handleClose={() => {
                    setRespondentSearch({
                        ...respondentSearch,
                        isSearching: false
                    })
                }} primaryActionName={'Close'} content={respondentSearch.content}></AlertDialogSlide>

            <div className="head-text">{props.isPreview ? 'Preview Filing' : 'Complaint Form'}</div>

            <div className="d-flex flex-row-reverse mb-1">
                <button onClick={handlePrint} hidden={!props.isDisabled || true} className="btn btn-primary" type="button">Print</button>

            </div>
            <div className="form-container">
                <fieldset disabled={props.isDisabled}>
                    <form onSubmit={handleSubmit(onSubmit)} onChange={() => saveYourStore()} >
                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header">Informant Information</div>
                            <HorizontalLine></HorizontalLine>
                            <div className="row">
                                <div className="col">
                                    <label>Informant Type</label>

                                    <select className="form-control" {...register("informant_details.informant_type", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Company">Company</option>
                                        <option value="Individual">Individual</option>
                                    </select>
                                    {errors.informant_details?.informant_type && <span>This field is required</span>}
                                </div>

                                {
                                    data?.informant_details?.informant_type === 'Individual' &&
                                    <div className="col">
                                        <label>Title</label>

                                        <select className="form-control" {...register("informant_details.title", { required: data?.informant_details?.informant_type === 'Individual' })}>
                                        <option value="">---Select---</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms.">Ms.</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Prof.">Prof.</option>
                                        <option value="Rev.">Rev.</option>
                                        <option value="Sr.">Sr.</option>
                                        <option value="Jr.">Jr.</option>
                                        <option value="Hon.">Hon.</option>
                                        <option value="Rt. Hon.">Rt. Hon.</option>
                                        <option value="Esq.">Esq.</option>
                                        <option value={"Other"}>Other</option>

                                        </select>
                                        {errors.informant_details?.title && <span>This field is required</span>}
                                    </div>
                                }

                                {
                                    data?.informant_details?.informant_type === 'Individual' &&
                                    <div className="col">
                                        <label>Position</label>

                                        <select className="form-control" {...register("informant_details.informant_position", { required: false })}>
                                            <option value="">---Select---</option>
                                            <option value="CEO">CEO</option>
                                            <option value="Clerical Officer">Clerical Officer</option>

                                        </select>
                                    </div>
                                }

                                {
                                    data?.informant_details?.informant_type === 'Individual' &&
                                    <>
                                        <div className="col">
                                            <label>First Name</label>
                                            <input type="text"  {...register("informant_details.first_name", { required: true, maxLength: { value: 25, message: "Max length is 25" } })} className="form-control" placeholder="First name" />
                                            {errors.informant_details?.first_name && <span>This field is required</span>}
                                        </div>
                                        <div className="col">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control" {...register("informant_details.last_name", { required: true, maxLength: { value: 25, message: "Max length is 25" } })} placeholder="Last name" />
                                            {errors.informant_details?.last_name && <span>This field is required</span>}
                                        </div>
                                    </>
                                }

                                {
                                    data?.informant_details?.informant_type === 'Company' &&
                                    <div className="col">
                                        <label>Company Name</label>
                                        <input type="text" className="form-control" {...register("informant_details.company_name", { required: data?.informant_details?.informant_type === 'Company' })} placeholder="Company Name" />
                                        {errors.informant_details?.company_name && <span>This field is required</span>}
                                    </div>
                                }

                            </div>

                            <div className="row">


                                <div className="col">
                                    <label>Street Address</label>
                                    <input type="text" className="form-control" {...register("informant_details.street_address", { required: true })} placeholder="Street Address" />
                                    {errors.informant_details?.street_address && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Parish</label>

                                    <select className="form-control" {...register("informant_details.parish", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.informant_details?.parish && <span>This field is required</span>}
                                </div>

                                {/* <div className="col">
                                    <label>Town or City</label>
                                   

                                    <select className="form-control" {...register("informant_details.town_city", { required: true })}>
                                        <option selected value="">---Select---</option>
                                        {
                                            data?.informant_details.parish === 'Clarendon' &&
                                            <>
                                                <option value="Aenon Town">Aenon Town</option>
                                                <option value="Alley">Alley</option>
                                                <option value="Alston">Alston</option>
                                                <option value="Beckford Kraal">Beckford Kraal</option>
                                                <option value="Chapleton">Chapleton</option>
                                                <option value="Colonels Ridge">Colonels Ridge</option>
                                                <option value="Crofts Hill">Crofts Hill</option>
                                                <option value="Crooked River">Crooked River</option>
                                                <option value="Denbigh">Denbigh</option>
                                                <option value="Four Paths">Four Paths</option>
                                                <option value="Frankfield">Frankfield</option>
                                                <option value="Grantham">Grantham</option>
                                                <option value="Hayes">Hayes</option>
                                                <option value="James Hill">James Hill</option>
                                                <option value="Kellits">Kellits</option>
                                                <option value="Lionel Town">Lionel Town</option>
                                                <option value="May Pen">May Pen</option>
                                                <option value="Milk River">Milk River</option>
                                                <option value="Mocho">Mocho</option>
                                                <option value="Osbourne Store">Osbourne Store</option>
                                                <option value="Race Course">Race Course</option>
                                                <option value="Richmond Park">Richmond Park</option>
                                                <option value="Rock River">Rock River</option>
                                                <option value="Smithville">Smithville</option>
                                                <option value="Spaldings">Spaldings</option>
                                                <option value="Thompson Town">Thompson Town</option>
                                                <option value="Toll Gate">Toll Gate</option>
                                                <option value="Trout Hall">Trout Hall</option>
                                                <option value="Wood Hall">Wood Hall</option>
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'Kingston' &&
                                            <>
                                                <option value="Kingston">Kingston</option>
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'Portland' &&
                                            <>
                                                <option value="Balcarres">Balcarres</option>
                                                <option value="Bangor Ridge">Bangor Ridge</option>
                                                <option value="Buff Bay">Buff Bay</option>
                                                <option value="Comfort Castle">Comfort Castle</option>
                                                <option value="Fairy Hill">Fairy Hill</option>
                                                <option value="Fellowship">Fellowship</option>
                                                <option value="Fruitful Vale">Fruitful Vale</option>
                                                <option value="Green Hill">Green Hill</option>
                                                <option value="Hectors River">Hectors River</option>
                                                <option value="Hope Bay">Hope Bay</option>
                                                <option value="Long Bay">Long Bay</option>
                                                <option value="Manchioneal">Manchioneal</option>
                                                <option value="Moore Town">Moore Town</option>
                                                <option value="Orange Bay">Orange Bay</option>
                                                <option value="Port Antonio">Port Antonio</option>
                                                <option value="Priestman's River">Priestman's River</option>
                                                <option value="Prior Park">Prior Park</option>
                                                <option value="Saint Margaret's Bay">Saint Margaret's Bay</option>
                                                <option value="Skibo">Skibo</option>
                                                <option value="Spring Hill">Spring Hill</option>
                                                <option value="Swift River">Swift River</option>
                                                <option value="Windsor Castle">Windsor Castle</option>
                                            </>
                                        }
                                       
                                        {
                                            data?.informant_details.parish === 'St. Catherine' &&
                                            <>
                                                <option value="Above Rocks">Above Rocks</option>
                                                <option value="Bartons">Bartons</option>
                                                <option value="Bellas Gate">Bellas Gate</option>
                                                <option value="Bog Walk">Bog Walk</option>
                                                <option value="Bridgeport">Bridgeport</option>
                                                <option value="Browns Hall">Browns Hall</option>
                                                <option value="Bushy Park">Bushy Park</option>
                                                <option value="Ewarton">Ewarton</option>
                                                <option value="Ginger Ridge">Ginger Ridge</option>
                                                <option value="Glengoffe">Glengoffe</option>
                                                <option value="Greater Portmore">Greater Portmore</option>
                                                <option value="Gregory Park">Gregory Park</option>
                                                <option value="Guanaboa Vale">Guanaboa Vale</option>
                                                <option value="Harewood">Harewood</option>
                                                <option value="Harkers Hall">Harkers Hall</option>
                                                <option value="Linstead">Linstead</option>
                                                <option value="Lluidas Vale">Lluidas Vale</option>
                                                <option value="Old Harbour">Old Harbour</option>
                                                <option value="Old Harbour Bay">Old Harbour Bay</option>
                                                <option value="Pear Tree Grove">Pear Tree Grove</option>
                                                <option value="Point Hill">Point Hill</option>
                                                <option value="Redwood">Redwood</option>
                                                <option value="Riversdale">Riversdale</option>
                                                <option value="Sligoville">Sligoville</option>
                                                <option value="Spanish Town">Spanish Town</option>
                                                <option value="Troja">Troja</option>
                                                <option value="Water Mount">Water Mount</option>
                                                <option value="Waterford">Waterford</option>
                                            </>
                                        }
                                        
                                       
                                        {
                                            data?.informant_details.parish === 'Hanover' &&
                                            <>
                                                <option value="Askenish">Askenish</option>
                                                <option value="Cascade">Cascade</option>
                                                <option value="Cessnock">Cessnock</option>
                                                <option value="Chester Castle">Chester Castle</option>
                                                <option value="Dias">Dias</option>
                                                <option value="Great Valley">Great Valley</option>
                                                <option value="Green Island">Green Island</option>
                                                <option value="Hopewell">Hopewell</option>
                                                <option value="Jericho">Jericho</option>
                                                <option value="Ken Jones">Ken Jones</option>
                                                <option value="Lucea">Lucea</option>
                                                <option value="March Town">March Town</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Mount Peto">Mount Peto</option>
                                                <option value="Ramble">Ramble</option>
                                                <option value="Sandy Bay">Sandy Bay</option>

                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'Manchester' &&
                                            <>
                                                <option value="Auchtembeddie">Auchtembeddie</option>
                                                <option value="Banana Ground">Banana Ground</option>
                                                <option value="Christiana">Christiana</option>
                                                <option value="Coleyville">Coleyville</option>
                                                <option value="Comfort Hall">Comfort Hall</option>
                                                <option value="Craighead">Craighead</option>
                                                <option value="Cross Keys">Cross Keys</option>
                                                <option value="Devon">Devon</option>
                                                <option value="Ellen Street">Ellen Street</option>
                                                <option value="Harmons">Harmons</option>
                                                <option value="Harry Watch">Harry Watch</option>
                                                <option value="Hat Field">Hat Field</option>
                                                <option value="Kirkvine">Kirkvine</option>
                                                <option value="Knockpatrick">Knockpatrick</option>
                                                <option value="Lincoln">Lincoln</option>
                                                <option value="Maidstone">Maidstone</option>
                                                <option value="Mandeville">Mandeville</option>
                                                <option value="Mile Gully">Mile Gully</option>
                                                <option value="Newport">Newport</option>
                                                <option value="Old England">Old England</option>
                                                <option value="Porus">Porus</option>
                                                <option value="Pratville">Pratville</option>
                                                <option value="Shooters Hill">Shooters Hill</option>
                                                <option value="Spur Tree">Spur Tree</option>
                                                <option value="Walderston">Walderston</option>
                                                <option value="Watson Hill">Watson Hill</option>
                                                <option value="Williamsfield">Williamsfield</option>
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. Andrew' &&
                                            <>
                                                <option value="Border">Border</option>
                                                <option value="Bull Bay">Bull Bay</option>
                                                <option value="Dallas">Dallas</option>
                                                <option value="Gordon Town">Gordon Town</option>
                                                <option value="Kingston">Kingston</option>
                                                <option value="Lawrence Tavern">Lawrence Tavern</option>
                                                <option value="Mavis Bank">Mavis Bank</option>
                                                <option value="Mount James">Mount James</option>
                                                <option value="Red Hills">Red Hills</option>
                                                <option value="Saint Peters">Saint Peters</option>
                                                <option value="Woodford">Woodford</option>
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. Ann' &&
                                            <>
                                                <option value="Alexandria">Alexandria</option>
                                                <option value="Bamboo">Bamboo</option>
                                                <option value="Bensonton">Bensonton</option>
                                                <option value="Blackstonedge">Blackstonedge</option>
                                                <option value="Borobridge">Borobridge</option>
                                                <option value="Browns Town">Browns Town</option>
                                                <option value="Calderwood">Calderwood</option>
                                                <option value="Cave Valley">Cave Valley</option>
                                                <option value="Claremont">Claremont</option>
                                                <option value="Discovery Bay">Discovery Bay</option>
                                                <option value="Epworth">Epworth</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Inverness">Inverness</option>
                                                <option value="Keith">Keith</option>
                                                <option value="Laughlands">Laughlands</option>
                                                <option value="Lime Hall">Lime Hall</option>
                                                <option value="Lodge">Lodge</option>
                                                <option value="Moneague">Moneague</option>
                                                <option value="Ocho Rios">Ocho Rios</option>
                                                <option value="Runaway Bay">Runaway Bay</option>
                                                <option value="Saint Ann's Bay">Saint Ann's Bay</option>
                                                <option value="Salisbury">Salisbury</option>
                                                <option value="Steer Town">Steer Town</option>
                                                <option value="Walkerswood">Walkerswood</option>
                                                <option value="Watt Town">Watt Town</option>
                                                <option value="Wild Cane">Wild Cane</option>
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. Elizabeth' &&
                                            <>
                                                <option value="Aberdeen">Aberdeen</option>
                                                <option value="Balaclava">Balaclava</option>
                                                <option value="Black River">Black River</option>
                                                <option value="Braes River">Braes River</option>
                                                <option value="Brighton">Brighton</option>
                                                <option value="Elderslie">Elderslie</option>
                                                <option value="Fyffes Pen">Fyffes Pen</option>
                                                <option value="Giddy Hall">Giddy Hall</option>
                                                <option value="Junction">Junction</option>
                                                <option value="Lacovia">Lacovia</option>
                                                <option value="Maggotty">Maggotty</option>
                                                <option value="Malvern">Malvern</option>
                                                <option value="Middle Quarters">Middle Quarters</option>
                                                <option value="Mountainside">Mountainside</option>
                                                <option value="Munroe College">Munroe College</option>
                                                <option value="Myersville">Myersville</option>
                                                <option value="Nain">Nain</option>
                                                <option value="New Market">New Market</option>
                                                <option value="Pepper">Pepper</option>
                                                <option value="Rose Hall">Rose Hall</option>
                                                <option value="Santa Cruz">Santa Cruz</option>
                                                <option value="Siloah">Siloah</option>
                                                <option value="Southfield">Southfield</option>
                                                <option value="Springfield">Springfield</option>
                                                <option value="Top Hill">Top Hill</option>
                                                <option value="Treasure Beach">Treasure Beach</option>
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. James' &&
                                            <>
                                                <option value="Adelphi">Adelphi</option>
                                                <option value="Anchovy">Anchovy</option>
                                                <option value="Cambridge">Cambridge</option>
                                                <option value="Catadupa">Catadupa</option>
                                                <option value="Granville">Granville</option>
                                                <option value="Half Moon">Half Moon</option>
                                                <option value="Hopeton">Hopeton</option>
                                                <option value="Johns Hall">Johns Hall</option>
                                                <option value="Little River">Little River</option>
                                                <option value="Lottery">Lottery</option>
                                                <option value="Maroon Town">Maroon Town</option>
                                                <option value="Mont Horeb">Mont Horeb</option>
                                                <option value="Montego Bay">Montego Bay</option>
                                                <option value="Montpellier">Montpellier</option>
                                                <option value="Point">Point</option>
                                                <option value="Reading">Reading</option>
                                                <option value="Salt Spring">Salt Spring</option>
                                                <option value="Sign">Sign</option>
                                                <option value="Somerton">Somerton</option>
                                                <option value="Stonehedge">Stonehedge</option>
                                                <option value="Welcome Hall">Welcome Hall</option>
                                                <option value="White Sands">White Sands</option>
                                                
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. Mary' &&
                                            <>
                                                <option value="Albany">Albany</option>
                                                <option value="Annotto Bay">Annotto Bay</option>
                                                <option value="Belfield">Belfield</option>
                                                <option value="Bonny Gate">Bonny Gate</option>
                                                <option value="Boscobel">Boscobel</option>
                                                <option value="Brainerd">Brainerd</option>
                                                <option value="Carron Hall">Carron Hall</option>
                                                <option value="Castleton">Castleton</option>
                                                <option value="Clonmel">Clonmel</option>
                                                <option value="Enfield">Enfield</option>
                                                <option value="Free Hill">Free Hill</option>
                                                <option value="Gayle">Gayle</option>
                                                <option value="Guys Hill">Guys Hill</option>
                                                <option value="Hampstead">Hampstead</option>
                                                <option value="Highgate">Highgate</option>
                                                <option value="Islington">Islington</option>
                                                <option value="Labyrinth">Labyrinth</option>
                                                <option value="Long Road">Long Road</option>
                                                <option value="Lucky Hill">Lucky Hill</option>
                                                <option value="Oracabessa">Oracabessa</option>
                                                <option value="Pembroke Hall">Pembroke Hall</option>
                                                <option value="Port Maria">Port Maria</option>
                                                <option value="Retreat">Retreat</option>
                                                <option value="Richmond">Richmond</option>
                                                <option value="Tower Isle">Tower Isle</option>
                                                <option value="Union Hill">Union Hill</option>
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'St. Thomas' &&
                                           
                                            <>
                                                
                                                <option value="Bath">Bath</option>
                                                <option value="Cedar Valley">Cedar Valley</option>
                                                <option value="Dalvey">Dalvey</option>
                                                <option value="Golden Grove">Golden Grove</option>
                                                <option value="Hagley Gap">Hagley Gap</option>
                                                <option value="Llandewey">Llandewey</option>
                                                <option value="Lyssons">Lyssons</option>
                                                <option value="Morant Bay">Morant Bay</option>
                                                <option value="Port Morant">Port Morant</option>
                                                <option value="Seaforth">Seaforth</option>
                                                <option value="Spring Garden">Spring Garden</option>
                                                <option value="Sunning Hill">Sunning Hill</option>
                                                <option value="Trinityville">Trinityville</option>
                                                <option value="White House">White House</option>
                                                <option value="Wilmington">Wilmington</option>
                                                <option value="Yallahs">Yallahs</option>
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'Trelawny' &&
                                
                                            <>
                                                <option value="Albert Town">Albert Town</option>
                                                <option value="Bunkers Hill">Bunkers Hill</option>
                                                <option value="Clarks Town">Clarks Town</option>
                                                <option value="Deeside">Deeside</option>
                                                <option value="Duanvale">Duanvale</option>
                                                <option value="Duncans">Duncans</option>
                                                <option value="Falmouth">Falmouth</option>
                                                <option value="Hampden">asfasfasf</option>
                                                <option value="Jackson Town">asfasfasf</option>
                                                <option value="Lorrimers">Lorrimers</option>
                                                <option value="Rio Bueno">Rio Bueno</option>
                                                <option value="Sawyers">Sawyers</option>
                                                <option value="Sherwood Content">Sherwood Content</option>
                                                <option value="Stewart Town">Stewart Town</option>
                                                <option value="Troy">Troy</option>
                                                <option value="Ulster Spring">Ulster Spring</option>
                                                <option value="Wait-a-Bit">Wait-a-Bit</option>
                                                <option value="Wakefield">Wakefield</option>
                                                <option value="Warsop">Warsop</option>
                                                
                                            </>
                                        }
                                        {
                                            data?.informant_details.parish === 'Westmoreland' &&
                                            <>
                                                <option value="Beeston Spring">Beeston Spring</option>
                                                <option value="Bethel Town">Bethel Town</option>
                                                <option value="Bluefields">Bluefields</option>
                                                <option value="Carmel">Carmel</option>
                                                <option value="Cave">Cave</option>
                                                <option value="Cornwall Mountain">Cornwall Mountain</option>
                                                <option value="Darliston">Darliston</option>
                                                <option value="Friendship">Friendship</option>
                                                <option value="Frome">Frome</option>
                                                <option value="Glenislay">Glenislay</option>
                                                <option value="Grange Hill">Grange Hill</option>
                                                <option value="Lambs River">Lambs River</option>
                                                <option value="Little London">Little London</option>
                                                <option value="Negril">Negril</option>
                                                <option value="Petersfield">Petersfield</option>
                                                <option value="Saint Leonards">Saint Leonards</option>
                                                <option value="Savanna-La-Mar">Savanna-La-Mar</option>
                                                <option value="Shefield">Shefield</option>
                                                <option value="White House">White House</option>
                                            </>
                                        }

                                    </select>

                                   
                                    {errors.respondent_details?.town_city && <span>This field is required</span>}
                                </div>*/}

                                <div className="col">
                                    <label>Contact Number</label>
                                    <input type="text" className="form-control" {...register("informant_details.phone_number", { required: true })} placeholder="Phone Number" />
                                    {errors.informant_details?.phone_number && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Email Address</label>
                                    <input type="email" className="form-control" {...register("informant_details.email_address", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} placeholder="Email Address" />
                                    {errors.informant_details?.email_address && <span>This field is required</span>}
                                </div>
                            </div>

                        </div>

                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="horizontally-aligned-container">
                                <div className="sub-form-header">Respondent details


                                </div>
                                <button type="button" className=" ml-1 btn btn-sm btn-primary">



                                    <div className="horizontally-aligned-container">
                                        <span style={{ fontSize: '16px' }} >Lookup</span>
                                        <span onClick={() => {
                                            setRespondentSearch((state) => {
                                                return {
                                                    ...state,
                                                    isSearching: true
                                                }
                                            })
                                        }} title="Search" style={{ paddingTop: '0.5rem', marginLeft: '0.5rem' }} class="material-icons-outlined" >
                                            search
                                        </span>

                                    </div>

                                </button>


                            </div>


                            <HorizontalLine></HorizontalLine>

                            <div className="row">
                                {/* <div className="col">
                                    <label>Title</label>

                                    <select className="form-control" {...register("respondent_details.title", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>

                                    </select>
                                    {errors.respondent_details?.title && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>First Name</label>
                                    <input type="text"  {...register("respondent_details.first_name", { required: true })} className="form-control" placeholder="First name" />
                                    {errors.respondent_details?.first_name && <span>This field is required</span>}
                                </div>
                                <div className="col">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" {...register("respondent_details.last_name", { required: true })} placeholder="Last name" />
                                    {errors.respondent_details?.last_name && <span>This field is required</span>}
                                </div>
 */}

                                <div className="col">
                                    <label>Company Name</label>
                                    <input type="text" className="form-control" {...register("respondent_details.company_name", { required: true })} placeholder="Company Name" />
                                    {errors.respondent_details?.company_name && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Street Address</label>
                                    <input type="text" className="form-control" {...register("respondent_details.street_address", { required: true })} placeholder="Street Address" />
                                    {errors.respondent_details?.street_address && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Parish</label>

                                    <select className="form-control" {...register("respondent_details.parish", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.respondent_details?.parish && <span>This field is required</span>}
                                </div>


                                {/* <div className="col">
                                    <label>Town or City</label>

                                    <select className="form-control" {...register("respondent_details.town_city", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            data?.respondent_details.parish === 'Clarendon' &&
                                            <>

                                                <option value="Aenon Town">Aenon Town</option>
                                                <option value="Alley">Alley</option>
                                                <option value="Alston">Alston</option>
                                                <option value="Beckford Kraal">Beckford Kraal</option>
                                                <option value="Chapleton">Chapleton</option>
                                                <option value="Colonels Ridge">Colonels Ridge</option>
                                                <option value="Crofts Hill">Crofts Hill</option>
                                                <option value="Crooked River">Crooked River</option>
                                                <option value="Denbigh">Denbigh</option>
                                                <option value="Four Paths">Four Paths</option>
                                                <option value="Frankfield">Frankfield</option>
                                                <option value="Grantham">Grantham</option>
                                                <option value="Hayes">Hayes</option>
                                                <option value="James Hill">James Hill</option>
                                                <option value="Kellits">Kellits</option>
                                                <option value="Lionel Town">Lionel Town</option>
                                                <option value="May Pen">May Pen</option>
                                                <option value="Milk River">Milk River</option>
                                                <option value="Mocho">Mocho</option>
                                                <option value="Osbourne Store">Osbourne Store</option>
                                                <option value="Race Course">Race Course</option>
                                                <option value="Richmond Park">Richmond Park</option>
                                                <option value="Rock River">Rock River</option>
                                                <option value="Smithville">Smithville</option>
                                                <option value="Spaldings">Spaldings</option>
                                                <option value="Thompson Town">Thompson Town</option>
                                                <option value="Toll Gate">Toll Gate</option>
                                                <option value="Trout Hall">Trout Hall</option>
                                                <option value="Wood Hall">Wood Hall</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Kingston' &&
                                            <>
                                                <option value="Kingston">Kingston</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Portland' &&
                                            <>

                                                <option value="Balcarres">Balcarres</option>
                                                <option value="Bangor Ridge">Bangor Ridge</option>
                                                <option value="Buff Bay">Buff Bay</option>
                                                <option value="Comfort Castle">Comfort Castle</option>
                                                <option value="Fairy Hill">Fairy Hill</option>
                                                <option value="Fellowship">Fellowship</option>
                                                <option value="Fruitful Vale">Fruitful Vale</option>
                                                <option value="Green Hill">Green Hill</option>
                                                <option value="Hectors River">Hectors River</option>
                                                <option value="Hope Bay">Hope Bay</option>
                                                <option value="Long Bay">Long Bay</option>
                                                <option value="Manchioneal">Manchioneal</option>
                                                <option value="Moore Town">Moore Town</option>
                                                <option value="Orange Bay">Orange Bay</option>
                                                <option value="Port Antonio">Port Antonio</option>
                                                <option value="Priestman's River">Priestman's River</option>
                                                <option value="Prior Park">Prior Park</option>
                                                <option value="Saint Margaret's Bay">Saint Margaret's Bay</option>
                                                <option value="Skibo">Skibo</option>
                                                <option value="Spring Hill">Spring Hill</option>
                                                <option value="Swift River">Swift River</option>
                                                <option value="Windsor Castle">Windsor Castle</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Catherine' &&
                                            <>

                                                <option value="Above Rocks">Above Rocks</option>
                                                <option value="Bartons">Bartons</option>
                                                <option value="Bellas Gate">Bellas Gate</option>
                                                <option value="Bog Walk">Bog Walk</option>
                                                <option value="Bridgeport">Bridgeport</option>
                                                <option value="Browns Hall">Browns Hall</option>
                                                <option value="Bushy Park">Bushy Park</option>
                                                <option value="Ewarton">Ewarton</option>
                                                <option value="Ginger Ridge">Ginger Ridge</option>
                                                <option value="Glengoffe">Glengoffe</option>
                                                <option value="Greater Portmore">Greater Portmore</option>
                                                <option value="Gregory Park">Gregory Park</option>
                                                <option value="Guanaboa Vale">Guanaboa Vale</option>
                                                <option value="Harewood">Harewood</option>
                                                <option value="Harkers Hall">Harkers Hall</option>
                                                <option value="Linstead">Linstead</option>
                                                <option value="Lluidas Vale">Lluidas Vale</option>
                                                <option value="Old Harbour">Old Harbour</option>
                                                <option value="Old Harbour Bay">Old Harbour Bay</option>
                                                <option value="Pear Tree Grove">Pear Tree Grove</option>
                                                <option value="Point Hill">Point Hill</option>
                                                <option value="Redwood">Redwood</option>
                                                <option value="Riversdale">Riversdale</option>
                                                <option value="Sligoville">Sligoville</option>
                                                <option value="Spanish Town">Spanish Town</option>
                                                <option value="Troja">Troja</option>
                                                <option value="Water Mount">Water Mount</option>
                                                <option value="Waterford">Waterford</option>


                                            </>
                                        }

                                        {
                                            data?.respondent_details.parish === 'Westmoreland' &&
                                            <>
                                                <option value="Beeston Spring">Beeston Spring</option>
                                                <option value="Bethel Town">Bethel Town</option>
                                                <option value="Bluefields">Bluefields</option>
                                                <option value="Carmel">Carmel</option>
                                                <option value="Cave">Cave</option>
                                                <option value="Cornwall Mountain">Cornwall Mountain</option>
                                                <option value="Darliston">Darliston</option>
                                                <option value="Friendship">Friendship</option>
                                                <option value="Frome">Frome</option>
                                                <option value="Glenislay">Glenislay</option>
                                                <option value="Grange Hill">Grange Hill</option>
                                                <option value="Lambs River">Lambs River</option>
                                                <option value="Little London">Little London</option>
                                                <option value="Negril">Negril</option>
                                                <option value="Petersfield">Petersfield</option>
                                                <option value="Saint Leonards">Saint Leonards</option>
                                                <option value="Savanna-La-Mar">Savanna-La-Mar</option>
                                                <option value="Shefield">Shefield</option>
                                                <option value="White House">White House</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Hanover' &&
                                            <>
                                                <option value="Askenish">Askenish</option>
                                                <option value="Cascade">Cascade</option>
                                                <option value="Cessnock">Cessnock</option>
                                                <option value="Chester Castle">Chester Castle</option>
                                                <option value="Dias">Dias</option>
                                                <option value="Great Valley">Great Valley</option>
                                                <option value="Green Island">Green Island</option>
                                                <option value="Hopewell">Hopewell</option>
                                                <option value="Jericho">Jericho</option>
                                                <option value="Ken Jones">Ken Jones</option>
                                                <option value="Lucea">Lucea</option>
                                                <option value="March Town">March Town</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Mount Peto">Mount Peto</option>
                                                <option value="Ramble">Ramble</option>
                                                <option value="Sandy Bay">Sandy Bay</option>


                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Manchester' &&
                                            <>

                                                <option value="Auchtembeddie">Auchtembeddie</option>
                                                <option value="Banana Ground">Banana Ground</option>
                                                <option value="Christiana">Christiana</option>
                                                <option value="Coleyville">Coleyville</option>
                                                <option value="Comfort Hall">Comfort Hall</option>
                                                <option value="Craighead">Craighead</option>
                                                <option value="Cross Keys">Cross Keys</option>
                                                <option value="Devon">Devon</option>
                                                <option value="Ellen Street">Ellen Street</option>
                                                <option value="Harmons">Harmons</option>
                                                <option value="Harry Watch">Harry Watch</option>
                                                <option value="Hat Field">Hat Field</option>
                                                <option value="Kirkvine">Kirkvine</option>
                                                <option value="Knockpatrick">Knockpatrick</option>
                                                <option value="Lincoln">Lincoln</option>
                                                <option value="Maidstone">Maidstone</option>
                                                <option value="Mandeville">Mandeville</option>
                                                <option value="Mile Gully">Mile Gully</option>
                                                <option value="Newport">Newport</option>
                                                <option value="Old England">Old England</option>
                                                <option value="Porus">Porus</option>
                                                <option value="Pratville">Pratville</option>
                                                <option value="Shooters Hill">Shooters Hill</option>
                                                <option value="Spur Tree">Spur Tree</option>
                                                <option value="Walderston">Walderston</option>
                                                <option value="Watson Hill">Watson Hill</option>
                                                <option value="Williamsfield">Williamsfield</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Andrew' &&
                                            <>
                                                <option value="Border">Border</option>
                                                <option value="Bull Bay">Bull Bay</option>
                                                <option value="Dallas">Dallas</option>
                                                <option value="Gordon Town">Gordon Town</option>
                                                <option value="Kingston">Kingston</option>
                                                <option value="Lawrence Tavern">Lawrence Tavern</option>
                                                <option value="Mavis Bank">Mavis Bank</option>
                                                <option value="Mount James">Mount James</option>
                                                <option value="Red Hills">Red Hills</option>
                                                <option value="Saint Peters">Saint Peters</option>
                                                <option value="Woodford">Woodford</option>

                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Ann' &&
                                            <>
                                                <option value="Alexandria">Alexandria</option>
                                                <option value="Bamboo">Bamboo</option>
                                                <option value="Bensonton">Bensonton</option>
                                                <option value="Blackstonedge">Blackstonedge</option>
                                                <option value="Borobridge">Borobridge</option>
                                                <option value="Browns Town">Browns Town</option>
                                                <option value="Calderwood">Calderwood</option>
                                                <option value="Cave Valley">Cave Valley</option>
                                                <option value="Claremont">Claremont</option>
                                                <option value="Discovery Bay">Discovery Bay</option>
                                                <option value="Epworth">Epworth</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Inverness">Inverness</option>
                                                <option value="Keith">Keith</option>
                                                <option value="Laughlands">Laughlands</option>
                                                <option value="Lime Hall">Lime Hall</option>
                                                <option value="Lodge">Lodge</option>
                                                <option value="Moneague">Moneague</option>
                                                <option value="Ocho Rios">Ocho Rios</option>
                                                <option value="Runaway Bay">Runaway Bay</option>
                                                <option value="Saint Ann's Bay">Saint Ann's Bay</option>
                                                <option value="Salisbury">Salisbury</option>
                                                <option value="Steer Town">Steer Town</option>
                                                <option value="Walkerswood">Walkerswood</option>
                                                <option value="Watt Town">Watt Town</option>
                                                <option value="Wild Cane">Wild Cane</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Elizabeth' &&
                                            <>
                                                <option value="Aberdeen">Aberdeen</option>
                                                <option value="Balaclava">Balaclava</option>
                                                <option value="Black River">Black River</option>
                                                <option value="Braes River">Braes River</option>
                                                <option value="Brighton">Brighton</option>
                                                <option value="Elderslie">Elderslie</option>
                                                <option value="Fyffes Pen">Fyffes Pen</option>
                                                <option value="Giddy Hall">Giddy Hall</option>
                                                <option value="Junction">Junction</option>
                                                <option value="Lacovia">Lacovia</option>
                                                <option value="Maggotty">Maggotty</option>
                                                <option value="Malvern">Malvern</option>
                                                <option value="Middle Quarters">Middle Quarters</option>
                                                <option value="Mountainside">Mountainside</option>
                                                <option value="Munroe College">Munroe College</option>
                                                <option value="Myersville">Myersville</option>
                                                <option value="Nain">Nain</option>
                                                <option value="New Market">New Market</option>
                                                <option value="Pepper">Pepper</option>
                                                <option value="Rose Hall">Rose Hall</option>
                                                <option value="Santa Cruz">Santa Cruz</option>
                                                <option value="Siloah">Siloah</option>
                                                <option value="Southfield">Southfield</option>
                                                <option value="Springfield">Springfield</option>
                                                <option value="Top Hill">Top Hill</option>
                                                <option value="Treasure Beach">Treasure Beach</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. James' &&
                                            <>
                                                <option value="Adelphi">Adelphi</option>
                                                <option value="Anchovy">Anchovy</option>
                                                <option value="Cambridge">Cambridge</option>
                                                <option value="Catadupa">Catadupa</option>
                                                <option value="Granville">Granville</option>
                                                <option value="Half Moon">Half Moon</option>
                                                <option value="Hopeton">Hopeton</option>
                                                <option value="Johns Hall">Johns Hall</option>
                                                <option value="Little River">Little River</option>
                                                <option value="Lottery">Lottery</option>
                                                <option value="Maroon Town">Maroon Town</option>
                                                <option value="Mont Horeb">Mont Horeb</option>
                                                <option value="Montego Bay">Montego Bay</option>
                                                <option value="Montpellier">Montpellier</option>
                                                <option value="Point">Point</option>
                                                <option value="Reading">Reading</option>
                                                <option value="Salt Spring">Salt Spring</option>
                                                <option value="Sign">Sign</option>
                                                <option value="Somerton">Somerton</option>
                                                <option value="Stonehedge">Stonehedge</option>
                                                <option value="Welcome Hall">Welcome Hall</option>
                                                <option value="White Sands">White Sands</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Mary' &&
                                            <>
                                                <option value="Albany">Albany</option>
                                                <option value="Annotto Bay">Annotto Bay</option>
                                                <option value="Belfield">Belfield</option>
                                                <option value="Bonny Gate">Bonny Gate</option>
                                                <option value="Boscobel">Boscobel</option>
                                                <option value="Brainerd">Brainerd</option>
                                                <option value="Carron Hall">Carron Hall</option>
                                                <option value="Castleton">Castleton</option>
                                                <option value="Clonmel">Clonmel</option>
                                                <option value="Enfield">Enfield</option>
                                                <option value="Free Hill">Free Hill</option>
                                                <option value="Gayle">Gayle</option>
                                                <option value="Guys Hill">Guys Hill</option>
                                                <option value="Hampstead">Hampstead</option>
                                                <option value="Highgate">Highgate</option>
                                                <option value="Islington">Islington</option>
                                                <option value="Labyrinth">Labyrinth</option>
                                                <option value="Long Road">Long Road</option>
                                                <option value="Lucky Hill">Lucky Hill</option>
                                                <option value="Oracabessa">Oracabessa</option>
                                                <option value="Pembroke Hall">Pembroke Hall</option>
                                                <option value="Port Maria">Port Maria</option>
                                                <option value="Retreat">Retreat</option>
                                                <option value="Richmond">Richmond</option>
                                                <option value="Tower Isle">Tower Isle</option>
                                                <option value="Union Hill">Union Hill</option>
                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'St. Thomas' &&
                                            <>
                                                <option value="Bath">Bath</option>
                                                <option value="Cedar Valley">Cedar Valley</option>
                                                <option value="Dalvey">Dalvey</option>
                                                <option value="Golden Grove">Golden Grove</option>
                                                <option value="Hagley Gap">Hagley Gap</option>
                                                <option value="Llandewey">Llandewey</option>
                                                <option value="Lyssons">Lyssons</option>
                                                <option value="Morant Bay">Morant Bay</option>
                                                <option value="Port Morant">Port Morant</option>
                                                <option value="Seaforth">Seaforth</option>
                                                <option value="Spring Garden">Spring Garden</option>
                                                <option value="Sunning Hill">Sunning Hill</option>
                                                <option value="Trinityville">Trinityville</option>
                                                <option value="White House">White House</option>
                                                <option value="Wilmington">Wilmington</option>
                                                <option value="Yallahs">Yallahs</option>

                                            </>
                                        }
                                        {
                                            data?.respondent_details.parish === 'Trelawny' &&
                                            <>
                                                <option value="Albert Town">Albert Town</option>
                                                <option value="Bunkers Hill">Bunkers Hill</option>
                                                <option value="Clarks Town">Clarks Town</option>
                                                <option value="Deeside">Deeside</option>
                                                <option value="Duanvale">Duanvale</option>
                                                <option value="Duncans">Duncans</option>
                                                <option value="Falmouth">Falmouth</option>
                                                <option value="Hampden">asfasfasf</option>
                                                <option value="Jackson Town">asfasfasf</option>
                                                <option value="Lorrimers">Lorrimers</option>
                                                <option value="Rio Bueno">Rio Bueno</option>
                                                <option value="Sawyers">Sawyers</option>
                                                <option value="Sherwood Content">Sherwood Content</option>
                                                <option value="Stewart Town">Stewart Town</option>
                                                <option value="Troy">Troy</option>
                                                <option value="Ulster Spring">Ulster Spring</option>
                                                <option value="Wait-a-Bit">Wait-a-Bit</option>
                                                <option value="Wakefield">Wakefield</option>
                                                <option value="Warsop">Warsop</option>
                                            </>
                                        }


                                    </select>
                                    {errors.respondent_details?.town_city && <span>This field is required</span>}
                                </div>

 */}


                            </div>

                            <div className="row">


                                <div className="col">
                                    <label>Contact Number</label>
                                    <input type="text" className="form-control" {...register("respondent_details.phone_number", { required: true })} placeholder="Phone Number" />
                                    {errors.respondent_details?.phone_number && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Email Address</label>
                                    <input type="email" className="form-control" {...register("respondent_details.email_address", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} placeholder="Email Address" />
                                    {errors.respondent_details?.email_address && <span>This field is required</span>}
                                </div>
                            </div>

                        </div>

                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header">Details of your Complaint</div>
                            <HorizontalLine></HorizontalLine>

                            <div className="row">

                                <div className="col">
                                    <label>Product</label>

                                    <select className="form-control" {...register("complaint_details.product", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Product">Product</option>
                                        <option value="Service">Service</option>


                                    </select>

                                    {/* <select className="form-control" {...register("complaint_details.product", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.products?.map((product) => {
                                                return <option value={product.name}>{product.name}</option>
                                            })
                                        }

                                    </select> */}
                                    {errors.complaint_details?.product && <span>This field is required</span>}

                                </div>


                                <div className="col">
                                    <label>Industry</label>
                                    <select className="form-control" {...register("complaint_details.industry", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.industries?.map((industry) => {
                                                return <option value={industry.name}>{industry.name}</option>
                                            })
                                        }

                                    </select>
                                    {errors.complaint_details?.industry && <span>This field is required</span>}

                                </div>

                                <div className="col">
                                    <label>Relationship With Party</label>
                                    <select className="form-control" {...register("complaint_details.relationship_against_party", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Competitor">Competitor</option>
                                        <option value="Supplier/Service Provider">Supplier/Service Provider</option>
                                        <option value="Employee/Former Employee">Employee/Former Employee</option>
                                        <option value="No Relationship">No Relationship</option>
                                        <option value="Other">Other</option>
                                        <option value="N/A">Not Applicable</option>
                                    </select>
                                    {errors.complaint_details?.relationship_against_party && <span>This field is required</span>}

                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Complaint Details</label>
                                    <textarea type="text"  {...register("complaint_details.issue_description", { required: true })} className="form-control" placeholder="Complaint Details" />
                                    {errors.complaint_details?.issue_description && <span>This field is required</span>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Describe the harm or effects of the activity you are complaining about</label>
                                    <textarea type="text"  {...register("complaint_details.harms_or_effects", { required: true })} className="form-control" placeholder="Harm or Effects" />
                                    {errors.complaint_details?.harms_or_effects && <span>This field is required</span>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>State the individuals/companies that you believe may be directly affected</label>
                                    <textarea type="text"  {...register("complaint_details.all_affected_directly", { required: true })} className="form-control" placeholder="Individuals/Companies" />
                                    {errors.complaint_details?.all_affected_directly && <span>This field is required</span>}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-row-reverse">
                            <div className="ml-2">
                                {/* <button hidden={props.isDisabled} className="btn btn-primary" type="submit">Submit</button> */}
                                <button hidden={true} ref={buttonSubmitRef} className="btn btn-primary" type="submit">Submit</button>
                            </div>

                            {/* <div className="ml-2">
                            <button hidden={props.isDisabled} onClick={() => saveAndContinueLater()} className="btn btn-primary" type="button">Save & Continue Later</button>
                        </div> */}
                        </div>
                    </form>

                </fieldset>
                <div hidden={props.isDisabled} >
                    <SimpleNavigator next="/ftc/guidelines" middle middleValue={"Save & Continue Later"}
                        middleAction={saveAndContinueLater} nextAction={nextAction} previousAction={previousAction} previous="/ftc/guidelines" />
                    {/* onClick={() => props.handlePrint()} */}
                    {/* <button onClick={handlePrint} hidden={!props.isDisabled} className="btn btn-primary" type="button">Print</button> */}

                    {/* <button onClick={() => submitAllDetails()} hidden={!props.isDisabled} className="btn btn-primary ml-1" type="button">Submit</button> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        temp_trans_id: state.currentTransaction.temp_trans_id
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(FTCForm)
