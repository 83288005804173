import axios from "axios";
import { ROUTES } from "../../config";
import { trackPromise } from 'react-promise-tracker';

import { getData, postData } from "../../services/service-call";

 export function requestGetGuidelines(applicationType){
    return getData(
        { url :ROUTES.getGuidelines + applicationType
    }); 
 }