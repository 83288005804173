export const GET_MERCHANTS = 'GET_MERCHANTS';
export const SET_MERCHANTS = 'SET_MERCHANTS';


export const getMerchants = () => {
    return {
        type: GET_MERCHANTS
    }
}

export const setMerchants = (merchants) => {
    return {
        type: SET_MERCHANTS,
        merchants
    }
}