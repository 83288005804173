import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";

import "../../assets/styles/form.css";
import HorizontalLine from "../components/HorLine";
import { getCurrentTransaction } from "../redux/actions/current-transaction";
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../config/index";
import SimpleNavigator from "../components/SimpleNavigator";
import { postData } from "../services/service-call";
import RespondentSearch from "../components/MerchantSearch";
import _ from "lodash";
import AlertDialogSlide from "../components/Modal/Modal";

import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import SelectBranch from "../components/SelectBranch";

export const RequestForAuthorizationForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const buttonSubmitRef = useRef();
	const [data, setFormData] = useState(null);

	const componentRef = useRef();

	const transactionDetails = useSelector((state) => {
		return state.currentTransaction;
	});

	let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

	const saveYourStore = () => {
		const data = getValues();
		data.temp_trans_id = props.temp_trans_id;
		setFormData(data);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const submitAllDetails = () => {
		history.push("/ftc/transaction-details");
	};

	useEffect(() => {
		if (transactionDetails.temp_trans_id) {
			dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
		}
	}, [transactionDetails.temp_trans_id]);

	useEffect(() => {
		if (transactionDetails.transaction) {
			setValue(
				"informant_details",
				!!transactionDetails.transaction.informant_details?.[0]
					? transactionDetails.transaction.informant_details?.[0]
					: {}
			);
			setValue(
				"respondent_details",
				!!transactionDetails.transaction.respondent_details?.[0]
					? transactionDetails.transaction.respondent_details?.[0]
					: {}
			);
			if(transactionDetails.transaction?.request_for_application?.signature){
				transactionDetails.transaction.request_for_application.signature = '';
			}
			setValue(
				"complaint_details",
				!!transactionDetails.transaction.request_for_application
					? transactionDetails.transaction.request_for_application
					: {}
			);
		}
	}, [transactionDetails.transaction]);

	const [dropdowns, setAllDropdowns] = useState({});
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		getValues,
	} = useForm();

	// const watchInformantParish = watch("respondent_details.parish");

	const onSubmit = async (data) => {
		data.temp_trans_id = props.temp_trans_id;
		data.app_type = appType;
		data.complaint_details.signature = await changeFileList(data?.complaint_details.signature);
		localStorage.setItem("generic_applications_data", JSON.stringify(data));
		const result = await postData({
			url: ROUTES.saveRequestForApplication,
			body: data,
		});
		localStorage.setItem(
			"generic_applications_data",
			JSON.stringify(result.data)
		);
		console.log(result);
		history.push(props.next);
	};

	const saveAndContinueLater = async () => {
		
		const data = getValues();
		data.complaint_details.signature = await changeFileList(data?.complaint_details.signature);
		
		localStorage.setItem(
			"generic_applications_data",
			JSON.stringify(data)
		);
		
		data.temp_trans_id = props.temp_trans_id;
		data.app_type = appType;
		const result = await postData({
			url: ROUTES.saveRequestForApplication,
			body: data
		});
		console.log(result);
		history.push("/home");
	};

	const allowedMimeType = ["image/png", "image/jpeg", "image/jpg"];


	const changeFileList = (fileList = []) => {
		if(!fileList){
			
			return;
		}
		return new Promise((resolve, reject) => {
			let files = Array.from(fileList);

			let reader = new FileReader();
			let fileName = files[0].name;
			reader.readAsDataURL(files[0]);
			reader.onload = function () {
				
				let resultString = reader.result
				.toString()
				.slice(reader.result.indexOf("base64,") + 7);
				let contentType = reader.result
				.toString()
				.slice(reader.result.indexOf(":") + 1, reader.result.indexOf(";"));
				if (allowedMimeType.indexOf(contentType?.toLowerCase()) !== -1) {
							resolve({
                base64: resultString,
                contentType: contentType,
                success: true,
				fileName: fileName
              });
				} else {
					resolve({
						success: false,
						msg: 'only png, jpg, jpeg allowed'
					})
				}
			};
			reader.onerror = function (error) {

				console.log("Error: ", error);
				reject({
					success: false,
					msg: 'Error while reading file', error
				})
			};
		})
	}

	const nextAction = () => {
		buttonSubmitRef.current.click();
	};

	const previousAction = () => {
		history.push(props.previous);
	};

	// to populate default values while testing
	useEffect(async () => {
		let { parishes, industries, products } = await ALL_DROPDOWNS;
		setAllDropdowns({
			parishes,
			industries,
			products,
		});
	}, []);

	return (
		<div className="container-fluid " ref={componentRef}>
			<div className="head-text">
				{props.isPreview
					? "Preview Filing"
					: "Request for Authorization Form"}
			</div>

			<div className="d-flex flex-row-reverse mb-1">
				<button
					onClick={handlePrint}
					hidden={!props.isDisabled || true}
					className="btn btn-primary"
					type="button"
				>
					Print
				</button>
			</div>
			<div className="form-container">
				<fieldset disabled={props.isDisabled}>
					<form
						onSubmit={handleSubmit(onSubmit)}
						onChange={() => saveYourStore()}
					>
						<div className="shadow p-3 mb-3 bg-white rounded">
							<div className="sub-form-header">
								Applicant Information
							</div>
							<HorizontalLine></HorizontalLine>

							<div className="row">

                            <div className="col">
									<label className="required">
										First Name
									</label>
									<input
										type="text"
										{...register(
											"informant_details.first_name",
											{
												required: true,
												maxLength: {
													value: 25,
													message: "Max length is 25",
												},
											}
										)}
										className="form-control"
										placeholder="First name"
									/>
									{errors.informant_details?.first_name && (
										<span>This field is required</span>
									)}
								</div>

                                <div className="col">
									<label className="required">
										Last Name
									</label>
									<input
										type="text"
										className="form-control"
										{...register(
											"informant_details.last_name",
											{
												required: true,
												maxLength: {
													value: 25,
													message: "Max length is 25",
												},
											}
										)}
										placeholder="Last name"
									/>
									{errors.informant_details?.last_name && (
										<span>This field is required</span>
									)}
								</div>
                                

								<div className="col">
									<label>Title</label>

									<select
										className="form-control"
										{...register(
											"informant_details.title",
											{}
										)}
									>
									   <option value="">---Select---</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms.">Ms.</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Prof.">Prof.</option>
                                        <option value="Rev.">Rev.</option>
                                        <option value="Sr.">Sr.</option>
                                        <option value="Jr.">Jr.</option>
                                        <option value="Hon.">Hon.</option>
                                        <option value="Rt. Hon.">Rt. Hon.</option>
                                        <option value="Esq.">Esq.</option>
                                        <option value={"Other"}>Other</option>
									</select>
									{errors?.informant_details?.title && (
										<span>This field is required</span>
									)}
								</div>

								
								
							</div>

							<div className="row">
								<div className="col">
									<label>Organization</label>
									<input
										type="text"
										className="form-control"
										{...register(
											"informant_details.company_name",
											{}
										)}
										placeholder="Organization Name"
									/>
									{errors.informant_details?.company_name && (
										<span>This field is required</span>
									)}
								</div>



                                <div className="col">
                                    <label>Address</label>
                                    <input type="text" className="form-control" {...register("informant_details.street_address", { required: true })} placeholder="Street Address" />
                                    {errors.informant_details?.street_address && <span>This field is required</span>}
                                </div>



                                <div className="col">
                                    <label>Address (Mailing)</label>
                                    <input type="text" className="form-control" {...register("informant_details.street_address_mailing", { required: true })} placeholder="Street Address" />
                                    {errors.informant_details?.street_address_mailing && <span>This field is required</span>}
                                </div>


								<div className="col">
									<label>Contact Number</label>
									<input
										type="text"
										className="form-control"
										{...register(
											"informant_details.phone_number",
											{}
										)}
										placeholder="Phone Number"
									/>
									{errors.informant_details?.phone_number && (
										<span>This field is required</span>
									)}
								</div>

								<div className="col">
									<label className="required">
										Email Address
									</label>
									<input
										type="email"
										className="form-control"
										{...register(
											"informant_details.email_address",
											{
												required: true,
												pattern:
													/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											}
										)}
										placeholder="Email Address"
									/>
									{errors.informant_details
										?.email_address && (
										<span>This field is required</span>
									)}
								</div>
							</div>
						</div>

						<div className="shadow p-3 mb-3 bg-white rounded">
							<div className="sub-form-header pt-4">
								Details of Your Request
							</div>
							<HorizontalLine></HorizontalLine>


							<div className="row">
                            <div className="col">
                                    <label>Section(s): </label>
                                    <textarea type="text"  {...register("complaint_details.section", { required: true })} className="form-control" placeholder="Sections" />
                                    {errors.complaint_details?.all_affected_directly && <span>This field is required</span>}
                                </div>
							</div>

							<div className="row">
                            <div className="col">
                                    <label>The individuals/companies/organizations directly affected by the agreement or practice are:</label>
                                    <textarea type="text"  {...register("complaint_details.all_affected_directly", { required: true })} className="form-control" placeholder="Individuals/Companies" />
                                    {errors.complaint_details?.all_affected_directly && <span>This field is required</span>}
                                </div>
							</div>

							<div className="row">
                            <div className="col">
                                    <label>Other parties likely to be affected by the agreement or practice would include:</label>
                                    <textarea type="text"  {...register("complaint_details.other_parties_affected_directly", { required: true })} className="form-control" placeholder="Other Parties" />
                                    {errors.complaint_details?.other_parties_affected_directly && <span>This field is required</span>}
                                </div>
							</div>

                            <div className="row">
                            <div className="col">
                                    <label>Market and competition considerations</label>
                                    <textarea type="text"  {...register("complaint_details.market_and_competition_consideration", { required: true })} className="form-control" placeholder="Market and competition considerations" />
                                    {errors.complaint_details?.market_and_competition_consideration && <span>This field is required</span>}
                                </div>
							</div>


                            <div className="row">

                            </div>



							<div className="row">
								

								<div className="col">
									<label>This application is made by:</label>
									<textarea
										type="text"
										className="form-control"
										{...register(
											"complaint_details.application_made_by"
										)}
										placeholder="Individuals/Companies"
									/>
									{errors.informant_details?.company_name && (
										<span>This field is required</span>
									)}
								</div>
							</div>

							<div className="row">
								<div className="col">
									<label>
										What is the competitive issue or harm in
										the sector(s) of the economy?
									</label>
									<textarea
										type="text"
										{...register(
											"complaint_details.competitive_harm",
											{
												required: true,
											}
										)}
										className="form-control"
										placeholder="Competive issue or harm"
									/>
									{errors.complaint_details
										?.competitive_harm && (
										<span>This field is required</span>
									)}
								</div>
							</div>
						</div>


						<div className="shadow p-3 mb-3 bg-white rounded">
						<div className="sub-form-header">
								Declaration
							</div>
							<HorizontalLine></HorizontalLine>

							<div className="row">
								<div className="col">
										<label>Upload Signature of 
										</label>

										<select className="form-control" {...register("complaint_details.signature_of", { required: false })}  >
											<option value="">---Select---</option>
											<option value="Director">Director</option>
											<option value="Chief Executive Officer">Chief Executive Officer</option>
											<option value="Common Seal">Common Seal</option>
											<option value="Applicant">Applicant</option>

										</select>
										{errors?.complaint_details?.signature_of && <span>This field is required</span>}
									</div>


									<div className="col">
										<label>Upload Signature
										</label>

									<div>	<input type="file"  {...register("complaint_details.signature", { required: !(data?.transaction?.request_for_application?.is_signature_uploaded) })}  />
										
										{errors?.complaint_details?.signature && <span>This field is required</span>}</div>
									</div>
							</div>
						</div>

						<div className="d-flex flex-row-reverse">
							<div className="ml-2">
								<button
									hidden={true}
									ref={buttonSubmitRef}
									className="btn btn-primary"
									type="submit"
								>
									Submit
								</button>
							</div>

							<div>
								{/* <button hidden={props.isDisabled} onClick={() => saveAndContinueLater()} className="btn btn-primary" type="button">Save & Continue Later</button> */}
							</div>
						</div>
					</form>
				</fieldset>
				<div hidden={props.isDisabled}>
					<SimpleNavigator
						next="/ftc/guidelines"
						middle
						middleValue={"Save & Continue Later"}
						middleAction={saveAndContinueLater}
						nextAction={nextAction}
						previousAction={previousAction}
						previous="/ftc/guidelines"
					/>

					{/* onClick={() => props.handlePrint()} */}
					{/* <button onClick={handlePrint} hidden={!props.isDisabled} className="btn btn-primary" type="button">Print</button> */}

					{/* <button onClick={() => submitAllDetails()} hidden={!props.isDisabled} className="btn btn-primary ml-1" type="button">Submit</button> */}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		temp_trans_id: state.currentTransaction.temp_trans_id,
	};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RequestForAuthorizationForm);
