import { call, put } from "redux-saga/effects";

import { setMerchants } from "../../redux/actions/merchants";
import { requestGetMerchants } from "../requests/merchant";

export function* handlerGetMerchants(action){
    try{
        const response = yield call(requestGetMerchants);
        const {merchantDetails} = response.data;
        yield put(setMerchants(merchantDetails));
    }catch(err){
        console.error(err);
    }
}