import { call, put } from "redux-saga/effects";

import { setTransactions } from "../../redux/actions/transactions";
import {setCurrentTransaction} from '../../redux/actions/current-transaction';
import { requestGetTransactions, requestGetTransaction } from "../requests/transactions";

export function* handlerGetTransactions(action){
    try{
        const response = yield call(requestGetTransactions);
        let {transactions} = response.data;
        // const commission = localStorage.getItem('route') ?? "";
        // if(commission === 'ftc'){
        //     transactions = transactions.filter('')
        // }
        yield put(setTransactions(transactions));
    }catch(err){
        console.error(err);
    }
}

export function* handleGetTransaction(action){
    try{
        const response = yield call(requestGetTransaction, action.temp_trans_id);
        const {transaction} = response.data;
        yield put(setCurrentTransaction(transaction));
    }catch(err){
        console.error(err);
    }
}
