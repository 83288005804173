import React, { useEffect, useState } from 'react';

import DefaultTable from './Grid/DefaultTable';
import { COLUMN_DEFINITIONS } from "../config/index";
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../services/service-call';
import { ROUTES } from '../config/index';
import ActionCellRenderer from './ActionCellRenderer';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PARISH_LIST, ALL_DROPDOWNS } from "../config/index";

function RespondentSearch(props) {

    const params = useParams();
    const [dropdowns, setAllDropdowns] = useState({});
    // let merchants = [];
    const [merchants, setMerchants] = useState([]);
    const { register, getValues, handleSubmit, watch, formState: { errors }, setValue, clearErrors } = useForm();
    // const merchants = useSelector(state => state.merchants.merchants);
    // const dispatch = useDispatch();

    //todo to call api
    useEffect(async () => {
        console.log(params);

        const searchParams = {
            commission_type: `COMPETITION`
        }

        const result = await getData({
            url: `${ROUTES.getMerchants}?searchParams=${JSON.stringify(searchParams)}`
        });
        setMerchants(result.merchantDetails);
    }, [params]);

    useEffect(async () => {
        var { parishes, industries, products } = await ALL_DROPDOWNS;
        setAllDropdowns({
            parishes, industries, products
        });
    }, []);


    const onSubmit = async data => {

        console.log(params);

        const searchParams = {
            commission_type: `COMPETITION`,
            ...data
        }

        const result = await getData({
            url: `${ROUTES.getMerchants}?searchParams=${JSON.stringify(searchParams)}`
        });
        setMerchants(result.merchantDetails);
    }

    const onInvalid = async data => {
        console.log(data);
    }

    const onRowClicked = (data) => {
        props.onSelectRespondent(data.data);
    }

    return (
        <div>

           


            <div className="p-2">

                <form className="pl-3 pr-3 search-form-container" onSubmit={handleSubmit(onSubmit, onInvalid)}>
                    <h2 style={{color:'black'}}>Search Respondent</h2>
                    <div className="row">

                        <div className="col">
                            <label>Name</label>
                            <input type="text"  {...register("name")} className="form-control form-control-sm" placeholder="Name" />
                            {errors && errors.name && <span>This field is required</span>}
                        </div>

                        <div className="col">
                            <label>ID</label>
                            <input type="number"  {...register("id")} className="form-control form-control-sm" placeholder="Id" />
                            {errors && errors.id && <span>This field is required</span>}
                        </div>

                        <div className="col">
                            <label>Street Address</label>
                            <input type="text"  {...register("street_address")} className="form-control form-control-sm" placeholder="Street Address" />
                            {errors && errors.name && <span>This field is required</span>}
                        </div>

                        <div className="col">
                            <label>Contact Number</label>
                            <input type="text"  {...register("phone_number")} className="form-control form-control-sm" placeholder="Contact Number" />
                            {errors && errors.name && <span>This field is required</span>}
                        </div>

                    </div>

                    <div className="row">

                        <div className="col">
                            <label>Email Address</label>
                            <input type="text"  {...register("email_address")} className="form-control form-control-sm" placeholder="Email" />
                            {errors && errors.name && <span>This field is required</span>}
                        </div>

                        <div className="col">

                            <label>Parish</label>

                            <select className="form-control form-control-sm" {...register("parish")}>
                                <option value="">---Select---</option>
                                {
                                    dropdowns.parishes?.map((parish) => {
                                        return <option value={parish.name}>{parish.name}</option>
                                    })
                                }
                            </select>
                            {errors.merchant_details?.parish && <span>This field is required</span>}
                        </div>

                        <div className="col">
                        {/* <label>{' '}</label> */}
                        <input  className="m-3 btn btn-primary btn-sm" style={{marginTop:'37px !important'}} value="Search" type="submit" />
                        </div>

                        <div className="col">

                        </div>
                    </div>

                    <div className="row right-aligned-container">

                   
                       

                    </div>
                </form>
            </div>

            <div className="p-2">
                <DefaultTable pointer={true} onRowClicked={onRowClicked} gridOptions={{
                    rowData: merchants, colDefs: COLUMN_DEFINITIONS.viewMerchants, frameworkComponents: {
                        'ActionCellRenderer': ActionCellRenderer,
                        // 'customTooltip': CustomTooltip
                    }, rowAnimation: true, pagination: true, defaultColDef: { filter: true }
                }} ></DefaultTable>
            </div>
        </div>
    );
}

export default RespondentSearch;