import React, { useEffect, useState } from 'react';

import DefaultTable from './Grid/DefaultTable';
import {COLUMN_DEFINITIONS } from "../config/index";
import { useDispatch, useSelector } from 'react-redux';
import { getMerchants } from '../redux/actions/merchants';


function ViewMerchant(props) {


    const merchants = useSelector(state => state.merchants.merchants);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMerchants());
    } , []);


    return (

        <div>
            <DefaultTable gridOptions={{rowData:merchants, colDefs: COLUMN_DEFINITIONS.viewMerchants, rowAnimation:true, pagination: true, defaultColDef:{filter:true}}} ></DefaultTable>
        </div>
    );
}

export default ViewMerchant;