import React from 'react'



export default function HorizontalLine() {

    return (
       <div style={{height:'1px !important', padding:'1px', backgroundColor:'#aaa'}}></div>
    )
}
