import React from 'react';

import { Link } from 'react-router-dom';


import { useHistory } from 'react-router-dom';


function SimpleNavigator(props) {
    const history = useHistory();

    return (
        <div className="pl-2 pr-2 mb-4" >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <div>
                    {
                        !!props.previous &&
                        <Link onClick={() => {
                            props.previousAction &&
                                props.previousAction();
                        }}>
                            <button type="button" className="btn btn-primary">
                                {
                                    props.prevValue || "Previous"
                                }
                            </button>
                        </Link>
                    }
                </div>

                <div>
                    {
                        !!props.middle &&
                        <Link onClick={() => {
                            props.middleAction &&
                                props.middleAction();
                        }}>
                            <button type="button" className="btn btn-primary">
                                {
                                    props.middleValue || "Middle"
                                }
                            </button>
                        </Link>
                    }
                </div>

                <div>
                    {
                        !!props.next &&
                        <Link onClick={() => {
                            props.nextAction &&
                                props.nextAction();
                        }}>
                            <button type="button" className={props.nextBtnClass ? props.nextBtnClass : `btn btn-primary`}>
                                {
                                    props.nextValue || "Next"
                                }
                            </button>
                        </Link>
                    }
                </div>
            </div>
        </div>
    );
}

export default SimpleNavigator;