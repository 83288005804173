import { LOGIN, LOGOUT } from '../actions/auth';

const initialState = {
  isLoggedIn: !!localStorage.getItem('token'),
  token: localStorage.getItem('token')
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        token: action.token
      };
    case LOGOUT:
      localStorage.setItem('token', '');
      return {
        ...state,
        isLoggedIn: false,
        token: ''
      }
    default:
      return state;
  }
}