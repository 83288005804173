export const SET_CURRENT_TRANSACTION = 'SET_CURR_TRANS';
export const GET_CURRENT_TRANSACTION = 'GET_CURR_TRANS';
export const SAVE_CURRENT_TRANSACTION = 'SAVE_CURRENT_TRANSACTION';
export const CLEAR_CURRENT_TRANSACTION = 'CLEAR_CURRENT_TRANSACTION';

export const setCurrentTransaction = (data) => {
    return {
        type: SET_CURRENT_TRANSACTION,
        transaction: data
    }
}

export const getCurrentTransaction = (temp_trans_id) => {
    return {
        type: GET_CURRENT_TRANSACTION,
        temp_trans_id
    }
}


export const saveCurrentTransaction = (payload) => {
    return {
        type: SAVE_CURRENT_TRANSACTION,
        payload
    }
}

export const clearCurrentTransaction = () => {
    return {
        type : CLEAR_CURRENT_TRANSACTION
    }
}