import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";

import '../../assets/styles/form.css';
import HorizontalLine from '../components/HorLine';

import { ROUTES } from "../config/index";
import { postData } from "../services/service-call";

function AddMerchant(props) {



    const { register, getValues, handleSubmit, watch, formState: { errors }, setValue, clearErrors } = useForm();

    const onInvalid = async data => {
        console.log(formValues);

    }

    const onSubmit = async data => {

        console.log(data);
        const result = await postData({ url: ROUTES.addNewMerchant, body: data })
        console.log(result);
    }

    let [formValues, setFormValues] = useState(getValues());

    const addMerchantContact = () => {

        clearErrors();
        setValue('contact_details', [...formValues.contact_details, { first_name: 'Rishab', last_name: 'Surana', telephone_number: '8827929160', email: 'rsurana@lpacaribbean.com' }])

        setFormValues(getValues())
    }

    const addMerchantBranch = () => {

        clearErrors();
        setValue('merchant_branch_details', [...formValues.merchant_branch_details, { name: 'Rishab', last_name: 'Surana', telephone_number: '8827929160', email: 'rsurana@lpacaribbean.com' }])
        setFormValues(getValues())
    }

    const addProductAndService = () => {
        clearErrors();
        setValue('product_service_details', [...formValues.product_service_details, { name: 'Rishab', last_name: 'Surana', telephone_number: '8827929160', email: 'rsurana@lpacaribbean.com' }])
        setFormValues(getValues())
    }




    // to populate default values while testing
    useEffect(() => {

        if (process.env.REACT_APP_ENV === 'development') {

            setValue('merchant_details', {
                name: 'firstnkname', type: 'Company', telephone_number: '67866986', parish: 'Jewish',
                website: 'RISHA@OU.COM'
            });

            setValue('address', {
                address1: 'Address 1', address2: 'Address2', city: 'city', state: 'state', zip_code: 'zip',
                'country': 'Barbados'
            })

            setValue('contact_details', [{ first_name: 'Rishab', last_name: 'Surana', telephone_number: '8827929160', email: 'rsurana@lpacaribbean.com' }]);

            setValue('merchant_branch_details', [{ name: 'Rishab', last_name: 'Surana', telephone_number: '8827929160', email: 'rsurana@lpacaribbean.com' }]);

            setValue('product_service_details', [{ name: 'Rishab', last_name: 'Surana', telephone_number: '8827929160', email: 'rsurana@lpacaribbean.com' }]);

            setFormValues(getValues())


        }

    }, []);

    return (
        <div>

            <div className="container-fluid pt-4">
                <div className="form-container">
                    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
                        <div className="sub-form-header">Respondent Details</div>
                        <HorizontalLine></HorizontalLine>
                        <div className="row">
                            <div className="col">
                                <label>Name</label>
                                <input type="text"  {...register("merchant_details.name", { required: true })} className="form-control form-control-sm" placeholder="First name" />
                                {errors.merchant_details && errors.merchant_details.name && <span>This field is required</span>}
                            </div>


                            <div className="col">
                                <label>Merchant Type</label>

                                <select className="form-control form-control-sm" {...register("merchant_details.type", { required: true })}  >
                                    <option selected value="NO_VALUE">---Select---</option>
                                    <option value="Company">Company</option>
                                    <option value="Individual">Individual</option>
                                </select>
                                {errors.merchant_details && errors.merchant_details.type && <span>This field is required</span>}
                            </div>



                            <div className="col">
                                <label>Website</label>
                                <input type="text" className="form-control form-control-sm" {...register("merchant_details.website", { required: true })} placeholder="Website" />
                                {errors.merchant_details && errors.merchant_details.email && <span>This field is required</span>}
                            </div>

                        </div>



                        <div className="sub-form-header pt-4">Respondent Branch Details   <span><button type="button" onClick={() => addMerchantBranch()} className="btn mb-1 btn-primary">
                            Add
                        </button></span> </div>
                        <HorizontalLine></HorizontalLine>

                        {
                            formValues.merchant_branch_details?.map((branch_details, index) => {
                                return <div style={{ border: '1px solid black' }} className="p-1 mt-2" key={index}>

                                    <div><b>Branch# {index + 1}</b></div>
                                    <div className="row">

                                        <div className="col">
                                            <label>Branch Name</label>
                                            <input type="text"  {...register(`merchant_branch_details[${index}].name`, { required: true })} className="form-control form-control-sm" placeholder="Street address" />
                                            {errors.merchant_branch_details && errors.merchant_branch_details[index].name && <span>This field is required</span>}
                                        </div>

                                        <div className="col">
                                            <label>Apt, Suite, (Building No.)</label>
                                            <input type="text"  {...register(`merchant_branch_details[${index}].address_line_1`)} className="form-control form-control-sm" placeholder="Street address" />

                                        </div>

                                        <div className="col">
                                            <label>State/Province/Region</label>
                                            <input type="text"  {...register(`merchant_branch_details[${index}].state_province_region`)} className="form-control form-control-sm" placeholder="Street address" />

                                        </div>

                                        <div className="col">
                                            <label>City</label>
                                            <input type="text"  {...register(`merchant_branch_details[${index}].city`)} className="form-control form-control-sm" placeholder="Street address" />

                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col">
                                            <label>Country</label>
                                            <input type="text"  {...register(`merchant_branch_details[${index}].city`)} className="form-control form-control-sm" placeholder="Street address" />

                                        </div>


                                        <div className="col">
                                            <label>Parish</label>
                                            <select className="form-control form-control-sm" {...register(`merchant_branch_details[${index}].parish`, { required: true })}  >
                                                <option selected value="NO_VALUE">---Select---</option>
                                                <option value="Company">Company</option>
                                                <option value="Individual">Individual</option>
                                            </select>
                                        </div>

                                        <div className="col">
                                            <label>Telephone Number</label>
                                            <input type="text"  {...register(`merchant_branch_details[${index}].telephone_number`)} className="form-control form-control-sm" placeholder="Street address" />

                                        </div>


                                        <div className="col">
                                            <label>Country</label>
                                            <input type="text"  {...register(`merchant_branch_details[${index}].email`)} className="form-control form-control-sm" placeholder="Street address" />

                                        </div>




                                    </div>
                                </div>
                            })
                        }

                        <div className="sub-form-header pt-4">Respondent Contact Details  <span><button type="button" onClick={() => addMerchantContact()} className="btn mb-1 btn-primary">
                            Add
                        </button></span> </div>

                        <HorizontalLine></HorizontalLine>

                        {
                            formValues.contact_details?.map((contact_detail, index) => {
                                return <div style={{ border: '1px solid black' }} className="p-1 mt-2">

                                    <div><b>Contact# {index + 1}</b></div>
                                    <div className="row" key={index}>
                                        <div className="col">
                                            <label>First Name</label>
                                            <input type="text"  {...register(`contact_details[${index}].first_name`, { required: true })} className="form-control form-control-sm" placeholder="Name" />
                                            {errors.contact_details && errors.contact_details[index].first_name && <span>This field is required</span>}
                                        </div>



                                        <div className="col">
                                            <label>Last Name</label>
                                            <input type="text"  {...register(`contact_details[${index}].last_name`, { required: true })} className="form-control form-control-sm" placeholder="Location(Street, City)" />
                                            {errors.contact_details && errors.contact_details[index].last_name && <span>This field is required</span>}
                                        </div>

                                        <div className="col">
                                            <label>Email Address</label>
                                            <input type="text"  {...register(`contact_details[${index}].email`, { required: true })} className="form-control form-control-sm" placeholder="Location(Street, City)" />
                                            {errors.contact_details && errors.contact_details[index].email && <span>This field is required</span>}
                                        </div>

                                        <div className="col">
                                            <label>Telephone Number</label>
                                            <input type="text"  {...register(`contact_details[${index}].telephone_number`, { required: true })} className="form-control form-control-sm" placeholder="Name" />
                                            {errors.contact_details && errors.contact_details[index].telephone_number && <span>This field is required</span>}
                                        </div>

                                    </div>
                                </div>
                            })
                        }

                        <div className="sub-form-header pt-4">Respondent Product/Service Details  <span><button type="button" onClick={() => addProductAndService()} className="btn mb-1 btn-primary">
                            Add
                        </button></span> </div>

                        <HorizontalLine></HorizontalLine>

                        {
                            formValues.product_service_details?.map((prodServDetails, index) => {
                                return <div style={{ border: '1px solid black' }} className="p-1 mt-2" >

                                    <div><b>Product/Service# {index + 1}</b></div>
                                    <div className="row" key={index}>



                                        <div className="col">
                                            <label>Product/Service</label>
                                            <select className="form-control form-control-sm" {...register(`product_service_details[${index}].product_or_service`, { required: true })}  >
                                                <option selected value="NO_VALUE">---Select---</option>
                                                <option value="PRODUCT">Product</option>
                                                <option value="SERVICE">Service</option>
                                            </select>
                                        </div>


                                        <div className="col">
                                            <label>Category</label>
                                            <select className="form-control form-control-sm" {...register(`product_service_details[${index}].complaint_category`, { required: true })}  >
                                                <option selected value="NO_VALUE">---Select---</option>
                                                <option value="Company">Company</option>
                                                <option value="Individual">Individual</option>
                                            </select>
                                        </div>


                                        <div className="col">
                                            <label>Sub Category</label>
                                            <select className="form-control form-control-sm" {...register(`product_service_details[${index}].complaint_sub_category`, { required: true })}  >
                                                <option selected value="NO_VALUE">---Select---</option>
                                                <option value="Company">Company</option>
                                                <option value="Individual">Individual</option>
                                            </select>
                                        </div>


                                        <div className="col">
                                            <label>Industry</label>
                                            <select className="form-control form-control-sm" {...register(`product_service_details[${index}].industry`, { required: true })}  >
                                                <option selected value="NO_VALUE">---Select---</option>
                                                <option value="Company">Company</option>
                                                <option value="Individual">Individual</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>


                            })
                        }

                        <input className="mt-3" type="submit" />
                    </form>
                </div>
            </div>

        </div>
    );
}

export default AddMerchant;