import React, { useEffect, useState } from 'react';
import { ROUTES } from '../config';
import { getData } from '../services/service-call';
import ApplicationHeading from '../../js//components/ApplicationHeading';

function Profile(props) {


    const [profileData, setProfileDate] = useState({});

    useEffect(async () => {
        const result = await getData({
            url: ROUTES.getProfileInfo
        });
        setProfileDate(result.data);
        console.log(result.data);
    }, []);



    return (

            <div class="card text-center">

                <ApplicationHeading title={"Profile"}></ApplicationHeading>

                <div class="card-body">

                    <div class="text-center">
                        {
                            profileData.oauth_source !== 'MANUAL_EMAIL_PASSWORD' &&
                            <img src={profileData.picture} alt="Avatar" class="avatar" />
                        }


                        <h4>{profileData.name ?? null}</h4>
                        <h5>{profileData.email ?? null}</h5>
                    </div>
                </div>
        </div>
    );
}

export default Profile;