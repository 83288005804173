import React from 'react';

import "react-step-progress-bar/styles.css";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { ProgressBar, Step } from "react-step-progress-bar";

function ProgressStep(props) {
    return (
        <div className="pt-5 pb-3 pl-5 pr-5" style={{ overflow: 'hidden', textAlign:'center !important' }}>
            <ProgressBar
                percent={props.percent}
                filledBackground="linear-gradient(to right, #fefb72, #f0bb31)">
                <Step transition="scale">
                    {({ accomplished }) => (
                        <div><AssignmentTurnedInIcon className="svg_icons"></AssignmentTurnedInIcon></div>
                        
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <ListAltIcon className="svg_icons"></ListAltIcon>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <InsertDriveFileIcon className="svg_icons"></InsertDriveFileIcon>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <VisibilityIcon className="svg_icons"></VisibilityIcon>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <ReceiptIcon className="svg_icons"></ReceiptIcon>
                    )}
                </Step>
            </ProgressBar>
        
        <div className="mb-4">

        </div>
        
            <ProgressBar
                percent={100}
                filledBackground="linear-gradient(to right, #fff, #fff)">
                <Step transition="scale">
                    {({ accomplished }) => (
                        <span>Guidelines</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span>Fill Form</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span>Upload Documents</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span>Preview</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span style={{textAlign:'center' }}>Transaction Details</span>
                    )}
                </Step>
            </ProgressBar>
        </div>
    );
}

export default ProgressStep;