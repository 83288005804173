import React, { useEffect, useState } from 'react';

import DefaultTable from './Grid/DefaultTable';
import { COLUMN_DEFINITIONS } from "../config/index";
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../services/service-call';
import { ROUTES } from '../config/index';
import ActionCellRenderer from './ActionCellRenderer';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PARISH_LIST, ALL_DROPDOWNS } from "../config/index";

function SelectBranch(props) {


    const onRowClicked = (data) => {
        props.onSelectBranch(data.data);
    }

    return (
        <div>

        <h2>Select Branch</h2>
          
            <div className="p-2">
                <DefaultTable pointer={true} onRowClicked={onRowClicked} gridOptions={{
                    rowData: props.branches, colDefs: COLUMN_DEFINITIONS.viewBranchCAC, frameworkComponents: {
                        'ActionCellRenderer': ActionCellRenderer,
                        // 'customTooltip': CustomTooltip
                    }, rowAnimation: true, pagination: true, defaultColDef: { filter: true }
                }} ></DefaultTable>
            </div>
        </div>
    );
}

export default SelectBranch;