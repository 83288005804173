import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from "react-hook-form";

import '../../assets/styles/form.css';
import HorizontalLine from '../components/HorLine';
import { getCurrentTransaction } from '../redux/actions/current-transaction';
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../config/index";
import SimpleNavigator from '../components/SimpleNavigator';
import { postData } from "../services/service-call";
import RespondentSearch from '../components/MerchantSearch';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import _ from 'lodash';
import AlertDialogSlide from '../components/Modal/Modal';

import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom';
import SelectBranch from '../components/SelectBranch';

export const RequestForInfoForm = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const buttonSubmitRef = useRef();
    const [data, setFormData] = useState(null);

    const currentAppState = useSelector((state) => state.currentTransaction);
    let appDetails = useSelector((state) => {
        console.log(currentAppState);
        // return APP_SUBMISSION_CONFIG[params.appType];
        return APP_SUBMISSION_CONFIG[currentAppState.appType];
    });

    const componentRef = useRef();

    const transactionDetails = useSelector(state => {
        return state.currentTransaction;
    });



	let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });
    const saveYourStore = () => {
        const data = getValues();
        data.temp_trans_id = props.temp_trans_id;
        setFormData(data);
    }

    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const submitAllDetails = () => {
        history.push('/ftc/transaction-details');
    }

    useEffect(() => {
        if (transactionDetails.temp_trans_id) {
            dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
        }
    }, [transactionDetails.temp_trans_id]);

    useEffect(() => {

        if (transactionDetails.transaction) {
            setValue('informant_details', !!transactionDetails.transaction.informant_details?.[0] ? transactionDetails.transaction.informant_details?.[0] : {});
            setValue('respondent_details', !!transactionDetails.transaction.respondent_details?.[0] ? transactionDetails.transaction.respondent_details?.[0] : {});
            setValue('complaint_details', !!transactionDetails.transaction.request_for_application ? transactionDetails.transaction.request_for_application : {});
        }

    }, [transactionDetails.transaction]);

    const [dropdowns, setAllDropdowns] = useState({});
    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

    // const watchInformantParish = watch("respondent_details.parish");



    const onSubmit = async data => {

        data.temp_trans_id = props.temp_trans_id;
        data.app_type = appType;
        localStorage.setItem('generic_applications_data', JSON.stringify(data));
        const result = await postData({ url: ROUTES.saveRequestForApplication, body: data });
        localStorage.setItem('generic_applications_data', JSON.stringify(result.data));
        console.log(result);
        history.push(props.next);
    }

    const saveAndContinueLater = async () => {
        const data = getValues();
        localStorage.setItem('generic_applications_data', JSON.stringify(data));
        data.temp_trans_id = props.temp_trans_id;
        data.app_type = appDetails.application.type;
        const result = await postData({ url: ROUTES.saveRequestForApplication, body: data });
        console.log(result);
        history.push('/home');
    }  

    const nextAction = () => {
        buttonSubmitRef.current.click();
    }

    const previousAction = () => {
        history.push(props.previous);
    }

    // to populate default values while testing
    useEffect(async () => {



        var { parishes, industries, products } = await ALL_DROPDOWNS;
        setAllDropdowns({
            parishes, industries, products
        });

        if (process.env.REACT_APP_ENV === 'development') {

        }

    }, []);


    return (
        <div className="container-fluid " ref={componentRef}>



            <div className="head-text">{props.isPreview ? 'Preview Filing' : 'Request for Information Form'}</div>

            <div className="d-flex flex-row-reverse mb-1">
                <button onClick={handlePrint} hidden={!props.isDisabled || true} className="btn btn-primary" type="button">Print</button>

            </div>
            <div className="form-container">
                <fieldset disabled={props.isDisabled}>
                    <form onSubmit={handleSubmit(onSubmit)} onChange={() => saveYourStore()}  >


                        <div className='shadow p-3 mb-3 bg-white rounded'>

                            <div className='sub-form-header'>Info</div>

                            <div className='row'>
                                <div className="col">
                                    <label>Is this a follow-up to a question you previously sent to the Fair Trading Commission?
                                    </label>

                                    <select className="form-control" {...register("complaint_details.is_follow_up", { required: true })}  >
                                        <option value="">---Select---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>

                                    </select>
                                    {errors?.complaint_details?.is_follow_up && <span>This field is required</span>}
                                </div>


                                {
                                    data?.complaint_details?.is_follow_up === 'Yes' &&
                                    <div className="col">
                                        <label>Reference Number</label>

                                        <input type="text"  {...register("complaint_details.reference_number", { required: true })} className="form-control" placeholder="Reference Number" />
                                        {errors?.complaint_details?.reference_number && <span>This field is required</span>}
                                    </div>
                                }

                            </div>

                        </div>
                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header">Applicant Information</div>
                            <HorizontalLine></HorizontalLine>

                            <div className="row">
                                <div className="col">
                                    <label>Title</label>

                                    <select className="form-control" {...register("informant_details.title", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms.">Ms.</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Prof.">Prof.</option>
                                        <option value="Rev.">Rev.</option>
                                        <option value="Sr.">Sr.</option>
                                        <option value="Jr.">Jr.</option>
                                        <option value="Hon.">Hon.</option>
                                        <option value="Rt. Hon.">Rt. Hon.</option>
                                        <option value="Esq.">Esq.</option>
                                        <option value={"Other"}>Other</option>
                                    </select>
                                    {errors?.informant_details?.title && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>First Name</label>
                                    <input type="text"  {...register("informant_details.first_name", { required: true, maxLength: { value: 25, message: "Max length is 25" } })} className="form-control" placeholder="First name" />
                                    {errors.informant_details?.first_name && <span>This field is required</span>}
                                </div>
                                <div className="col">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" {...register("informant_details.last_name", { required: true, maxLength: { value: 25, message: "Max length is 25" } })} placeholder="Last name" />
                                    {errors.informant_details?.last_name && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Company Name</label>
                                    <input type="text" className="form-control" {...register("informant_details.company_name", { required: true })} placeholder="Company Name" />
                                    {errors.informant_details?.company_name && <span>This field is required</span>}
                                </div>

                            </div>

                            <div className="row">


                                <div className="col">
                                    <label>Street Address</label>
                                    <input type="text" className="form-control" {...register("informant_details.street_address", { required: true })} placeholder="Street Address" />
                                    {errors.informant_details?.street_address && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Parish</label>

                                    <select className="form-control" {...register("informant_details.parish", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.informant_details?.parish && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Contact Number</label>
                                    <input type="text" className="form-control" {...register("informant_details.phone_number", { required: true })} placeholder="Phone Number" />
                                    {errors.informant_details?.phone_number && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Email Address</label>
                                    <input type="email" className="form-control" {...register("informant_details.email_address", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} placeholder="Email Address" />
                                    {errors.informant_details?.email_address && <span>This field is required</span>}
                                </div>
                            </div>

                        </div>


                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header pt-4">Details of Your Request</div>
                            <HorizontalLine></HorizontalLine>


                            <div className="row">
                              
                            <div className="col">
                                    <label>Product</label>

                                    <select className="form-control" {...register("complaint_details.product", { required: true })}>
                                        <option value="">---Select---</option>
                                        <option value="Product">Product</option>
                                        <option value="Service">Service</option>


                                    </select>

                                    {/* <select className="form-control" {...register("complaint_details.product", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.products?.map((product) => {
                                                return <option value={product.name}>{product.name}</option>
                                            })
                                        }

                                    </select> */}
                                    {errors.complaint_details?.product && <span>This field is required</span>}

                                </div>


                                <div className="col">
                                    <label>Industry</label>
                                    <select className="form-control" {...register("complaint_details.industry", { required: true })}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.industries?.map((industry) => {
                                                return <option value={industry.name}>{industry.name}</option>
                                            })
                                        }

                                    </select>
                                    {errors.complaint_details?.industry && <span>This field is required</span>}

                                </div>


                             



                            </div>

                            <div className='row'>
                            <div className="col">
                                    <label>Please provide as much information as possible on the subject and nature of your question</label>
                                    <textarea type="text"  {...register("complaint_details.issue_description", { required: true })} className="form-control" placeholder="Describe your Problem" />
                                    {errors.complaint_details?.issue_description && <span>This field is required</span>}
                                </div>
                            </div>

                        </div>


                        <div className="d-flex flex-row-reverse">
                            <div className="ml-2">
                                <button hidden={true} ref={buttonSubmitRef} className="btn btn-primary" type="submit">Submit</button>
                            </div>

                            <div>
                                {/* <button hidden={props.isDisabled} onClick={() => saveAndContinueLater()} className="btn btn-primary" type="button">Save & Continue Later</button> */}

                            </div>

                        </div>
                    </form>

                </fieldset>
                <div hidden={props.isDisabled}>
                    <SimpleNavigator next="/ftc/guidelines" middle middleValue={"Save & Continue Later"}
                        middleAction={saveAndContinueLater} nextAction={nextAction} previousAction={previousAction} previous="/ftc/guidelines" />

                    {/* onClick={() => props.handlePrint()} */}
                    {/* <button onClick={handlePrint} hidden={!props.isDisabled} className="btn btn-primary" type="button">Print</button> */}

                    {/* <button onClick={() => submitAllDetails()} hidden={!props.isDisabled} className="btn btn-primary ml-1" type="button">Submit</button> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        temp_trans_id: state.currentTransaction.temp_trans_id
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RequestForInfoForm)
