import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";

import '../../assets/styles/form.css';
import HorizontalLine from '../components/HorLine';
import { getCurrentTransaction } from '../redux/actions/current-transaction';
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../config/index";
import SimpleNavigator from '../components/SimpleNavigator';
import { postData } from "../services/service-call";

import { useReactToPrint } from 'react-to-print';
import { Router, useHistory } from 'react-router-dom';

export const RequestForAdviceForm = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const buttonSubmitRef = useRef();
    const [data, setFormData] = useState(null);

    const componentRef = useRef();

    const transactionDetails = useSelector(state => {
        return state.currentTransaction;
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const saveYourStore = () => {
        const data = getValues();
        setFormData(data);
    }

    const submitAllDetails = () => {
        history.push('/ftc/transaction-details');
    }

    useEffect(() => {
        if (transactionDetails.temp_trans_id) {
            dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
        }
    }, [transactionDetails.temp_trans_id]);

    useEffect(() => {

        if (transactionDetails.transaction) {
            setValue('informant_details', !!transactionDetails.transaction.informant_details?.[0] ? transactionDetails.transaction.informant_details?.[0] : {});
            setValue('respondent_details', !!transactionDetails.transaction.respondent_details?.[0] ? transactionDetails.transaction.respondent_details?.[0] : {});
            setValue('complaint_details', !!transactionDetails.transaction.request_for_advice ? transactionDetails.transaction.request_for_advice : {});
        }

    }, [transactionDetails.transaction]);

    const [dropdowns, setAllDropdowns] = useState({});
    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();
    const onSubmit = async data => {

        data.temp_trans_id = props.temp_trans_id;
        localStorage.setItem('request_for_advice', JSON.stringify(data));
        const result = await postData({ url: ROUTES.saveReqForAdvice, body: data });
        localStorage.setItem('request_for_advice', JSON.stringify(result.data));
        console.log(result);
        history.push(props.next);
    }

    const saveAndContinueLater = async () => {
        const data = getValues();
        localStorage.setItem('request_for_advice', JSON.stringify(data));
        data.temp_trans_id = props.temp_trans_id;
        const result = await postData({ url: ROUTES.saveReqForAdvice, body: data });
        console.log(result);
        history.push('/home');
    }

    const nextAction = () => {
        buttonSubmitRef.current.click();
    }

    const previousAction = () => {
        history.push(props.previous);
    }

    // to populate default values while testing
    useEffect(async () => {

        var { parishes, industries, products } = await ALL_DROPDOWNS;
        setAllDropdowns({
            parishes, industries, products
        });

        if (process.env.REACT_APP_ENV === 'development') {

            // let data = localStorage.getItem('cac_complaints');
            // data = !!data ? JSON.parse(data) : {};
            // setValue('informant_details', !!data.informant_details ? data.informant_details : {});
            // setValue('respondent_details', !!data.respondent_details ? data.respondent_details : {});
            // setValue('complaint_details', !!data.complaint_details ? data.complaint_details : {});


            // setValue('informant_details', {
            //     title: 'NO_VALUE', company_name: 'Zomato Ltd',
            //     parish: 'Clarendon',
            //     first_name: 'firstnkname', last_name: 'last_name', age: '18-24', phone_number: '67866986',
            //     emailAddress: 'RISHA@OU.COM', address1: 'Address 1', address2: 'Address2', city: 'city', state: 'state', zip_code: 'zip',
            //     email_address: 'rishab.suraa@lpacaribbean.com',
            //     street_address: 'Street Address',
            //     phone_number: '8827929160',
            //     'country': 'Barbados'
            // });

            // setValue('respondent_details', {
            //     title: 'NO_VALUE', company_name: 'Zomato Ltd',
            //     parish: 'Clarendon',
            //     first_name: 'firstnkname', last_name: 'last_name', age: '18-24', phone_number: '67866986',
            //     emailAddress: 'RISHA@OU.COM', address1: 'Address 1', address2: 'Address2', city: 'city', state: 'state', zip_code: 'zip',
            //     email_address: 'rishab.suraa@lpacaribbean.com',
            //     street_address: 'Street Address',
            //     phone_number: '8827929160',
            //     'country': 'Barbados'
            // });



            // setValue('complaint_details', {
            //     issue_description: 'My Issue Descriptions',
            //     harms_or_effects: 'Harms or effects',
            //     all_affected_directly: 'All effected Directly',
            //     industry: 'NO_VALUE',
            //     product: 'NO_VALUE',
            //     relationship_against_party: 'NO_VALUE'

            // });

        }

    }, []);


    return (
        <div className="container-fluid " ref={componentRef}>

            <div className="head-text">{props.isPreview ? 'Preview Filing' : 'Request for Advice'}</div>

            <div className="d-flex flex-row-reverse mb-1">
                <button onClick={handlePrint} hidden={!props.isDisabled || true} className="btn btn-primary" type="button">Print</button>

            </div>
            <div className="form-container">
                <fieldset disabled={props.isDisabled}>
                    <form onSubmit={handleSubmit(onSubmit)} onChange={() => saveYourStore()} >
                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header">Complainant's (Informant) Information</div>
                            <HorizontalLine></HorizontalLine>
                            <div className="row">


                                <div className="col">
                                    <label>First Name</label>
                                    <input type="text"  {...register("informant_details.first_name" , {required:true})} className="form-control" placeholder="First name" />
                                    {errors.informant_details?.first_name && <span>This field is required</span>}
                                </div>
                                <div className="col">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" {...register("informant_details.last_name", {required:true} )} placeholder="Last name" />
                                    {errors.informant_details?.last_name && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Age</label>

                                    <select className="form-control" {...register("informant_details.age", )}  >
                                        <option value="">---Select---</option>
                                        <option value="Under 18">Under 18</option>
                                        <option value="18 - 19">18-19</option>
                                        <option value="20 - 29">20-29</option>
                                        <option value="30 - 39">30-39</option>
                                        <option value="40 - 49">40-49</option>
                                        <option value="50 - 59">50-59</option>
                                        <option value="60 - 64">60-64</option>
                                        <option value="65 - 69">65-69</option>
                                        <option value="70 - 79">70-79</option>
                                        <option value="80 and Over">80 and Over</option>
                                    </select>
                                    {errors.informant_details?.age && <span>This field is required</span>}
                                </div>


                            </div>

                            <div className="row">


                                <div className="col">
                                    <label>Street Address</label>
                                    <input type="text" className="form-control" {...register("informant_details.street_address", )} placeholder="Street Address" />
                                    {errors.informant_details?.street_address && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Town or City</label>
                                    <input type="text" className="form-control" {...register("informant_details.town_city", )} placeholder="Town/City" />
                                    {errors.informant_details?.town_city && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Postal Code</label>
                                    <input type="text" className="form-control" {...register("informant_details.post_office", )} placeholder="Postal Code" />
                                    {errors.informant_details?.post_office && <span>This field is required</span>}
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Parish</label>

                                    <select className="form-control" {...register("informant_details.parish", )}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.informant_details?.parish && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Country</label>

                                    <select className="form-control" {...register("informant_details.country", )}>
                                        <option value="">---Select---</option>
                                        <option value="JAMAICA" selected>Jamaica</option>
                                        {/* {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        } */}
                                    </select>
                                    {errors.informant_details?.parish && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Email Address</label>
                                    <input type="email" className="form-control" {...register("informant_details.email_address", {required:true}, { pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} placeholder="Email Address" />
                                    {errors.informant_details?.email_address && <span>This field is required</span>}
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Home Phone</label>
                                    <input type="number" className="form-control" {...register("informant_details.home_phone", )} placeholder="Home Phone" />
                                    {errors.informant_details?.home_phone && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Work Phone</label>
                                    <input type="number" className="form-control" {...register("informant_details.work_phone", )} placeholder="Work Phone" />
                                    {errors.informant_details?.work_phone && <span>This field is required</span>}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                        </div>


                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header pt-4">Respondent Details</div>
                            <HorizontalLine></HorizontalLine>

                            <div className="row">

                                <div className="col">
                                    <label>Name of Company or Individual
                                        {/* Against Which You Are Complaining: */}
                                    </label>
                                    <input type="text" className="form-control" {...register("respondent_details.name", )} placeholder="Name" />
                                    {errors.respondent_details?.name && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Branch:</label>
                                    <input type="text" className="form-control" {...register("respondent_details.branch", )} placeholder="Branch" />
                                    {errors.respondent_details?.branch && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Street Address:</label>
                                    <input type="text" className="form-control" {...register("respondent_details.street_address", )} placeholder="Street Address" />
                                    {errors.respondent_details?.street_address && <span>This field is required</span>}
                                </div>


                            </div>


                            <div className="row">
                                <div className="col">
                                    <label>Town or City</label>
                                    <input type="text" className="form-control" {...register("respondent_details.town_city", )} placeholder="Town/City" />
                                    {errors.respondent_details?.town_city && <span>This field is required</span>}
                                </div>

                                {/* <div className="col">
                                    <label>Post Office</label>
                                    <input type="text" className="form-control" {...register("respondent_details.post_office", )} placeholder="Post Office" />
                                    {errors.respondent_details?.post_office && <span>This field is required</span>}
                                </div> */}

                                <div className="col">
                                    <label>Parish</label>

                                    <select className="form-control" {...register("respondent_details.parish", )}>
                                        <option value="">---Select---</option>
                                        {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.respondent_details?.parish && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Country</label>

                                    <select className="form-control" {...register("respondent_details.country", )}>
                                        <option value="">---Select---</option>
                                        <option value="JAMAICA" selected>Jamaica</option>
                                        {/* {
                                            dropdowns.parishes?.map((parish) => {
                                                return <option value={parish.name}>{parish.name}</option>
                                            })
                                        } */}
                                    </select>
                                    {errors.respondent_details?.country && <span>This field is required</span>}
                                </div>

                            </div>


                            <div className="row">




                                <div className="col">
                                    <label>Company Website</label>
                                    <input type="text" className="form-control" {...register("respondent_details.company_website", )} placeholder="Website" />
                                    {errors.respondent_details?.company_website && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Company Email Address</label>
                                    <input type="email" className="form-control" {...register("respondent_details.email_address", { pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} placeholder="Email" />
                                    {errors.respondent_details?.email_address && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Phone Number</label>
                                    <input type="text" className="form-control" {...register("respondent_details.phone_number", )} placeholder="Phone Number" />
                                    {errors.respondent_details?.phone_number && <span>This field is required</span>}
                                </div>

                            </div>



                        </div>



                        <div className="shadow p-3 mb-3 bg-white rounded">

                            <div className="sub-form-header pt-4">Complaint Details</div>
                            <HorizontalLine></HorizontalLine>


                            <div className="row">
                                <div className="col">
                                    <label>Category:</label>
                                    <select className="form-control" {...register("complaint_details.industry", )}>
                                        <option value="">---Select---</option>
                                        {/* <option value="Appliances, Electronic and Other Household Items">Appliances, Electronic and Other Household Items</option>
                                        <option value="Automotive">Automotive</option>
                                        <option value="Banking">Banking</option>
                                        <option value="Cable services">Cable services</option>
                                        <option value="Chemical, Pharmaceutical, Petroleum Products or services">Chemical, Pharmaceutical, Petroleum Products or services</option>
                                        <option value="Computer / Internet services">Computer / Internet services</option>
                                        <option value="Debt collection">Debt collection</option>
                                        <option value="Electronic funds transfer">Electronic funds transfer</option>
                                        <option value="Furniture">Furniture</option>
                                        <option value="Food &amp; Beverage">Food &amp; Beverage</option>
                                        <option value="Government agencies/ministries">Government agencies/ministries</option>
                                        <option value="Health">Health</option>
                                        <option value="Lending">Lending</option>
                                        <option value="Professional services">Professional services</option>
                                        <option value="Utility services">Utility services</option>
                                        <option value="Work / Education">Work / Education</option>
                                        <option value="Other">Other</option> */}
                                        <option value="CAT1">Furniture</option>
                                        <option value="CAT2">Hardware Items</option>
                                        <option value="CAT3">Electrical Equipment, Appliances etc.</option>
                                        <option value="CAT4">Utilities</option>
                                        <option value="CAT5">Cable Services</option>
                                        <option value="CAT6">Computer and Computer Parts</option>
                                        <option value="CAT7">Jewellery, Clothing Kitchenware</option>
                                        <option value="CAT8">Chemical, Pharmaceutical and Petroleum Products</option>
                                        <option value="CAT9">Food and Beverages</option>
                                        <option value="CAT10">Motor Vehicle & Parts</option>
                                        <option value="CAT11">Payment Difficulties ( in respect of tangible items only )</option>
                                        <option value="CAT12">Other ( Other complaints against individuals where there are apparent breaches of contracts in any areas not noted elsewhere )</option>
                                        <option value="CAT13">Other Services ( include complaints relating to services apart from those mentioned in 1- 11 )</option>
                                    </select>
                                    {errors.complaint_details?.industry && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>How Did You Make Initial contact With the Company:</label>
                                    <select className="form-control" {...register("complaint_details.intitial_contact_with_company", )}>
                                        <option value="">---Select---</option>
                                        <option value="Fax">Fax</option>
                                        <option value="In Person">In Person</option>
                                        <option value="Internet (Website)">Internet (Website)</option>
                                        <option value="Email">Internet / E-mail</option>
                                        <option value="Mail">Mail</option>
                                        <option value="Phone">Phone</option>
                                        <option value="Print">Print</option>
                                        <option value="TV-Radio">TV / Radio</option>
                                        <option value="Unknown">Unknown</option>
                                    </select>
                                    {errors.complaint_details?.intitial_contact_with_company && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Have you lodged a complaint with the company: </label>
                                    <select className="form-control" {...register("complaint_details.is_complaint_lodged_against_company", )}>
                                        <option value="">---Select---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {errors.complaint_details?.is_complaint_lodged_against_company && <span>This field is required</span>}
                                </div>



                            </div>

                            <div className="row">

                                <div className="col">
                                    <label>If yes, please indicate the date first lodged:</label>
                                    <input type="date"  {...register("complaint_details.complaint_lodged_against_company_date", )} className="form-control" />
                                    {errors.complaint_details?.complaint_lodged_against_company_date && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Date of Purchase, Service contracted
                                        {/* or Entered Contractual Agreement: */}
                                    </label>
                                    <input type="date"  {...register("complaint_details.date_of_purchase", )} className="form-control" />
                                    {errors.complaint_details?.date_of_purchase && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Indicate type of Purchase Agreement: </label>
                                    <select className="form-control" {...register("complaint_details.type_of_purchase_agreement", )}>
                                        <option value="">---Select---</option>
                                        <option value="N/A">Not Applicable</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Hire Purchase">Hire Purchase</option>
                                        <option value="Lay-Away">Lay Away</option>
                                        <option value="Lease">Lease</option>
                                    </select>
                                    {errors.complaint_details?.type_of_purchase_agreement && <span>This field is required</span>}
                                </div>
                            </div>

                            <div className="row">

                                <div className="col">
                                    <label>How Much Did the Company Ask You To Pay</label>
                                    <input type="number"  {...register("complaint_details.actual_payment_asked", )} className="form-control" placeholder="Payment Asked" />
                                    {errors.complaint_details?.actual_payment_asked && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>How Much Did You Actually Pay The Company</label>
                                    <input type="number"  {...register("complaint_details.actual_payment_made", )} className="form-control" placeholder="Payment Made" />
                                    {errors.complaint_details?.actual_payment_made && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>How Did You Pay the Company:</label>
                                    <select className="form-control" {...register("complaint_details.payment_mode_to_company", )}>
                                        <option value="">---Select---</option>
                                        <option value="N/A">Not Applicable</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Credit Card">Credit Card</option>
                                        <option value="Debit Card">Debit Card</option>
                                    </select>
                                    {errors.complaint_details?.payment_mode_to_company && <span>This field is required</span>}
                                </div>

                            </div>

                            <div className="row">



                                <div className="col">
                                    <label>Warranty Period Given (Month)</label>
                                    <input type="text"  {...register("complaint_details.warranty_period", )} className="form-control" placeholder="Warranty in Months" />
                                    {errors.complaint_details?.warranty_period && <span>This field is required</span>}
                                </div>

                                <div className="col">
                                    <label>Account or Receipt number</label>
                                    <input type="text"  {...register("complaint_details.account_receipt_number", )} className="form-control" placeholder="Account or Receipt Number" />
                                    {errors.complaint_details?.account_receipt_number && <span>This field is required</span>}
                                </div>

                                <div className="col">

                                </div>


                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>First Name (Representative or Salesperson)</label>
                                    <input type="text"  {...register("complaint_details.first_name_contact", )} className="form-control" placeholder="First name" />
                                    {errors.complaint_details?.first_name_contact && <span>This field is required</span>}
                                </div>


                                <div className="col">
                                    <label>Last Name (Representative or Salesperson)</label>
                                    <input type="text"  {...register("complaint_details.last_name_contact", )} className="form-control" placeholder="Last Name" />
                                    {errors.complaint_details?.last_name_contact && <span>This field is required</span>}
                                </div>

                                <div className="col">

                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Explain Your Problem: Please limit your entry to 2,000 characters (about 30 lines):</label>
                                    <textarea type="text"  {...register("complaint_details.issue_description", )} className="form-control" placeholder="Describe your problem" />
                                    {errors.complaint_details?.issue_description && <span>This field is required</span>}
                                </div>
                            </div>

                        </div>

                        <div className="d-flex flex-row-reverse">
                            <div className="ml-2">
                                <button hidden={true} ref={buttonSubmitRef} className="btn btn-primary" type="submit">Submit</button>
                            </div>

                            <div>
                                {/* <button hidden={props.isDisabled} onClick={() => saveAndContinueLater()} className="btn btn-primary" type="button">Save & Continue Later</button> */}

                            </div>

                        </div>
                    </form>

                </fieldset>
                <div hidden={props.isDisabled}>
                    <SimpleNavigator next="/ftc/guidelines" middle="" middleValue={"Save & Continue Later"}
                        middleAction={saveAndContinueLater} nextAction={nextAction} previousAction={previousAction} previous="/ftc/guidelines" />

                    {/* onClick={() => props.handlePrint()} */}
                    {/* <button onClick={handlePrint} hidden={!props.isDisabled} className="btn btn-primary" type="button">Print</button> */}

                    {/* <button onClick={() => submitAllDetails()} hidden={!props.isDisabled} className="btn btn-primary ml-1" type="button">Submit</button> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        temp_trans_id: state.currentTransaction.temp_trans_id
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RequestForAdviceForm);
