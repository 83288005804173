import React from 'react';

import ViewMerchant from '../components/ViewMerchant';
import AddMerchant from '../components/AddMerchant'


import { useHistory, Link, Switch } from 'react-router-dom';
import { BrowserRouter, Route } from 'react-router-dom';

function Merchant(props) {


    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", margin: '20px' }}>
                <div className="m-2">
                    <Link to="/merchant">
                        <button type="button" className="btn btn-outline-dark">View Merchants</button>
                    </Link>
                </div>
                <div className="m-2">
                    <Link to="/merchant/add">
                        <button type="button" className="btn btn-outline-dark">Add Merchant</button>
                    </Link>
                </div>
            </div>
            <Switch>
            <Route path={`${props.match.path}/add`} component={AddMerchant} />
            <Route  path={props.match.path} component={ViewMerchant} />
            </Switch>

        </div>
    );
}

export default Merchant;