import React, { useState, useEffect } from 'react';

import SimpleNavigator from '../SimpleNavigator';
import { getBase64 } from '../../utils/utils'
import { useHistory } from 'react-router-dom';
import { getData, postData } from '../../services/service-call'
import { ROUTES } from '../../config/index'
import '../../../assets/styles/common-styles.css';
import axios from 'axios';
import store from '../../redux/store';
import {setCurrentDocuments} from '../../redux/actions/documents';
import { useDispatch, useSelector } from 'react-redux';
import {APP_SUBMISSION_CONFIG} from '../../config/application-submission-config';
import { alertService } from '../../_services/alert.service';
//todo more actions and disable ness depending upon diff use case

function SupportingDocumentWithoutDefinedTypes(props) {

    let inputFileUpload;
    const history = useHistory();
    let initialDocument = [{ isUploaded: false }];
    let [documents, setDocuments] = useState(initialDocument);
    // let [documentProps, setDocumentProps] = useState([]);
    let state = store.getState();
    const [selectedFiles, setSelectedFiles] = useState(undefined);

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};
    

    let routingDetails = appDetails.steps.filter((step) => {
        if(step.currentStep === history.location.pathname){
            return true;
        }
        return false;
    })[0];

    const nextAction = () => {
        store.dispatch(setCurrentDocuments(
            documents.filter((document) => {
                return document.isUploaded
            })
        ));

        history.push(routingDetails.next);
    }

    const previousAction = () => {
        history.push(routingDetails.previous);
    }

    const selectFile = async (event, index=0) => {
        // single document code below
        // const file = event.target.files[0];
        // if(!file)
        //     return
        // documents[index].file = file;
        // setSelectedFiles(file);



        // multi document code below
        let files = event.target.files;
        if (!files)
            return

        files = Array.from(files).map((file) => file);

        setDocuments((documents) => {
            let updatedDocuments = [];
            documents.map((document, i) => {
                if (index === i) {
                    files.map((file) => {
                        updatedDocuments.push(Object.assign({}, document, {
                            isUploaded: false,
                            file
                        }))
                    });
                }
                updatedDocuments.push(document);
            });
            return updatedDocuments;//.concat(initialDocument);
        });
    };

    useEffect(async () => {
        let toUpdate = false;
        let localDocuments = Object.assign([], documents);
        for(let i=0;i<localDocuments.length;i++){
            if(!localDocuments[i].isUploaded && localDocuments[i].file){
                toUpdate = true;
                const result = await uploadFile(i)
                localDocuments[i] = {
                    ...localDocuments[i],
                    ...result
                }
            }
        }

        if(toUpdate){
            setDocuments(localDocuments);
        }
    }, [documents]);

    const uploadFile = async (index) => {
        if(documents[index].isUploaded){
            alertService.warn('Document already uploaded', { autoClose:true, keepAfterRouteChange: true });
            return;
        }
        const file = documents[index].file;
        if (!file)
            return;
        const { base64: content, contentType } = await getBase64(documents[index].file);
        try {
            const result = await postData({
                url: ROUTES.documentUpload, body: {
                    document: {
                        content,
                        contentType,
                        name: documents[index].file.name
                    },
                    temp_trans_id: state.currentTransaction.temp_trans_id
                }
            });
            // setDocuments((documents) => {
            //     return documents.map((document, ind) => {
            //         if (index === ind){
            //             document.isUploaded = true;
            //             document.id = result.data.id;
            //         }
            //         return document;
            //     });
            // });
            alertService.success('Document uploaded successfully', { autoClose:true, keepAfterRouteChange: true });
            return {
                isUploaded: true,
                id: result.data.id
            }
        } catch (err) {
            alertService.error('Document could not uploaded successfully', { autoClose:true, keepAfterRouteChange: true });
            return {
                isUploaded: false
            }
        }
    }

    const removeDocument =  async (index) => {

        const {id, isUploaded} = documents[index];
        if(!isUploaded){
            setDocuments((documents) => {
                return documents.splice(index, 1);
            })
            return
        }
        const result = await postData({
            url: `${ROUTES.removeDocument}${id}`
        });
        alertService.success('Document removed successfully', { autoClose:true, keepAfterRouteChange: true });
        getAllDocumentsUploaded();
    }

    const getAllDocumentsUploaded = () => {
        getData({ url: `${ROUTES.getUploadedDocuments}${state.currentTransaction.temp_trans_id}` }).then(data => {
            let newDocuments = data.map((document, index) => {
                document.isUploaded = true;
                document.file = {};
                document.file.name = document.file_name;
                return document;
            })

            setDocuments(newDocuments.concat(initialDocument));
        });
    }

    useEffect(() => {
        getAllDocumentsUploaded();
        alertService.info('Please upload the supporting documents', { autoClose:true, keepAfterRouteChange: false });
    }, []);


    // <button className="btn btn-primary" type="button" id="clickImage" onClick={() => inputFileUpload.click()} value="Upload">Select files...</button>

    // <input type="file" hidden ref={input => inputFileUpload = input} multiple onChange={(event) => selectFile(event, index)}></input> 

    return (
        <div>
            <div className="shadow-sm p-3 m-3 bg-light rounded">
                <div className="head-text">Supporting Documents</div>

                <div className="d-flex flex-row justify-content-between mb-1 mt-1">
                    <button className="btn btn-info" type="button" id="clickImage" onClick={() => inputFileUpload.click()} value="Upload">Select files...</button>

                    <input type="file" hidden ref={input => inputFileUpload = input} multiple onChange={(event) => selectFile(event)}></input>

                    <button className="btn btn-success ml-2" type="button" id="uploadAll" value="Upload">Upload All</button>

                </div>

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>File</th>
                            <th>Action</th>
                            <th>Upload Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documents.map( (document, index) => {
                                if(documents.length == index+1)
                                    return null;

                                return <tr key={index}>
                                    <td>{document.file?.name}</td>
                                  
                                    <td className="d-flex flex-row justify-content-start"> 
                                        <button onClick={() => uploadFile(index)} className="btn btn-warning">Upload File</button>
                                    
                                        <button onClick={() => {removeDocument(index);}} className="btn btn-danger ml-1">Remove File</button>

                                    </td>
                                    <td className={document.isUploaded ? 'success-text' : 'failure-text'}><b>{document.isUploaded ? 'Uploaded' : 'Not Uploaded'}</b> </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>


                

            </div>
            <SimpleNavigator next="/ftc/preview" nextAction={nextAction} previousAction={previousAction} previous="/ftc/form"></SimpleNavigator>
        </div>
    );
}

export default SupportingDocumentWithoutDefinedTypes;