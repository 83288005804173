import axios from "axios";
import { ROUTES } from "../../config";
import { trackPromise } from 'react-promise-tracker';

import { getData } from "../../services/service-call";


 export function requestGetMerchants(){
    return getData({
        url :  ROUTES.getMerchants
    }); 
 }