import React, { useEffect, useRef } from 'react';
import SimpleNavigator from '../SimpleNavigator';
import Form from './Form';
import FTCForm from '../../containers/FTCForm';
import CACForm from '../../containers/CACForm';
import RequestForAdviceForm from '../../containers/RequestForAdviceForm';
import RequestForInfo from '../../containers/RequestForInfo';
import AdvocacySuggestionForm from '../../containers/AdvocacySuggestionForm';
import RequestForAuthorizationForm from '../../containers/RequestForAuthorizationForm';
import RequestForOpinionForm from '../../containers/RequestForOpinionForm';
import ReactToPrint from "react-to-print";
import store from '../../redux/store';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APP_SUBMISSION_CONFIG } from '../../config/application-submission-config';
import { alertService } from '../../_services/alert.service';
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../../config/index";
import { postData } from "../../services/service-call";
import {saveCurrentTransaction} from '../../redux/actions/current-transaction';

import { useReactToPrint } from 'react-to-print';
import GenericApplicationSubmssion from '../../containers/GenericApplicationSubmssion';


function Preview(props) {

    
    const componentRef = useRef();

    const history = useHistory();
    const temp_trans_id = useSelector((state) => state.currentTransaction.temp_trans_id);
    const dispatch = useDispatch();

    let currentTransaction = useSelector((state) => {
        return state.currentTransaction;
    })

    
    let documents = useSelector((state) => {
        return state.documents.currentDocuments;
    })

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    

    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};

    let routingDetails = appDetails.steps.filter((step) => {
        if (step.currentStep === history.location.pathname) {
            return true;
        }
        return false;
    })[0];

    const nextAction = async () => {

        let result = {};
        if (appType === 'COMPETITION_COMPLAINTS') {
            let data = localStorage.getItem('ftc_complaints');
            data = !!data ? JSON.parse(data) : {};
            result = await postData({
                url: ROUTES.competitionComplaints, body: {
                    ...data,
                    temp_trans_id: temp_trans_id,
                    app_type: appType
                }
            });
        } else if(appType === 'CONSUMER_COMPLAINTS') {
            let data = localStorage.getItem('cac_complaints');
            data = !!data ? JSON.parse(data) : {};
            result = await postData({
                url: ROUTES.consumerComplaints, body: {
                    ...data,
                    temp_trans_id: temp_trans_id,
                    app_type: appType
                }
            });
        }else if (appType === 'CONSUMER_REQUEST_FOR_ADVICE') {
            let data = localStorage.getItem('request_for_advice');
            data = !!data ? JSON.parse(data) : {};
            result = await postData({
                url: ROUTES.requestForAdvice, body: {
                    ...data,
                    temp_trans_id: temp_trans_id,
                    app_type: appType
                }
            });
        } else if(['REQUEST_FOR_ADVOCACY', 'REQUEST_FOR_MERGER_ACQUISITIONS', 'REQUEST_FOR_INFORMATION', 'REQUEST_FOR_AUTHORIZATION', 'REQUEST_FOR_OPINION'].indexOf(appType) !== -1){

            // here it is not changed because data is saved in one common local storage variable
            let data = localStorage.getItem('generic_applications_data');
            data = !!data ? JSON.parse(data) : {};
            result = await postData({
                url: ROUTES.launchGenericApplication, body: {
                    ...data,
                    temp_trans_id: temp_trans_id,
                    app_type: appType,
                    launchCase: true
                }
            });
        }

        if(result.statusCode === 200){
            alertService.success('Application submitted successfully', { autoClose:true, keepAfterRouteChange: true });
        }else{
            alertService.error('Error occured while submitting application', { autoClose:true, keepAfterRouteChange: true });
        }

        dispatch(saveCurrentTransaction(result));
        history.replace({pathname: routingDetails.next});
    }

    const previousAction = () => {
        history.push(routingDetails.previous);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    let form;
    if (props.application.type === 'COMPETITION_COMPLAINTS') {
        form = <FTCForm  isDisabled={true} isPreview={true}></FTCForm>;
    } else if (props.application.type === 'CONSUMER_COMPLAINTS') {
        form = <CACForm isDisabled={true} isPreview={true}></CACForm>;
    } else if(props.application.type === 'CONSUMER_REQUEST_FOR_ADVICE'){
        form = <RequestForAdviceForm isDisabled={true} isPreview={true}></RequestForAdviceForm>
    } 
    else if(props.application.type === 'REQUEST_FOR_INFORMATION') {
        // generic form converted to web form
        form = <RequestForInfo isDisabled={true} isPreview={true}></RequestForInfo>;
    } else if (props.application.type === 'REQUEST_FOR_ADVOCACY') {
        form = <AdvocacySuggestionForm isDisabled={true} isPreview={true}></AdvocacySuggestionForm>;
    } else if (props.application.type === 'REQUEST_FOR_AUTHORIZATION') {
        form = <RequestForAuthorizationForm isDisabled={true} isPreview={true}></RequestForAuthorizationForm>;
    } else if(props.application.type === 'REQUEST_FOR_OPINION'){
        form = <RequestForOpinionForm isDisabled={true} isPreview={true} ></RequestForOpinionForm>
    }
    else if(['REQUEST_FOR_ADVOCACY', 'REQUEST_FOR_MERGER_ACQUISITIONS', 'REQUEST_FOR_INFORMATION', 'REQUEST_FOR_AUTHORIZATION', 'REQUEST_FOR_OPINION'].indexOf(props.application.type) !== -1){
        form = <GenericApplicationSubmssion isDisabled={true} isPreview={true} ></GenericApplicationSubmssion>
    } else {
        form = <FTCForm></FTCForm>;
    }

    useEffect(() => {
        alertService.info('Please the details and submit the application', { autoClose:true, keepAfterRouteChange: false });
    }, []);

    if(currentTransaction.submitted_transaction?.transaction_number){
        history.push('/home');
    }
 
    return (

        <div>
            <div>
                <div className="shadow-sm p-3 m-3 bg-light rounded">

                <SimpleNavigator nextAction={nextAction}
                 nextBtnClass="btn btn-success" nextValue="Submit" 
                 previousAction={previousAction} next="/ftc/transaction-details"
                  previous="/ftc/supporting-documents" 
                  middle middleAction={handlePrint} middleValue="Print"
                  ></SimpleNavigator>
           
                    <div ref={componentRef}>
                    {form}
                    </div>
                   

                    <div className="shadow p-3 m-3 bg-white rounded">

                        <div className="head-text">Supporting Documents</div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>File</th>
                                    <th>Upload Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    documents.map((document, index) => {
                                        return <tr key={index}>
                                            <td>{document.file?.name}</td>
                                            <td>{document.isUploaded ? 'Uploaded' : 'Not Uploaded'} </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>




                {/* <button onClick={handlePrint}>Print this out!</button> */}

            </div>
        </div >
    );
}

export default Preview;