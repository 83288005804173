import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from '../config';
import { getBase64 } from '../utils/utils'
import store from '../redux/store';
import { getData, postData } from '../services/service-call';
import HorizontalLine from '../components/HorLine';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import { useSelector } from 'react-redux';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { useHistory, useParams } from 'react-router-dom';
import { } from "../config/index";

function GenericApplicationSubmssion(props) {

    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();
    let state = store.getState();
    const history = useHistory();

    const params = useParams();
    const [downloadLink, setDownloadLinkHref] = useState();
    const [uploadedForm, setUploadedForm] = useState(null);

    const currentAppState = useSelector((state) => state.currentTransaction);
    const [dropdowns, setAllDropdowns] = useState({});

    let appDetails = useSelector((state) => {
        console.log(currentAppState);
        // return APP_SUBMISSION_CONFIG[params.appType];
        return APP_SUBMISSION_CONFIG[currentAppState.appType];
    });

    useEffect(async () => {

        var { parishes, industries, products } = await ALL_DROPDOWNS;
        setAllDropdowns({
            parishes, industries, products
        });


        const result = await getData({
            url: `${ROUTES.getDocumentDetailsForGenericApplication}/${currentAppState.temp_trans_id}`
        });


        setUploadedForm(result.data);

        const formDetails = await getData({
            url: `${ROUTES.getFormDetailsForGenericApplication}/${currentAppState.temp_trans_id}`
        });

        if (formDetails && formDetails.data) {
            localStorage.setItem('generic_applications_data', JSON.stringify(formDetails.data));    

            if (formDetails.data.informant_details) {
                setValue('informant_details', formDetails.data.informant_details);
            }
            if (formDetails.data.respondent_details) {
                setValue('respondent_details', formDetails.data.respondent_details);
            }

            if (formDetails.data.complaint_details) {
                setValue('complaint_details', formDetails.data.complaint_details);
            }
        }
        
      

    }, []);


    const onSubmit = async data => {


        console.log(data);
        let files = Array.from(data.transaction_details.appFile).map((file) => file);
        if (files.length > 0 || true) {
            let documentBody = null;
            if(files.length > 0){
                const { base64: content, contentType } = await getBase64(files[0]);
                documentBody = {
                    content,
                    contentType,
                    name: files[0].name,
                    documentType: 'APPLICATION_FORM'
                }
            }
            const result = await postData({
                url: ROUTES.launchGenericApplication, body: {
                    document: documentBody,
                    temp_trans_id: state.currentTransaction.temp_trans_id,
                    appType: data.transaction_details.app_type,
                    launchCase: false,
                    informant_details: data.informant_details,
                    respondent_details: data.respondent_details,
                    complaint_details: data.complaint_details
                }
            });

            console.log(appDetails);

            if (result.statusCode === 200) {
                history.push(appDetails.steps[2].currentStep);
            }
        } else {
            history.push(appDetails.steps[2].currentStep);
        }

        // console.log(result);
    }

    


    const downloadForm = () => {

    }

    const appTypeChanged = (e) => {

        // history.push('/form-submission/' + e.target.value);
        store.dispatch({
            type: SET_CURRENT_TRANSACTION,
            appType: e.target.value,
            transaction: {},
            submitted_transaction: null
        });

    }

    useEffect(() => {
        setDownloadLinkHref(appDetails.filePath);
    }, [appDetails]);

    return (
      <div className="generic-form-container">
        <fieldset disabled={props.isDisabled}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="shadow p-3 mb-3 bg-white rounded">
              <div className="sub-form-header">Applicant Information</div>
              <HorizontalLine></HorizontalLine>
              <div className="row">
                <div className="col">
                  <label>Title</label>

                  <select
                    className="form-control"
                    {...register("informant_details.title", { required: true })}
                  >
                     <option value="">---Select---</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms.">Ms.</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Prof.">Prof.</option>
                                        <option value="Rev.">Rev.</option>
                                        <option value="Sr.">Sr.</option>
                                        <option value="Jr.">Jr.</option>
                                        <option value="Hon.">Hon.</option>
                                        <option value="Rt. Hon.">Rt. Hon.</option>
                                        <option value="Esq.">Esq.</option>
                                        <option value={"Other"}>Other</option>
                  </select>
                  {errors.informant_details?.title && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="col">
                  <label>First Name</label>
                  <input
                    type="text"
                    {...register("informant_details.first_name", {
                      required: true,
                      maxLength: { value: 25, message: "Max length is 25" },
                    })}
                    className="form-control"
                    placeholder="First name"
                  />
                  {errors.informant_details?.first_name && (
                    <span>This field is required</span>
                  )}
                </div>
                <div className="col">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("informant_details.last_name", {
                      required: true,
                      maxLength: { value: 25, message: "Max length is 25" },
                    })}
                    placeholder="Last name"
                  />
                  {errors.informant_details?.last_name && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="col">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("informant_details.company_name", {
                      required: true,
                    })}
                    placeholder="Company Name"
                  />
                  {errors.informant_details?.company_name && (
                    <span>This field is required</span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label>Street Address</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("informant_details.street_address", {
                      required: true,
                    })}
                    placeholder="Street Address"
                  />
                  {errors.informant_details?.street_address && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="col">
                  <label>Parish</label>

                  <select
                    className="form-control"
                    {...register("informant_details.parish", {
                      required: true,
                    })}
                  >
                    <option value="">---Select---</option>
                    {dropdowns.parishes?.map((parish) => {
                      return <option value={parish.name}>{parish.name}</option>;
                    })}
                  </select>
                  {errors.informant_details?.parish && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="col">
                  <label>Contact Number</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("informant_details.phone_number", {
                      required: true,
                    })}
                    placeholder="Phone Number"
                  />
                  {errors.informant_details?.phone_number && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="col">
                  <label>Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    {...register("informant_details.email_address", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    placeholder="Email Address"
                  />
                  {errors.informant_details?.email_address && (
                    <span>This field is required</span>
                  )}
                </div>
              </div>
            </div>

            {appDetails?.application?.type === "REQUEST_FOR_INFORMATION" && (
              <>
                <div className="row">
                  <div className="col">
                    <label>Respondent Name</label>
                    <input
                      type="text"
                      {...register("respondent_details.name", {
                        required: false,
                        maxLength: { value: 75, message: "Max length is 75" },
                      })}
                      className="form-control"
                      placeholder="Name"
                    />
                    {errors.respondent_details?.name && (
                      <span>This field is required</span>
                    )}
                  </div>

                  <div className="col">
                    <label>Industry</label>

                    <select
                      className="form-control"
                      {...register("complaint_details.industry", {
                        required: true,
                      })}
                    >
                      <option value="">---Select---</option>
                      {dropdowns.industries?.map((industry) => {
                        return (
                          <option value={industry.name}>{industry.name}</option>
                        );
                      })}
                    </select>
                    {errors.complaint_details?.industry && (
                      <span>This field is required</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label>Information Requested</label>
                    <textarea
                      type="text"
                      {...register("complaint_details.issue_description", {
                        required: true,
                      })}
                      className="form-control"
                      placeholder="Information Requested"
                    />
                    {errors.complaint_details?.issue_description && (
                      <span>This field is required</span>
                    )}
                  </div>
                </div>
              </>
            )}

            {appDetails?.application?.type === "REQUEST_FOR_MERGER_ACQUISITIONS" && (
              <>
                <div className="row">
               

                  <div className="col">
                    <label>Industry</label>

                    <select
                      className="form-control"
                      {...register("complaint_details.industry", {
                        required: true,
                      })}
                    >
                      <option value="">---Select---</option>
                      {dropdowns.industries?.map((industry) => {
                        return (
                          <option value={industry.name}>{industry.name}</option>
                        );
                      })}
                    </select>
                    {errors.complaint_details?.industry && (
                      <span>This field is required</span>
                    )}
                  </div>
                </div>

                  {/* <textarea
                      type="text"
                      {...register("complaint_details.name_of_parties_involved", {
                        required: true,
                      })}
                      className="form-control"
                      placeholder="Name of parties involved"
                    />
                    {errors.complaint_details?.name_of_parties_involved && (
                      <span>This field is required</span>
                    )} */}

                <div className="row">
                  <div className="col">
                    <label>Name of the parties involved 1</label>

                    <input
                      type="text"
                      {...register("complaint_details.name_of_parties_involved1", {
                        required: false,
                        maxLength: { value: 200, message: "Max length is 200" },
                      })}
                      className="form-control"
                      placeholder="Name"
                    />
                    {errors.complaint_details?.name_of_parties_involved1 && (
                      <span>This field is required</span>
                    )}

                  </div>

                  <div className="col">
                    <label>Name of the parties involved 2</label>

                    <input
                      type="text"
                      {...register("complaint_details.name_of_parties_involved2", {
                        required: false,
                        maxLength: { value: 200, message: "Max length is 200" },
                      })}
                      className="form-control"
                      placeholder="Name"
                    />
                    {errors.complaint_details?.name_of_parties_involved2 && (
                      <span>This field is required</span>
                    )}

                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label>Name of the parties involved 3</label>

                    <input
                      type="text"
                      {...register("complaint_details.name_of_parties_involved3", {
                        required: false,
                        maxLength: { value: 200, message: "Max length is 200" },
                      })}
                      className="form-control"
                      placeholder="Name"
                    />
                    {errors.complaint_details?.name_of_parties_involved3 && (
                      <span>This field is required</span>
                    )}

                  </div>

                  <div className="col">
                    <label>Name of the parties involved 4</label>

                    <input
                      type="text"
                      {...register("complaint_details.name_of_parties_involved4", {
                        required: false,
                        maxLength: { value: 200, message: "Max length is 200" },
                      })}
                      className="form-control"
                      placeholder="Name"
                    />
                    {errors.complaint_details?.name_of_parties_involved4 && (
                      <span>This field is required</span>
                    )}

                  </div>
                </div>
              </>
            )}
            <div></div>

            <div className="shadow p-3 mb-3 bg-white rounded">
              <div className="row" style={{display: 'none'}}>
                <div className="col">
                  <label htmlFor="app_type">Type of Application</label>
                  <select
                    className="form-control"
                    value={appDetails.application.type}
                    {...register("transaction_details.app_type", {
                      required: true,
                    })}
                    onChange={(e) => {
                      appTypeChanged(e);
                    }}
                  >
                    <option disabled value="">
                      ---Select---
                    </option>
                    {/* web enabled */}
                    <option value="REQUEST_FOR_ADVOCACY">Advocacy</option>
                    {/* web enabled */}
                    <option value="REQUEST_FOR_INFORMATION">
                      Request for Information
                    </option>

                    {/* to be web enabled */}
                    <option value="REQUEST_FOR_OPINION">
                      Request for Opinion
                    </option>

                    <option value="REQUEST_FOR_MERGER_ACQUISITIONS">
                      File for Mergers & Acquisitions
                    </option>
                    {/* to be web enabled */}
                    <option value="REQUEST_FOR_AUTHORIZATION">
                      Request for Authorization
                    </option>
                  </select>
                </div>
              </div>
              <div className="row" hidden={props.isDisabled}>
                <div className="col">
                  <label htmlFor="fownload_form">Download Form</label>
                  <a target="_blank" rel="noreferrer" href={downloadLink}>
                    <button
                      id="downloadForm"
                      type="button"
                      onClick={() => downloadForm()}
                      className="form-control btn btn-warning"
                    >
                      Download Form
                    </button>
                  </a>
                </div>
              </div>

              <div className="row" hidden={props.isDisabled}>
                <div className="col">
                  <label htmlFor="uploadForm">Upload Form</label>
                  <br></br>
                  <input
                    id="uploadForm"
                    {...register("transaction_details.appFile", {
                      required: !uploadedForm?.file_name,
                    })}
                    type="file"
                  />
                </div>
              </div>

              {uploadedForm && (
                <div className="row">
                  <div className="col">
                    <label htmlFor="fownload_form">Uploaded Form</label>
                    <div>{uploadedForm.file_name}</div>
                  </div>
                </div>
              )}
            </div>

            <div className="row" hidden={props.isDisabled}>
              <div className="col">
                <button
                  id="submitForm"
                  type="submit"
                  style={{ float: "right" }}
                  className=" btn btn-primary"
                >
                  Submit Form
                </button>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    );
}

export default GenericApplicationSubmssion;