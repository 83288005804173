import React, { useEffect, useState } from 'react';


import DefaultTable from './Grid/DefaultTable';
import { COLUMN_DEFINITIONS } from "../config/index";
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../redux/actions/transactions';
import ApplicationHeading from './ApplicationHeading';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import store from '../redux/store';
import { useHistory } from 'react-router-dom';
import ActionCellRenderer from './ActionCellRenderer';
// import CustomTooltip from './customTooltip';

function Transactions(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [toShowProgressBar, setToShowProgressBar] = useState(false);
    const [commissionType, setCommissionType] = useState('');
    const [status, setStatus] = useState("");
    const [investigationStatus, setInvestigationStatus] = useState("");

    const allSteps = {
        'CAC': [
            'Case Received',
            'Case Status',
            'Mediation',
            'Case Resolution',
            'Case Closed'
        ],
        'FTC': [
            'Case Received',
            'Case Status',
            'Investigation',
            'Resolution Recommended',
            'Closure'
        ],

        "REQUEST_FOR_INFORMATION": [
            "In Progress",
            "Completed"
        ]
    };

    const [allStepsState, setAllStepsState] = useState(allSteps);

    const investigationSteps =[
        'Investigation Status',
        'Investigation Progress',
        'Completion Date'
    ]

    const stepsInfo = {
        "CAC": {
            "0": {
                title: allSteps['CAC'][0],
                positiveStep: 'Successful',
                negativeStep: 'Unsuccessful'
            },
            "1": {
                title: allSteps['CAC'][1],
                positiveStep: 'Under Review',
                negativeStep: 'Dismissed'
            },
            "2": {
                title: allSteps['CAC'][2],
                positiveStep: 'Successful',
                negativeStep: 'Unsuccessful'
            },
            "3": {
                title: allSteps['CAC'][3],
                positiveStep: 'Resolved',
                negativeStep: 'Litigation'
            },
            "4": {
                title: allSteps['CAC'][4],
                positiveStep: 'Yes',
                negativeStep: 'No'
            }
        },
        "FTC": {
            "0": {
                title: allSteps['FTC'][0],
                positiveStep: 'Successful',
                negativeStep: 'Unsuccessful'
            },
            "1": {
                title: allSteps['FTC'][1],
                positiveStep: 'Accepted',
                negativeStep: 'Dismissed'
            },
            "2": {
                title: allSteps['FTC'][2],
                positiveStep: 'Case Closed',
                negativeStep: 'Case In Progress'
            },
            "3": {
                title: allSteps['FTC'][3],
                positiveStep: 'Case Resolved',
                negativeStep: 'Case Under Litigation'
            },
            "4": {
                title: allSteps['FTC'][4],
                positiveStep: 'Case Closed',
                negativeStep: 'Litigation In Progress'
            }
        }
    }

    const investigationStepsInfo = {
        "0": {
            title: investigationSteps[0],
            positiveStep: "Opened",
            negativeStep: "Not Opened"
        },
        "1": {
            title: investigationSteps[1],
            positiveStep: "In Progress",
            negativeStep: "On Hold"
        },
        "2": {
            title: investigationSteps[2],
            positiveStep: "Expected",
            negativeStep: "Extended"
        }
    }
    const [progressMapping, setProgressMapping] = useState({});
    const [invProgressMapping, setInvProgressMapping] = useState({});
    const [currentData, setCurrentData] = useState({});
    const [caseType, setCaseType] = useState();

    const transactions = useSelector((state) => {
        return state.transactions.transactions || [];
    });

    useEffect(() => {
        dispatch(getTransactions())
    }, []);

    const onCellClicked = (params) => {

        setCurrentData(params.data);


        setCommissionType(params.data.commission_type);
        setToShowProgressBar(true);
        
        if(params.data.app_type === 'REQUEST_FOR_INFORMATION'){
            allSteps[params.data.app_type].unshift(params.data.status === 'APPLICATION NOT SUBMITTED' ?  "Not Received": "Received Successfully");   
        
            if (params.data.status === 'APPLICATION SUBMITTED') {
                params.data.status = "Received Successfully";
            } else if (params.data.status === 'APPLICATION NOT SUBMITTED') {
                params.data.status = "Not Received";
            }


            setAllStepsState(allSteps);
            
            const steps = allSteps[params.data.app_type];

            setCaseType(params.data.app_type);
            if(params.data.status === 'APPLICATION NOT SUBMITTED'){
                return

            }
            let map = {};
            for (let i = 0; i<steps.length; i++) {
    
                map[steps[i]] = true;
                if (steps[i] === params.data.status) {
    
                    break;
                }
            }
            // allSteps[params.data.app_type].shift();

            setProgressMapping(map);
            return;

        }
        setCaseType(params.data.app_type);

        if (params.data.status === 'APPLICATION SUBMITTED') {
            params.data.status = "Case Received – Successful";
        } else if (params.data.status === 'APPLICATION NOT SUBMITTED') {
            params.data.status = "Case Received – Unsuccessful";
        }
        let [primaryStatus, secondaryStatus] = params.data.status.split('–');
        if (!primaryStatus || !secondaryStatus) {
            [primaryStatus, secondaryStatus] = params.data.status.split('-');
            if(!primaryStatus || !secondaryStatus){
                params.data.status = "Case Received – Unsuccessful";
                [primaryStatus, secondaryStatus] = params.data.status.split('–');
            }
        }
        primaryStatus = primaryStatus?.trim();
        secondaryStatus = secondaryStatus?.trim();
        const steps = allSteps[params.data.commission_type];

        let map = {};
        for (let i = 0; i< steps.length; i++) {

            map[steps[i]] = true;
            if (steps[i] === primaryStatus) {

                break;
            }
        }

        setProgressMapping(map);
        setStatus({
            primaryStatus: primaryStatus,
            secondaryStatus: secondaryStatus
        });


        if(primaryStatus === 'Investigation'){

            let [primaryStatus, secondaryStatus] = params.data.investigation_status?.split('–');
            if (!primaryStatus || !secondaryStatus) {
                [primaryStatus, secondaryStatus] = params.data.investigation_status.split('-');
            }

            setInvestigationStatus({
                primaryStatus: primaryStatus?.trim(),
                secondaryStatus: secondaryStatus?.trim()
            })

            let map = {};
            for (let i = 0; i< investigationSteps.length; i++) {

                map[investigationSteps[i]] = true;
                if (investigationSteps[i] === primaryStatus) {

                    break;
                }
            }	
            setInvProgressMapping(map);
        }
        

    }

    return (

        <div>
            <ApplicationHeading title={"Transaction History"}></ApplicationHeading>
            <div className="shadow-sm p-3 m-3 bg-light rounded ">
                <DefaultTable gridOptions={{
                    onCellClicked: onCellClicked,
                    applyColumnDefOrder: true,
                    rowData: transactions, colDefs: COLUMN_DEFINITIONS.viewTransactions(), frameworkComponents: {
                        'ActionCellRenderer': ActionCellRenderer,
                        // 'customTooltip': CustomTooltip
                    }, rowAnimation: true, pagination: true, defaultColDef: { filter: true, sortable: true }
                }} ></DefaultTable>
            </div>

            {
                toShowProgressBar && caseType === 'REQUEST_FOR_INFORMATION' && 

                <div style={{ border: '3px dashed black', paddingBottom: '20px', marginTop: '60px', paddingTop: '30px' }}>


<div>
                        <ul className="progress-tracker progress-tracker--text  progress-tracker--center">


                            {

allStepsState[caseType]?.map((step, index) => {
                                    return <li className={`progress-step ${progressMapping[allStepsState[caseType][index]] ? 'is-complete' : ''}`}>
                                        <span className="progress-marker"></span>
                                        <span className="progress-text">
                                            <h4 className="progress-title">{allStepsState[caseType][index]}</h4>
                                        </span>
                                    </li>;
                                })

                            }


                        </ul>
                    </div>


                </div>
                
            }

            {
                toShowProgressBar  && caseType !== 'REQUEST_FOR_INFORMATION' &&
                <div id="progressTracker"
                    style={{ border: '3px dashed black', paddingBottom: '30px', marginTop: '30px' }}>
                    <div
                        style={{ "fontSize": "42px", "fontWeight": "500", "textDecoration": "underline", "margin": "0px auto", "marginBottom": "40px", "width": "100%", "textAlign": "center" }}>
                        Progress Tracker</div>


                    <div style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between", flex: "0 0 100%", "alignItems": "center", "margin": "0px 8% 0px 7%" }}>



                        {
                            allSteps[commissionType]?.map((step, index) => {
                                return <div style={{ "textAlign": "center" }}>
                                    <div className={`test-status ${status.secondaryStatus === stepsInfo[commissionType][index].positiveStep && status.primaryStatus === stepsInfo[commissionType][index].title ? 'progress-step-active-positive' : ''}`} style={{ "fontWeight": `${status.secondaryStatus === stepsInfo[commissionType][index].positiveStep && status.primaryStatus === stepsInfo[commissionType][index].title ? '900' : '500'}` }}>{stepsInfo[commissionType][index].positiveStep}</div>

                                    <span class="material-icons-outlined" style={{ "fontSize": "50px", "color": "green" }}>
                                        arrow_upward
                                    </span>
                                    {/* <i className="fa fa-arrow-up" style={{ "fontSize": "50px", "color": "green" }} aria-hidden="true"></i> */}

                                </div>;
                            })
                        }



                    </div>
                    <div>
                        <ul className="progress-tracker progress-tracker--text  progress-tracker--center">



                            {

                                allSteps[commissionType]?.map((step, index) => {
                                    return <li className={`progress-step ${progressMapping[stepsInfo[commissionType][index].title] ? 'is-complete' : ''}`}>
                                        <span className="progress-marker"></span>
                                        <span className="progress-text">
                                            <h4 className="progress-title">{stepsInfo[commissionType][index].title}</h4>
                                        </span>
                                    </li>;
                                })

                            }


                        </ul>
                    </div>

                    <div style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between", "alignItems": "center", flex: "0 0 100%", "padding": "0px 8% 0px 6%" }}>




                        {
                            allSteps[commissionType]?.map((step, index) => {
                                return <div style={{ "textAlign": "center" }}>
                                    <span class="material-icons-outlined " style={{ "fontSize": "50px", "color": "red" }}>
                                        arrow_downward  
                                    </span>
                                    <div className={`test-status ${status.secondaryStatus === stepsInfo[commissionType][index].negativeStep && status.primaryStatus === stepsInfo[commissionType][index].title ? 'progress-step-active-negative' : ''}`} style={{ "fontWeight": `${status.secondaryStatus === stepsInfo[commissionType][index].negativeStep && status.primaryStatus === stepsInfo[commissionType][index].title ? '900' : '500'}` }}>{stepsInfo[commissionType][index].negativeStep}</div>


                                    {/* <i className="fa fa-arrow-up" style={{ "fontSize": "50px", "color": "green" }} aria-hidden="true"></i> */}

                                </div>;
                            })
                        }
                    </div>
                </div>

            }


            {
                status.primaryStatus === 'Investigation' &&

                <div id="investigationProgressTracker"
                style={{ border: '1px solid #75bee6', paddingBottom: '30px', marginTop: '30px' }}>
                <div
                    style={{ "fontSize": "42px", "fontWeight": "500", "textDecoration": "underline", "margin": "0px auto", "marginBottom": "40px", "width": "100%", "textAlign": "center" }}>
                    Investigation Tracker</div>


                <div style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between", "flexGrow": "1 !important", "flexBasis": "0 !important", "alignItems": "center", "margin": "0px 8% 0px 7%" }}>


                    {
                        investigationSteps?.map((step, index) => {
                            return <div style={{ "textAlign": "center" }}>
                                <div className={`test-status ${investigationStatus.secondaryStatus === investigationStepsInfo[index].positiveStep && investigationStatus.primaryStatus === investigationStepsInfo[index].title ? 'progress-step-active-positive' : ''}`} style={{ "fontWeight": `${investigationStatus.secondaryStatus === investigationStepsInfo[index].positiveStep && investigationStatus.primaryStatus === investigationStepsInfo[index].title ? '900' : '500'}` }}>{investigationStepsInfo[index].positiveStep}
                                

                                {
                                    investigationSteps.length  === index+1 && currentData.investigation_expected_completion_date && 
                                    
                                        <div>
                                            {new Date(currentData.investigation_expected_completion_date).toISOString().split('T')[0]}
                                        </div>
                                }
                                </div>
                                <span class="material-icons-outlined" style={{ "fontSize": "50px", "color": "green" }}>
                                    arrow_upward
                                </span>
                                {/* <i className="fa fa-arrow-up" style={{ "fontSize": "50px", "color": "green" }} aria-hidden="true"></i> */}

                            </div>;
                        })
                    }



                </div>
                <div>
                    <ul className="progress-tracker progress-tracker--text  progress-tracker--center">



                        {

                            investigationSteps?.map((step, index) => {
                                return <li className={`progress-step ${invProgressMapping[investigationSteps[index].title] ? 'is-complete' : ''}`}>
                                    <span className="progress-marker"></span>
                                    <span className="progress-text">
                                        <h4 className="progress-title">{step}</h4>
                                    </span>
                                </li>;
                            })

                        }


                    </ul>
                </div>

                <div style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between", "alignItems": "center", "flexGrow": "1 !important", "flexBasis": "0 !important", "padding": "0px 8% 0px 6%" }}>




                    {
                        investigationSteps?.map((step, index) => {
                            return <div style={{ "textAlign": "center" }}>
                                <span class="material-icons-outlined " style={{ "fontSize": "50px", "color": "red" }}>
                                    arrow_downward  
                                </span>
                                <div className={`test-status ${investigationStatus.secondaryStatus === investigationStepsInfo[index].negativeStep && investigationStatus.primaryStatus === investigationStepsInfo[index].title ? 'progress-step-active-negative' : ''}`} style={{ "fontWeight": `${investigationStatus.secondaryStatus === investigationStepsInfo[index].negativeStep && investigationStatus.primaryStatus === investigationStepsInfo[index].title ? '900' : '500'}` }}>{investigationStepsInfo[index].negativeStep}
                                
                                
                                {
                                    investigationSteps.length  === index+1 && currentData.investigation_extended_completion_date && 
                                    
                                        <div>
                                            {new Date(currentData.investigation_extended_completion_date).toISOString().split('T')[0]}
                                        </div>
                                    
                                }

                                </div>


                                {/* <i className="fa fa-arrow-up" style={{ "fontSize": "50px", "color": "green" }} aria-hidden="true"></i> */}

                            </div>;
                        })
                    }
                </div>
            </div>


            }

        </div>
    );
}

export default Transactions;