
import React from 'react';
import './App.css';
import Navbar from './js/components/Navbar/navbar';
import './assets/styles/tracker.css'
import HomeContainer from './js/containers/Home';
import Merchant from './js/containers/Merchant';
import { Provider } from 'react-redux';
import store from './js/redux/store';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'material-icons/iconfont/material-icons.css';
import LoginContainer from './js/containers/Login';
import LandingContainer from './js/containers/LandingContainer';
import { Alert } from './js/components/Alert';
import PrivateRoute from './js/containers/PrivateRouter';
import { usePromiseTracker } from "react-promise-tracker";
import ApplicationSubmission from './js/components/ApplicationSubmission'
import Loader from "react-loader-spinner";
import Registration from './js/containers/Registration';
const LoadingIndicator = props => {
const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress &&

    <div style={{ position: 'fixed', left: '0px', top: '0px', backgroundColor: 'lightskyblue', opacity: 0.3, width: '100%', height: '100vh', zIndex: 99999998 }}>
      <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, 0)', zIndex: 99999999 }}>
        <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <React.StrictMode>

        <BrowserRouter>
          <LoadingIndicator />
          <Alert />

          <Switch>
            {/* <Route exact path="/login" component={LoginContainer} />
            <Route exact path="/register" component={Registration} /> */}

            {/* <PrivateRoute
                        path="/ftc/guidelines"
                        component={ApplicationSubmission}
                        application={{ type: 'COMPETITION_COMPLAINTS' }}
                        applicationDesc={'Competition Complaints'}
                    /> */}

            <Route component={LandingContainer}>


            </Route>
            {/* <PrivateRoute path='/ftc/guidelines'>
              <ApplicationSubmission application={{ type: 'COMPETITION_COMPLAINTS' }} />
            </PrivateRoute> */}

          </Switch>

        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  );
}

export default App;