import React from 'react';
import Navbar from '../components/Navbar/navbar';

import { BrowserRouter, Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import HomeContainer from './Home';


import PrivateRoute from './PrivateRouter';

import ApplicationSubmission from "../components/ApplicationSubmission";
import Merchant from './Merchant';

import LoginContainer from './Login';
import Registration from './Registration';
import Profile from './Profile';
import Transactions from '../components/Transactions';
import FormSubmission from '../components/FormSubmission';
import ResetPassword from './ResetPassword';


function LandingContainer(props) {


    return (
        <div>
            {/* <BrowserRouter> */}
                <Navbar></Navbar>

                <Switch>

                <Route exact path="/login" component={LoginContainer} />
                <Route exact path="/register" component={Registration} />
                <Route exact path="/reset-password/:uid" component={ResetPassword} />

                <Route exact path="/profile" component={Profile} />

                    <Route exact path="/home" component={HomeContainer} />

                    <Route path="/merchant" component={Merchant}>

                    </Route>

                    <Route path="/transactions" component={Transactions}>

                    </Route>

                    <Route path="/application" >
                        <ApplicationSubmission>
                        </ApplicationSubmission>
                    </Route>

                    <Route path="/form-submission/:appType" >
                        <FormSubmission>
                        </FormSubmission>
                    </Route>

                    <Route path="*">
                        <Redirect to="/home"></Redirect>
                    </Route>


                </Switch>
            {/* </BrowserRouter> */}
        </div>
    );
}

export default LandingContainer;