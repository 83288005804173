import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SimpleNavigator from '../SimpleNavigator';

import { useHistory } from 'react-router-dom';

import '../../../assets/styles/common-styles.css';
import axios from 'axios';
import { getData } from '../../services/service-call';
import { getGuidelines } from '../../redux/actions/guidelines';
import { useDispatch, useSelector } from 'react-redux';
import {APP_SUBMISSION_CONFIG} from '../../config/application-submission-config';
import { alertService } from '../../_services/alert.service';

function Guidelines(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    
    
    const guidelines = useSelector(state => {
        return state.guidelines.guidelines;
    });

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};
    

    let routingDetails = appDetails.steps.filter((step) => {
        if(step.currentStep === history.location.pathname){
            return true;
        }
        return false;
    })[0];

    const nextAction = () => {
        history.push(routingDetails.next);
    }

    const previousAction = () => {
        history.push(routingDetails.previous);
    }
    

    useEffect(() => {
        dispatch(getGuidelines(props.application.type))
        alertService.info('Please read guidelines', { autoClose:true, keepAfterRouteChange: false });
    }, []);


    return (
        <div>
            <div className="shadow-sm p-3 m-3 bg-light rounded simple-border">
                <div className="head-text">Guidelines</div>
                <ul style={{fontSize: '20px', fontWeight: '700'}}>
                    {
                        guidelines.map(guide => {
                            return <li key={guide.id}>{guide.content}</li>
                        })
                    }
                </ul>
            </div>
            <SimpleNavigator prevValue="Cancel" next="/ftc/form" nextAction={nextAction} previousAction={previousAction} previous="/home"></SimpleNavigator>
        </div>
    );
}

export default Guidelines;