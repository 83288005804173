import React from 'react';
import SimpleNavigator from '../SimpleNavigator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {APP_SUBMISSION_CONFIG} from '../../config/application-submission-config';
import moment from 'moment';

function TransactionDetails(props) {
    const history = useHistory();


    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    const submittedTransactionDetails = useSelector((state) => {
        return state.currentTransaction.submitted_transaction;
    })

    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};
    

    let routingDetails = appDetails.steps.filter((step) => {
        if(step.currentStep === history.location.pathname){
            return true;
        }
        return false;
    })[0];

    const nextAction = () => {
        history.push(routingDetails.next);
    }

    const previousAction = () => {
        history.push(routingDetails.previous);
    }


    return (
        <div>
            <div className="shadow-sm p-3 m-3 bg-light rounded">
                <div className="head-text">Transaction Details</div>


                    <div className="container-fluid">
                    <div className="row">
                    <div className="col simple-border">
                        <div>
                            <b>
                                Transaction Number
                            </b>
                        </div>
                        <div>
                            {submittedTransactionDetails.transaction_number}
                        </div>

                    </div>

                    <div className="col simple-border">
                        <div>
                            <b>
                                Date Submitted
                            </b>
                        </div>
                        <div>
                        {moment(submittedTransactionDetails.date_submitted).format('MM/DD/YYYY')}
                        {/* {submittedTransactionDetails.date_submitted} */}
                        </div>
                    </div>

                    <div className="col simple-border">
                        <div>
                            <b>
                                Complaint Type
                            </b>
                        </div>
                        <div>
                        {submittedTransactionDetails.complaint_type}
                        </div>
                    </div>

                    <div className="col simple-border">
                        <div>
                            <b>
                                Case Submission Status
                            </b>
                        </div>
                        <div>
                            {submittedTransactionDetails.app_status}
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        submittedTransactionDetails.informant_name && 
                        <div className="col simple-border">
                        <div>
                            <b>
                                Informant Name
                            </b>
                        </div>
                        <div>
                        {submittedTransactionDetails.informant_name}
                         </div>
                    </div>
                    }
                   

{
    submittedTransactionDetails.respondent_name && 
    <div className="col simple-border">

    <div>
        <b>
            Respondent Name
        </b>
    </div>
    <div>
    {submittedTransactionDetails.respondent_name}
    </div>
</div>
}
                   
                </div>
            
                    </div>
                </div>

            <SimpleNavigator nextAction={nextAction}  previousAction={previousAction} nextValue="Finish" next="/home" previous=""></SimpleNavigator>
        </div>
    );
}

export default TransactionDetails;