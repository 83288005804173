import React, {useEffect, useState} from 'react'

import '../../assets/styles/division-selection.css';

import { useHistory } from 'react-router-dom';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Guidelines from './FormSteps/Guidelines';
import Form from './FormSteps/Form';
import SupportingDocument from './FormSteps/SupportingDocument';
import Preview from './FormSteps/Preview';
import TransactionDetails from './FormSteps/TransactionDetails';
import ApplicationHeading from './ApplicationHeading';
import ProgressStep from './Steps/ProgressStep';
import SupportingDocumentWithoutDefinedTypes from './FormSteps/SupportingDocumentWithoutDefinedTypes';
import { useDispatch, useSelector } from 'react-redux';
import {APP_SUBMISSION_CONFIG} from '../config/application-submission-config';

import {getCurrentTransaction} from '../redux/actions/current-transaction';

export default function ApplicationSubmission(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });
    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};
    
    let stepCount = 0;
    for (let i = 0; i < appDetails.steps.length; i++) {
        if(appDetails.steps[i].currentStep === history.location.pathname)
            break;
        stepCount++;
    };

    const progress = (stepCount+1) * 100 / appDetails.totalSteps;
        
    return (
        <div>
            <ApplicationHeading title={appDetails.application.applicationDesc}></ApplicationHeading>
            <ProgressStep percent={progress}></ProgressStep>
            {/* <img src={'./images/Background FTC.png'}></img> */}
            <div className="bg-image">
            {/* <BrowserRouter>  if uncommented then will not work with some router*/}
                <Switch>
                <Route  path="/application/guidelines">
                <Guidelines application={appDetails.application} appDetails={appDetails}></Guidelines> 
                </Route>
                <Route exact path="/application/form">
                    <Form application={appDetails.application} appDetails={appDetails}></Form>
                </Route>
                <Route exact path="/application/supporting-documents">
                    {/* <SupportingDocument application={appDetails.application}/> */}
                    <SupportingDocumentWithoutDefinedTypes appDetails={appDetails} application={appDetails.application}>

                    </SupportingDocumentWithoutDefinedTypes>
                </Route>
                <Route exact path="/application/preview">
                    <Preview appDetails={appDetails} application={appDetails.application}/>
                </Route>
                <Route exact path="/application/transaction-details">
                    <TransactionDetails appDetails={appDetails} application={appDetails.application}/>
                </Route>

                <Route path="*">
                        <Redirect to="/application/guidelines"></Redirect>
                </Route>
                </Switch>
                </div>
            {/* </BrowserRouter> */}
        </div>
    )
}
