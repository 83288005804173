import React from 'react';
import { useHistory } from 'react-router-dom';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
function ApplicationType(props) {

    const history = useHistory();
    return (
        <div className={`application-type-container ${props.app.appBelongsTo}_app_container`}
        onClick={async (event) => {
            const details = props.app;
            const appDetails = APP_SUBMISSION_CONFIG[details.appType];
            let transNumber = '';
            if(appDetails.isLoginRequired){
                if(!localStorage.getItem('token')){
                    history.push('/login');
                    return;
                }

                transNumber = await getUniqueTransNumber(details.appType);
                
            }else{
                transNumber = await getUniqueTransNumber(details.appType);
            }
            
            store.dispatch({
                type: SET_CURRENT_TRANSACTION,
                temp_trans_id: transNumber ? transNumber.uniqueID : '',
                appType: details.appType,
                transaction: {},
                submitted_transaction:null
            });
            
            if (!!details)
                history.push(`${appDetails.steps[0].currentStep}`);
        }}
        >
            {props.app.title}
        </div>
    );
}

export default ApplicationType;