import React from 'react';

function FooterContainer(props) {

    const commission = localStorage.getItem('route') ?? "ftc";
    return (
        <div className="footer-container row pb-5">

            <div className="col-sm-1">

            </div>
            <div className="col-sm-12">

                <button type="button" className="footer-feedback-button">
                    Got feedback? Tell us what you think.
                </button>

                {
                    commission === 'ftc' &&
                    <div className="footer-text" style={{ marginTop: '1rem' }}>
                        {/* <a href="https://jftc.gov.jm/" target="_blank">jftc.gov.jm</a> is the commission website where you can report fraud, scams, and bad business practices. */}

                        The Fair Trading Commission (FTC) is Jamaica’s competition authority that is responsible for implementing the Fair Competition Act (FCA). Investigations are carried out by the FTC when a complaint is received, or investigations may be initiated by the staff to determine if any enterprise is engaged in practices that are in contravention of the FCA.
                        
                    </div>
                }


                {
                    commission === 'cac' &&
                    <div className="footer-text" style={{ marginTop: '1rem' }}>
                        {/* <a href="https://www.cacjamaica.com/" target="_blank">cacjamaica.com</a> is the commission website where you can report fraud, scams, and bad business practices. */}

                        The Consumer Affairs Commission (CAC) is Jamaica’s consumer protection agency “established to inform, educate and empower consumers to protect themselves in the marketplace”. The CAC engages in market research, provides complaint resolution services and runs a vibrant consumer education programme. The CAC operates under the Trade Act (1955) and the Consumer Protection Act (2005).




                    </div>
                }


                {/* <div style={{marginTop:'1rem'}}>
                    <a href="">Privacy Policy</a>
                </div> */}
            </div>

            <div className="col-sm-12">

                {/* <ul className="footer-link-list">
                    <li>FAQs</li>
                    <li>Update Report</li>
                    <li>Report Now</li>
                </ul> */}

                <div className="footer-text mt-2 ">

                    {
                        commission === 'cac' &&
                        <div>
                            © Copyright 2021 CAC 2000 Ltd. All Rights Reserved.          { '            '}
                        </div>
                    }

                    {
                        commission === 'ftc' &&
                       <div>
                           Copyright © 2021 Fair Trading Commission. All Rights Reserved.
                       </div>
                    }


                    {/* OMB CONTROL#: 3084-0169
                    Under the Paperwork Reduction Act, federal agencies are not allowed to collect information from the public without a valid OMB control number. */}
                </div>
            </div>
                    

            <div className="col-sm-1">

            </div>
       
        </div>
    );
}

export default FooterContainer;