import React, { useRef, useState } from 'react';

import { postData } from '../services/service-call';
import { ROUTES } from '../config';
import { alertService } from '../_services';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const linkRef = useRef();
  const [downloadHref, setDownloadHref] = useState('');
  const [downloadFileName, setDownloadFileName] = useState('');


  const downloadZipDocument = async (trans_id) => {
    const result = await postData({
      url: `${ROUTES.getZipForAllDocuments}${trans_id}`
    });
    manageDocumentInClient(result);
  }

  const downloadTranscript = async (trans_id) => {
    const result = await postData({
      url: `${ROUTES.getTransactionTranscript}${trans_id}`
    });
    manageDocumentInClient(result);
  }

  const manageDocumentInClient = (result) => {
    console.log(result);
    let fileContent = "data:" + result.data.contentType + ';base64,' + result.data.base64;
    if (result.statusCode === 200) {
      setDownloadHref(fileContent);
      setDownloadFileName(result.data.downloadFileName);

      setTimeout(() => {
        linkRef.current.click();
        alertService.success('File downloaded successfully', { autoClose: true, keepAfterRouteChange: false });
      }, 2000);

    } else {
      alertService.error(result.data.msg, { autoClose: true, keepAfterRouteChange: false });
    }
  }

  const navigateApplication = () => {
    const app_type = props.data.app_type;
    const details = APP_SUBMISSION_CONFIG[app_type];
    const appDetails = {
      type: SET_CURRENT_TRANSACTION,
      temp_trans_id: props.data.id,
      trans_id: props.data.transaction_number,
      appType: app_type,
      transaction: {},
      submitted_transaction: null
    };
    dispatch(appDetails);
    history.push(details.steps[0].currentStep);
  }

  const buttonClicked = () => {
    switch (props.type) {
      case 'zip':
        downloadZipDocument(props.data.id);
        break;
      case 'transcript':
        downloadTranscript(props.data.id);
        break
      case 'continue_application':
        navigateApplication();
        break;
      case 'select_merchant_respondent':
        break;
    }
  };

  return (
    <span>
      <a ref={linkRef} download href={downloadHref} download={downloadFileName}></a>
      {
        (props.type === 'zip' || props.type === 'transcript') &&
        <button className="btn btn-warning" onClick={() => buttonClicked()}>
          <CloudDownloadOutlinedIcon style={{ fontSize: 10 }} className="svg_icons"></CloudDownloadOutlinedIcon>

          <span className="ml-2">Download</span>
        </button>
      }

      {
        props.type === 'continue_application' &&
        <button className="btn btn-success" onClick={() => buttonClicked()}>

          <span>Resume Application</span>
        </button>

      }


      {
        props.type === 'transaction_id' && props.data.transaction_number &&
        <button className="btn btn-danger" style={{ cursor: 'pointer' }}>

          <span>{props.data.transaction_number || ' '}</span>
        </button>

      }


      {
        props.type === 'track' &&
        <button className="btn btn-success" style={{ cursor: 'pointer' }}>

          <span>Track your Application</span>

        </button>

      }

      {
        props.type === 'select_merchant_respondent' &&
        <button className="btn btn-success" onClick={() => buttonClicked()}>

          <span>Select</span>
        </button>
      }
    </span>
  );
};