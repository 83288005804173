
import { createStore , applyMiddleware} from 'redux';

import createSagaMiddleware from 'redux-saga';
import appReducer from './reducers/rootReducer';

import { watcherSaga } from '../sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(appReducer, applyMiddleware(sagaMiddleware));


sagaMiddleware.run(watcherSaga);

export default store;