import { takeLatest } from "redux-saga/effects";
import { GET_GUIDELINES } from "../redux/actions/guidelines";
import { GET_MERCHANTS } from "../redux/actions/merchants";
import { GET_TRANSACTIONS } from "../redux/actions/transactions";
import { GET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { handlerGetGuidelines } from "./handlers/guidelines";
import { handlerGetMerchants } from "./handlers/merchant";
import { handlerGetTransactions ,handleGetTransaction , handleSaveCurrentTransaction} from './handlers/transactions';



export function* watcherSaga() {
    yield takeLatest(GET_MERCHANTS, handlerGetMerchants);
    yield takeLatest(GET_GUIDELINES, handlerGetGuidelines);
    yield takeLatest(GET_TRANSACTIONS, handlerGetTransactions);
    yield takeLatest(GET_CURRENT_TRANSACTION, handleGetTransaction);
}
