import { getData } from '../services/service-call';


export async function getUniqueTransNumber(appType) {
    let commission = localStorage.getItem('route');
    commission = commission?.toUpperCase();
    return await getData({ url: `/transactions/transaction-number?appType=${appType}&commission_type=${commission}`});
}

export async function getBase64(file) {
    return new Promise((resolve, reject) => {

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve({contentType : reader.result.toString().slice(reader.result.indexOf(':') + 1, reader.result.indexOf(';')), base64 : reader.result.toString().slice(reader.result.indexOf('base64,') + 7)})
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            reject(error)
        };
    })
}