import React, { useEffect, useState } from 'react';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

function DefaultTable(props) {

    const [gridApi, setGridApi] = useState(null);
    const rowData = props.gridOptions.rowData;

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    // useEffect(() => {
    //     // if (gridApi) {
    //     //     gridApi.autoSizeAllColumns();
    //     // }
    // }, [rowData]);

    return (

        <div style={{ height: 400, width: '100%', margin: 'auto' }}>
            <div className="ag-theme-alpine" style={{ height: 400, width:'100%', margin: 'auto'}}>
                <AgGridReact onRowClicked={(data) => {
                    if(props.onRowClicked){
                        props.onRowClicked(data)
                    }
                }}  style={{ width: '100%', height: '100%;' }}
                    rowData={rowData}
                    onGridReady={onGridReady}
                    gridOptions={props.gridOptions}>
                    {props.gridOptions.colDefs?.map((element, index) => {
                        return <AgGridColumn rowGroup={!!element.rowGroup} cellStyle={{cursor:  `${props.pointer ? 'pointer' : ''}`}} key={index} field={element.field} valueGetter={element.valueGetter} headerName={element.headerName} cellRenderer={element.cellRenderer} 
                        cellRendererParams={element.cellRendererParams} width={element.width} resizable={true} ></AgGridColumn>
                    })}
                </AgGridReact>
            </div>
        </div>

    );
}

export default DefaultTable;