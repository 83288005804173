export const GET_GUIDELINES = 'GET_GUIDELINES';
export const SET_GUIDELINES = 'SET_GUIDELINES';


export const getGuidelines = (applicationType) => {
    return {
        type: GET_GUIDELINES,
        applicationType
    }
}

export const setGuidelines = (guidelines) => {
    return {
        type: SET_GUIDELINES,
        guidelines
    }
}