import { call, put } from "redux-saga/effects";

import { setGuidelines } from "../../redux/actions/guidelines";
import { requestGetGuidelines } from "../requests/guidelines";

export function* handlerGetGuidelines(action){
    try{
        const response = yield call(requestGetGuidelines, action.applicationType);
        const {guidelines} = response.data;
        yield put(setGuidelines(guidelines));
    }catch(err){
        console.error(err);
    }
}