export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';



export const getTransactions = () => {
    return {
        type: GET_TRANSACTIONS
    }
}

export const setTransactions = (transactions) => {
    return {
        type: SET_TRANSACTIONS,
        transactions
    }
}
