


import {getData} from '../services/service-call';
import moment from 'moment';

let commission = localStorage.getItem('route') ?? "ftc";
export const ROUTES = {
    documentUpload : '/documents/upload',
    removeDocument: '/documents/remove-document/',
    competitionComplaints : '/applications/competition-complaint',
    consumerComplaints: '/applications/consumer-complaint',
    requestForAdvice: '/applications/request-advice',
    addNewMerchant: '/merchants',
    getMerchants: '/merchants',
    getGuidelines: '/constants/guidelines?application_type=',
    getAllParish: '/constants/parish',
    getUploadedDocuments: '/documents/uploaded-documents/',
    getAllDropdowns: '/constants/all-dropdowns/',
    getTransactions: '/transactions',
    getTransaction : '/transactions/',
    registerUser: '/users/register',
    loginUser: '/users/login',
    resetPassword: '/users/password/reset',
    submitNewPassword: '/users/password/submit',
    getZipForAllDocuments: '/documents/all-documents/',
    getTransactionTranscript: '/documents/transaction-transcipt/',
    saveConsumerComplaints: '/applications/consumer-complaint/save',
    saveReqForAdvice : '/applications/request-advice/save',
    verifyToken : '/users/verify-google-token-id',
    getProfileInfo: '/users/profile',
    launchGenericApplication: '/applications/generic-application',
    getDocumentDetailsForGenericApplication: '/documents/generic-application',
    getFormDetailsForGenericApplication: '/applications/generic-application',
    saveRequestForApplication: '/applications/request-for/save'
}


export const COLUMN_DEFINITIONS = {
    viewMerchants : [
        { headerName: 'ID', field: 'id' },
        { headerName: 'Name', field: 'name' },
        { headerName: 'Parish', field: 'parish' },
        { headerName: 'Email', field: 'email_address' },
        { headerName: 'Phone Number', field: 'phone_number' },
        { headerName: 'Street Address', field: 'street_address' },
        {
            headerName: 'Select', field: '', cellRenderer: 'ActionCellRenderer', field: 'select_merchant_respondent', cellRendererParams: {
                type: 'select_merchant_respondent',
            },
        }
    ],

    viewMerchantsCAC: [
        { headerName: 'ID', field: 'id' },
        { headerName: 'Name', field: 'name'},
        { headerName: 'Merchant Type', field: 'merchant_type'},
        { headerName: 'Website', field: 'Website'},
        {
            headerName: 'Select', field: '', cellRenderer: 'ActionCellRenderer', field: 'select_merchant_respondent', cellRendererParams: {
                type: 'select_merchant_respondent',
            },
        }
    ],

    viewBranchCAC: [
        { headerName: 'Name', field: 'name'},
        {headerName: 'City', field: 'city'},
        {headerName: 'Parish', field: 'parish'},
        {headerName: 'Telephone Number', field: 'contact_telephone_number'},
        {headerName: 'Email', field: 'contact_email'},
        {headerName: 'Contact First Name', field: 'contact_first_name'},
        {headerName: 'Contact Last Name', field: 'contact_last_name'},
        { headerName: 'State/Province/Region', field:'state_province_region'}
    ],
    viewTransactions: () => {
        return [
            {
                headerName: 'Transaction ID',
                field: 'transaction_number',
                width:240,
                
                cellRenderer: 'ActionCellRenderer',
                cellRendererParams: {
                    type: 'transaction_id',
                },
            },
            {
                headerName : localStorage.getItem('route') === 'ftc' ? 'Respondent Name' : "Merchant Name",
                valueGetter: (params) => {        
                    return params.data.respondent_details?.[0]?.name;
                },
                width:250
            },
            {
                headerName: localStorage.getItem('route') === 'ftc' ? 'Informant Name' : 'Complainant Name', 
                valueGetter : (params) => {
                    return params.data.informant_details?.[0]?.name
                },
                width:250
            },
            // {
            //     headerName : 'Application Type',
            //     field: 'app_type',
            //     width:250
            // },
            {
                headerName : 'Date Submitted',
                valueGetter: (params) => {
                    if(!params.data.submitted_on){
                        return "";
                    }
                    // return moment(params.data.submitted_on).format('MM/DD/YYYY')
                    return new Date(params.data.submitted_on).toDateString()
                },
                width:200
            },
            // {
            //     headerName : 'Application Status',
            //     field: 'status',
            //     width:260
            // },
            {
                headerName: 'Copy of Docs Submitted',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'zip',
                },
                width:140
            },
            /*{
                headerName: 'Transaction Transactipts',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'transcript',
                },
            },*/
            {
                headerName: 'Resume Application',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'continue_application',
                },
                width:200
            },
            {
                headerName: 'Status',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'track',
                },
                width:200
            },
        ]
    }
}


let allParish;
let allDropDowns;
(async () => {

    allParish = getData({url: ROUTES.getAllParish});
    allDropDowns = getData({url: ROUTES.getAllDropdowns});
    
})();



export const PARISH_LIST = allParish;
export const ALL_DROPDOWNS = allDropDowns;